import React, { useMemo } from "react";
import { BSON } from "realm-web";
import Select from "react-select";
import i18n from "../../../translations/i18n";
import { NumValue, SelectOption } from "../../../model/common.types";
import { LocationType } from "../../../model/warehouse/common.types";

export interface RemoteWarehouseAssignment {
  _id: BSON.ObjectId;
  amountForNewLocation: NumValue;
  location: string | undefined;
}

interface AmountEntryProps {
  amountAtLocation: NumValue;
  remoteWarehouseAssignment: RemoteWarehouseAssignment;
  allRemoteWarehouseAssignments: Array<RemoteWarehouseAssignment>;
  selectOptions: Array<SelectOption<LocationType>>;
  disabled: boolean;
  onDeleteRow: (id: string) => void;
  onChangeAmount: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (id: string, value?: SelectOption<LocationType>) => void;
}

const AmountEntry: React.FC<AmountEntryProps> = ({
  amountAtLocation,
  remoteWarehouseAssignment,
  allRemoteWarehouseAssignments,
  selectOptions,
  disabled,
  onDeleteRow,
  onChangeAmount,
  onChangeLocation
}) => {
  const warehouse = useMemo(() => {
    return selectOptions.find(so => so.value === remoteWarehouseAssignment.location);
  }, [selectOptions, remoteWarehouseAssignment]);

  return (
    <div className="row align-items-center mt-2">
      <div className="col-4">
        <div className="input-group">
          <input
            className="form-control form-control-solid"
            type="number"
            placeholder={i18n.t("common:quantity")}
            disabled={disabled}
            min={0}
            max={amountAtLocation.value}
            step={1}
            value={remoteWarehouseAssignment.amountForNewLocation.value}
            onChange={e => onChangeAmount(remoteWarehouseAssignment._id.toString(), e)}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{amountAtLocation.unit}</span>
          </div>
        </div>
      </div>
      <div className="col-7">
        <Select
          className="select-warehouse"
          isClearable={true}
          disabled={disabled}
          value={warehouse}
          onChange={(value: any) => onChangeLocation(remoteWarehouseAssignment._id.toString(), value)}
          options={selectOptions}
        />
      </div>
      <div className="col-1">
        <button
          className={"btn btn-danger px-2 py-1 " + (allRemoteWarehouseAssignments.length === 1 ? "disabled" : "")}
          onClick={
            allRemoteWarehouseAssignments.length === 1
              ? undefined
              : () => onDeleteRow(remoteWarehouseAssignment._id.toString())
          }
        >
          <i className="fa fa-trash p-0" />
        </button>
      </div>
    </div>
  );
};

export default AmountEntry;

import React from "react";
import i18n from "../../../translations/i18n";

interface WarehouseUpdatesPanelProps {}

const WarehouseUpdatesPanel: React.FC<WarehouseUpdatesPanelProps> = () => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h4 className="kt-portlet__head-title kt-font-bolder">{i18n.t("warehouse:activities")}</h4>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-tabs nav-bold nav-tabs-line not-allowed">
            <li className="nav-item">
              <span className="nav-link active disabled" data-toggle="tab">
                {i18n.t("warehouse:unit")}
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link disabled" data-toggle="tab">
                {i18n.t("warehouse:batch")}
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link disabled" data-toggle="tab">
                {i18n.t("warehouse:document")}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="kt-portlet__body">
        <span className="text-black d-block mb-3">{i18n.t("warehouse:comingSoon")}</span>
        <WarehouseUpdatesTimeline />
      </div>
    </div>
  );
};

interface TimelineEntryData {
  timeLabel: string;
  content: string;
  badgeColor: string;
}

const WarehouseUpdatesTimeline: React.FC = () => {
  const timelineEntries: Array<TimelineEntryData> = [];

  return (
    <div className="timeline timeline-5">
      {timelineEntries.map(entry => (
        <WarehouseUpdatesTimelineEntry key={entry.content} entry={entry} />
      ))}
    </div>
  );
};

interface WarehouseUpdatesTimelineEntryProps {
  entry: TimelineEntryData;
}

const WarehouseUpdatesTimelineEntry: React.FC<WarehouseUpdatesTimelineEntryProps> = ({ entry }) => {
  return (
    <div className="timeline-item">
      <div className="timeline-label">{entry.timeLabel}</div>
      <div className="timeline-badge">
        <span className={entry.badgeColor || "bg-primary"} />
      </div>
      <div className="timeline-content">{entry.content}</div>
    </div>
  );
};

export default WarehouseUpdatesPanel;

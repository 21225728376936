import _ from "lodash";
import React, { PureComponent } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import { OrdersDocument } from "../../../model/orders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import orderUtils, { DECLINED, OFFER, REQUESTAPPROVED, REQUESTPENDING } from "../../../utils/orderUtils";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import PDFPreview from "../../common/PDFPreview";
import invoiceGeneration from "../../../utils/pdf/invoiceGeneration";
import OrderHelper from "../../order/OrderHelper";
import { DataContext } from "../../../context/dataContext";
import { CustomOrder } from "../../order/CustomTypes";
import config from "../../../config/config.json";

interface CompanyOposTabProps {
  orders: Array<OrdersDocument>;
  company: CompaniesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface CompanyOposTabState {
  companyOrders: Array<OrdersDocument>;
  timeRelatedOposCollection: Array<OposCollection>;
}

export interface OposCollection {
  monthlyCollectionDate: Date;
  relatedOposEntities: Array<OposEntity>;
  totalRestAmount: number;
}

export interface OposEntity {
  invoiceDate: Date;
  invoiceId: string;
  invoicePath: string;
  dueIn: number;
  orderId: string;
  orderNumber: string;
  orderProduct: string;
  paymentStatus: string;
  remainingAmount: number;
}

class CompanyOposTab extends PureComponent<CompanyOposTabProps, CompanyOposTabState> {
  constructor(props: CompanyOposTabProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidUpdate(prevProps: Readonly<CompanyOposTabProps>) {
    const { company, orders } = this.props;
    if (!_.isEqual(prevProps.company, company) || !_.isEqual(prevProps.orders, orders)) {
      this.setState(this.getDefaultState);
    }
  }

  /**
   * Function to receive the default state of this component.
   * @returns {CompanyOposTabState} the default states of the component.
   */
  getDefaultState = () => {
    // receive corresponding orders
    const companyOrders = this.getCompanyRelatedOrders();

    // building opos entity corresponding to the selected date time
    const relevantOposEntities: Array<OposCollection> = this.getRelevantOposEntities(true, companyOrders);

    // setting default state
    const defaultState: CompanyOposTabState = {
      companyOrders: companyOrders,
      timeRelatedOposCollection: relevantOposEntities
    };
    return defaultState;
  };

  /**
   * Function to receive the relevant opos entities with metadata corresponding to the company related orders and selected monthly date.
   * @param initial indicates if creation is on first render.
   * @param initialCompanyOrders company orders for initial creation.
   * @returns {Array <OposCollection>} the related opos entities.
   */
  getRelevantOposEntities = (initial: boolean, initialCompanyOrders?: Array<OrdersDocument>) => {
    let companyOrders: Array<OrdersDocument>;

    if (initial) {
      companyOrders = initialCompanyOrders!;
    } else {
      companyOrders = this.state.companyOrders;
    }

    // Building opos collections related to the monthly dates
    const timeRelatedOposCollection: Array<OposCollection> = [];
    companyOrders.forEach(order => {
      // check if order has invoices
      if (order.invoices) {
        order.invoices.forEach(invoice => {
          // receiving the date of the object
          const invoiceDate = invoice.invoiceDate;

          // check if there are existing opos entities related to the monthly date
          let relatedOposEntities: Array<OposEntity> = [];
          const searchResult: OposCollection | undefined = _.cloneDeep(
            timeRelatedOposCollection.find(
              collection =>
                new Date(invoiceDate.getFullYear(), invoiceDate.getMonth()).getTime() ===
                collection.monthlyCollectionDate.getTime()
            )
          );

          // extending collection of time related opos entities, if collection already exists
          if (searchResult) {
            relatedOposEntities = _.cloneDeep(searchResult.relatedOposEntities);
          }

          // Add invoice to collection, if It's still relevant
          if (invoice.state !== "canceled") {
            // calculating rest amount
            let restAmount = invoice.totalGross;
            if (invoice.state === "partlyPaid" || invoice.state === "paid") {
              invoice.payments.forEach(payment => {
                restAmount -= payment.amount;
              });
            }

            // for opos lists, it makes no sense have negative values for paid invoices -> filter negative values and round up to zero
            if (invoice.state === "paid") {
              restAmount = Math.max(0, restAmount);
            }

            relatedOposEntities.push({
              invoiceDate: invoiceDate,
              invoiceId: String(invoice.invoiceNumber),
              invoicePath: invoice.path,
              dueIn: invoice.dueIn,
              orderId: order._id.toString(),
              orderNumber: String(order.identifier),
              orderProduct: order.title,
              paymentStatus: invoice.state,
              remainingAmount: restAmount
            });

            // saving opos entity into time related collection set
            if (searchResult) {
              searchResult.relatedOposEntities = relatedOposEntities;
              searchResult.totalRestAmount += restAmount;

              const correspondingIndex = timeRelatedOposCollection.findIndex(
                collection =>
                  new Date(invoiceDate.getFullYear(), invoiceDate.getMonth()).getTime() ===
                  collection.monthlyCollectionDate.getTime()
              );
              timeRelatedOposCollection.splice(correspondingIndex, 1, searchResult);
            } else {
              // create time related collection set with corresponding opos entity
              timeRelatedOposCollection.push({
                monthlyCollectionDate: new Date(invoiceDate.getFullYear(), invoiceDate.getMonth()),
                relatedOposEntities: relatedOposEntities,
                totalRestAmount: restAmount
              });
            }
          }
        });
      }
    });
    return timeRelatedOposCollection.sort((col1, col2) => {
      return col1.monthlyCollectionDate.getTime() - col2.monthlyCollectionDate.getTime();
    });
  };

  /**
   * Function to receive the corresponding orders related to the selected company.
   * @returns {Array<OrdersDocument>} the corresponding orders.
   */
  getCompanyRelatedOrders = () => {
    const { orders, company } = this.props;
    return orders
      .filter(
        o =>
          ![DECLINED, OFFER, REQUESTAPPROVED, REQUESTPENDING].includes(o.state) &&
          o.createdFor.toString() === company._id.toString()
      )
      .sort((o1, o2) => o1.createdOn.getTime() - o2.createdOn.getTime());
  };

  /**
   * Returning opos listing as html document.
   * @param OposCollection the corresponding opos listing.
   * @returns {string} html representation of the opos listing.
   */
  doCreateOposListingHTML = (OposCollection: OposCollection) => {
    const { companyOrders } = this.state;
    const { context } = this.props;

    const helperOrder = companyOrders[0]; // using a representative order to extend customer information, cannot be triggered without any order

    // Extending necessary information
    const extendedOrder: CustomOrder = OrderHelper.getExtendedOrder(helperOrder, context);
    const extendedCustomer = OrderHelper.getCustomerData(extendedOrder);
    return invoiceGeneration.createOposListing(OposCollection, extendedCustomer);
  };

  /**
   * Function to create pdf preview component for opos listings.
   * @param OposCollection the corresponding opos collections.
   * @returns {React.PureComponent} preview component for pdf generation.
   */
  renderPreview = (OposCollection: OposCollection) => {
    const { company } = this.props;

    const title = "OPOS_List_" + company._id.toString() + "_" + new Date().toISOString().split("T")[0];
    const fileName =
      (title ? "_" + baseUtils.encodeString(title.replace(/\s/g, "_").replace(/#/g, "Nr-")) : "") +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf";
    return (
      <PDFPreview
        createPDF={() => this.doCreateOposListingHTML(OposCollection)}
        fileName={fileName}
        alternativeButtonName={"Generate"}
      />
    );
  };

  render() {
    const { timeRelatedOposCollection } = this.state;
    const header = [
      { title: "Inv-Date", size: 10 },
      { title: "Inv-Nr.", size: 10 },
      { title: "Ord-Nr. ", size: 10 },
      { title: "Product", size: 40 },
      { title: "Due Date", size: 10 },
      { title: "Status", size: 10 },
      { title: "Remaining", size: 10 }
    ];
    return (
      <>
        {timeRelatedOposCollection.length === 0 ? (
          <img
            src={toAbsoluteUrl("/media/img/no_results.jpg")}
            className="d-block my-0 mx-auto "
            style={{ height: "500px" }}
            alt="Result not found"
          />
        ) : (
          <div className="kt-portlet__body">
            <div className="kt-widget kt-widget--user-profile-3">
              <div className="kt-widget__content">
                <div className="kt-widget__head" style={{ paddingLeft: "10px" }}>
                  <LanguageSelectionDropdown
                    wrapperClasses={"form-group row"}
                    labelColumnClasses={"col-2 col-form-label"}
                    selectColumnClasses={"col-2"}
                    labelPosition={"front"}
                    label={"Generation Language"}
                  />
                </div>
              </div>
              <div className="kt-widget__content">
                {timeRelatedOposCollection.map(collection => {
                  return (
                    <Card className="my-2" key={collection.monthlyCollectionDate.getTime().toString()}>
                      <Card.Header>
                        <span>
                          <b>
                            {collection.monthlyCollectionDate.getFullYear()} -{" "}
                            {collection.monthlyCollectionDate.toLocaleString("en-US", { month: "long" })}
                          </b>
                        </span>
                      </Card.Header>
                      <Card.Body>
                        <table style={{ width: "100%" }}>
                          <thead style={{ display: "table-header-group" }}>
                            <tr>
                              {header.map(h => {
                                return (
                                  <th
                                    key={h.title}
                                    className="kt-datatable__cell d-table-cell"
                                    style={{ width: h.size + "%" }}
                                  >
                                    {h.title}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                            {collection.relatedOposEntities.map(entity => {
                              const dueIn = orderUtils.getInvoiceDue(entity.dueIn, entity.invoiceDate);
                              const dueInFormatted = baseUtils.formatDate(dueIn);
                              return (
                                <tr className="kt-datatable__row d-table-row" key={entity.invoiceId}>
                                  <td>{entity.invoiceDate.toISOString().split("T")[0]}</td>
                                  <td>
                                    <a
                                      href={config.mediahubBase + entity.invoicePath}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      className="kt-user-card-v2__name kt-link"
                                    >
                                      {"RE-" + entity.invoiceId}
                                    </a>
                                  </td>
                                  <td>
                                    <Link to={"/order/" + entity.orderId} className="kt-user-card-v2__name kt-link">
                                      {"AT-" + entity.orderNumber}
                                    </Link>
                                  </td>
                                  <td>{entity.orderProduct.trim()}</td>
                                  <td>
                                    <span
                                      className={
                                        "p-auto kt-font-bold kt-badge kt-badge--inline kt-badge--pill " +
                                        (dueIn.getTime() > new Date().getTime()
                                          ? "kt-badge--success"
                                          : "kt-badge--danger")
                                      }
                                      style={{ width: "90px" }}
                                    >
                                      <p style={{ textAlign: "center", fontSize: "12px" }}>{dueInFormatted}</p>
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "p-auto kt-font-bold kt-badge kt-badge--inline kt-badge--pill " +
                                        (entity.paymentStatus === "paid"
                                          ? "kt-badge--success"
                                          : entity.paymentStatus === "partlyPaid"
                                          ? "kt-badge--warning text-white"
                                          : "kt-badge--danger")
                                      }
                                      style={{ width: "75px" }}
                                    >
                                      <p style={{ textAlign: "center", fontSize: "12px" }}>{entity.paymentStatus}</p>
                                    </span>
                                  </td>
                                  <td>{baseUtils.formatEuro(entity.remainingAmount)}</td>
                                </tr>
                              );
                            })}
                            <tr className="kt-datatable__row d-table-row" style={{ height: "25px" }}>
                              <td className="mt-5" />
                            </tr>
                            <tr>
                              <th colSpan={2}>Total remaining:</th>
                              <th colSpan={4} />
                              <th>{baseUtils.formatEuro(collection.totalRestAmount)}</th>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                      <Card.Footer style={{ backgroundColor: "unset" }}>
                        <div style={{ textAlign: "right" }}>{this.renderPreview(collection)}</div>
                      </Card.Footer>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CompanyOposTab;

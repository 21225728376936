import { BSON } from "realm-web";
import React, { PureComponent, useState } from "react";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import orderUtils, {
  ARCHIVE,
  CONTRACT,
  CREATEINVOICE,
  DECLINED,
  FULFILLMENT,
  OFFER,
  ORDERORDERCOMMODITIES,
  PRODUCTION,
  PRODUCTIONQUEUE,
  REQUESTAPPROVED,
  REQUESTPENDING,
  WAITING
} from "../../utils/orderUtils";
import { CustomOrder } from "./CustomTypes";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import dateUtils from "../../utils/dateUtils";
import baseUtils from "../../utils/baseUtils";
import { DataContext, useDataContext } from "../../context/dataContext";
import { calculationInfo, FulfillmentPriceInfo } from "../../model/orders.types";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "./OrderHelper";
import userService from "../../services/userService";
import EditTargetDateModal from "./modals/EditTargetDateModal";
import EditMarginModal from "./modals/EditMarginModal";
import dbOrderService from "../../services/dbServices/dbOrderService";
import CancelOrderModal from "./modals/CancelOrderModal";
import PlaceOrderModal from "./modals/PlaceOrderModal";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { PackagingsDocument } from "../../model/packagings.types";
import ReturnToQueueModal from "./modals/ReturnToQueueModal";
import FinishProductionModal from "./modals/FinishProductionModal";
import dbService, { ORDERS } from "../../services/dbService";
import CreateContractModal from "./modals/CreateContractModal";
import CreateCallModal from "./modals/CreateCallModal";
import accessUtils, { ACTIONS, EDITLOCATIONS } from "../../utils/accessUtils";
import contractUtils from "../../utils/contractUtils";
import {
  T_DECLINED,
  T_ORDERARCHIVED,
  T_PRODUCTION,
  T_REQUESTPENDING,
  T_REQUESTREVOKED,
  T_WAITING
} from "../../utils/timelineUtils";
import { PackagingTypes } from "../configurator/configuratorConstants";
import SetDeliveryDateModal from "./modals/SetDeliveryDateModal";
import notificationService, {
  R_OFFERAPPROVED,
  R_ORDERARCHIVED,
  R_ORDERDECLINED,
  R_ORDERPRODUCTIONSTARTED
} from "../../services/notificationService";
import { ROLES } from "../../utils/userdataUtils";
import RevokeFulfillmentModal from "./modals/RevokeFulfillmentModal";
import EditTitleModal from "./modals/EditTitleModal";
import EditCustomCalculationModal from "./modals/EditCustomCalculationModal";
import slackService from "../../services/slackService";
import ReviewProductionReportModal from "./modals/ReviewProductionReportModal";
import RawbidsApprovalModal from "./modals/rawbidsApproval/RawbidsApprovalModal";

interface OrderHeaderProps extends RouteComponentProps<{}, {}, {}> {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  onSave: (approve?: boolean, onSuccess?: () => void, finalCallback?: () => void) => void;
}

interface OrderHeaderState {
  approving: boolean;
}

interface OrderHeaderButton {
  text: string;
  classes: string;
  onClick: (() => void) | (() => Promise<void>);
  loading?: boolean;
}

class OrderHeader extends PureComponent<OrderHeaderProps, OrderHeaderState> {
  isProcurement = userService.isAuthorizedForAction(ROLES.PROCUREMENT);
  isSales = userService.isAuthorizedForAction(ROLES.SALES);

  constructor(props: OrderHeaderProps) {
    super(props);
    this.state = { approving: false };
  }

  getOrderDescription = () => {
    const { order } = this.props;
    const description = orderUtils.resolveOrderDescription(order!);
    return `${order!.state === DECLINED ? "[DECLINED]" : description[0]} ${
      description[1]
    }-${order!.identifier.toString()}`;
  };

  getCalculationUnits = () => {
    const { order } = this.props;
    const units = order.calculations.map(calc => +calc.units);
    const last = units.pop();
    return units.join(", ") + (units.length > 0 ? " & " : "") + last;
  };

  /**
   * Get values from the calculation info object
   * @param property key of the calculation info object to get the data for
   * @returns {[string, number, any]} triple of prefix e.g. average sign, the (average) value,
   *          and list with tuples for units and their respective non average value
   */
  getAverageValue = (
    property: keyof Omit<calculationInfo, "marginBuffer" | "customCalculation" | "standardCalculation">
  ): [string, number, any] => {
    const { order } = this.props;
    return orderUtils.getAverageValue(order, property);
  };

  /**
   * Get values from the fulfillment price info object
   * @param property key of the fulfillment info object to get the data for
   * @returns {[string, number, any]} triple of prefix, the value and null
   */
  getFulfillmentValue = (property: keyof Omit<FulfillmentPriceInfo, "marginBuffer">): [string, number, null] => {
    const { order } = this.props;
    const priceInfo = order.fulfillment?.priceInfo!;
    return ["", priceInfo[property], null];
  };

  /**
   * Get buttons to be rendered for each state
   * @returns {Array<{key: string, component: JSX.Element}>} List of components and a matching key to be rendered
   */
  getButtons(): Array<{ key: string; component: JSX.Element }> {
    const { order, context } = this.props;
    const { approving } = this.state;
    const commodities = order.calculations[0].prices;
    const packaging = order.calculations[0].packagings;
    const isProd = userService.hasRole(ROLES.PRODUCTION, true);
    const isSales = userService.hasRole(ROLES.SALES, true);
    const isAdmin = userService.isAdmin();

    const getButton = (button: OrderHeaderButton, disabled?: boolean, errors?: Array<string>) => ({
      key: button.text,
      component: (
        <OverlayTrigger
          show={!errors || errors.length === 0 ? false : undefined}
          overlay={
            <Tooltip id="errorDescription">
              <ul className={"m-0 p-0"}>
                {errors &&
                  errors.map((e, key) => {
                    return (
                      <ul className="text-left pl-0" key={key}>
                        <span>
                          <b>{e}</b>
                        </span>
                        <br />
                      </ul>
                    );
                  })}
              </ul>
            </Tooltip>
          }
        >
          <button
            className={
              "btn btn-sm btn-upper " + button.classes + (disabled || (errors && errors.length > 0) ? " disabled" : "")
            }
            onClick={disabled || (errors && errors.length > 0) ? undefined : button.onClick}
            disabled={disabled && (!errors || errors.length === 0)}
            title={disabled ? "This action is not allowed" : ""}
          >
            {button.loading && (
              <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                <svg className="button-splash-spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </svg>
              </div>
            )}
            {button.text}
          </button>
        </OverlayTrigger>
      )
    });

    const editButton = {
      text: "Edit",
      classes: "btn-secondary",
      onClick: () => this.props.history.push("/calculation/order/" + order._id.toString())
    };
    const duplicateButton = {
      text: "Load As Template",
      classes: "btn-secondary",
      onClick: () => this.props.history.push("/calculation/order/" + order._id.toString() + "?copy")
    };
    const declineButton = { text: "Decline", classes: "btn-danger", onClick: this.handleDecline };
    const requestApprovalButton = {
      text: "Request Approval",
      classes: "btn-success",
      onClick: this.handleRequestApproval
    };
    const finishButton = { text: "Finish Order", classes: "btn-success", onClick: this.handleArchive };
    const editMarginModal = { key: "editMargin", component: <EditMarginModal {...this.props} /> };
    const editCustomCalcModal = {
      key: "editCustomCalc",
      component: <EditCustomCalculationModal context={context} order={order} />
    };
    const createContractModal = {
      key: "createContract",
      component: <CreateContractModal order={order} context={context} convertExisting={true} />
    };
    const cancelModal = { key: "cancelOrder", component: <CancelOrderModal order={order} /> };

    let buttons = [];
    switch (order.state) {
      case OFFER:
        if (isProd) return [];
        buttons = [
          getButton(declineButton, isProd),
          getButton(duplicateButton, isProd),
          editMarginModal,
          getButton(editButton, isProd)
        ];
        if (order.settings.type !== T_SERVICE) buttons.push(getButton(requestApprovalButton, isProd));
        else buttons.push({ key: "placeOrder", component: <PlaceOrderButton order={order} /> });
        return buttons;
      case REQUESTPENDING:
        if (isProd) return [];
        const saveButton = { text: "Save", classes: "btn-secondary", onClick: this.handleSave };
        const revokeApprovalButton = {
          text: "Revoke Approval",
          classes: "btn-warning",
          onClick: this.handleRevoke
        };
        const approvalButton = {
          text: "Approve Commodities",
          classes: "btn-success",
          onClick: this.handleApprove,
          loading: approving
        };
        const approvalWithRawbidsModal = {
          key: "rawbidsApproval",
          component: (
            <RawbidsApprovalModal disabled={!(this.isProcurement || this.isSales) || approving} order={order} />
          )
        };
        buttons = [
          getButton(declineButton),
          getButton(duplicateButton, false),
          editMarginModal,
          getButton(saveButton),
          getButton(revokeApprovalButton),
          getButton(approvalButton, !this.isProcurement || approving)
        ];
        if (![T_SOFTGEL, T_CUSTOM].includes(order.settings.type)) buttons.push(approvalWithRawbidsModal);

        return buttons;
      case REQUESTAPPROVED:
        if (isProd) return [];
        buttons = [getButton(declineButton), getButton(duplicateButton, false), editMarginModal, getButton(editButton)];
        if (order.settings.type !== T_SERVICE) {
          buttons.push(getButton(requestApprovalButton));
          buttons.push({ key: "placeOrder", component: <PlaceOrderButton order={order} /> });
        }
        return buttons;
      case ORDERORDERCOMMODITIES:
        if (isProd) return [];
        buttons = [
          cancelModal,
          getButton(duplicateButton, false),
          editMarginModal,
          editCustomCalcModal,
          getButton(editButton)
        ];
        if (order.settings.type !== T_SERVICE) {
          buttons = buttons.concat([
            createContractModal,
            {
              key: "nextStep",
              component: (
                <NextStepButton order={order} packaging={context.packagings} onNextStep={this.handleNextStep} />
              )
            }
          ]);
        } else {
          buttons.push(getButton(finishButton));
        }
        return buttons;
      case CONTRACT:
        if (isProd) return [];
        const contractFinished = contractUtils.isContractFinished(order);
        buttons = [cancelModal];
        if (order.contract?.some(c => !c.called)) buttons.push(editMarginModal, editCustomCalcModal);
        buttons.push({ key: "createCall", component: <CreateCallModal order={order} disabled={contractFinished} /> });
        if (contractFinished) buttons.push(getButton(finishButton));
        return buttons;
      case WAITING:
        if (isProd) return [];
        buttons = [
          cancelModal,
          getButton(duplicateButton, false),
          editMarginModal,
          editCustomCalcModal,
          getButton(editButton)
        ];
        const queueButton = {
          text: "Next Step",
          classes: "btn-success",
          onClick: this.handleNextStepQueue
        };
        if (commodities.every(c => !!c.delivered) && packaging.every(p => !!p.delivered))
          buttons.push(getButton(queueButton));
        return buttons;
      case PRODUCTIONQUEUE:
        const productionReqErrors = orderUtils.validateOrderDataForProduction(order, context.packagings);
        const startProdButton = {
          text: "Start Production",
          classes: "btn-success",
          onClick: this.handleStartProduction
        };
        if (isProd) return [getButton(startProdButton, productionReqErrors.length > 0, productionReqErrors)];
        return [
          cancelModal,
          getButton(duplicateButton, false),
          editMarginModal,
          editCustomCalcModal,
          getButton(editButton),
          getButton(startProdButton, productionReqErrors.length > 0, productionReqErrors)
        ];
      case PRODUCTION:
        if (isProd) {
          return [
            { key: "returnToQueue", component: <ReturnToQueueModal order={order} /> },
            { key: "finishProduction", component: <FinishProductionModal {...this.props} /> }
          ];
        }
        return [
          getButton(duplicateButton, false),
          getButton(editButton),
          editCustomCalcModal,
          { key: "returnToQueue", component: <ReturnToQueueModal order={order} /> },
          { key: "finishProduction", component: <FinishProductionModal {...this.props} /> }
        ];
      case FULFILLMENT:
        if (isProd) {
          return [
            { key: "orderShipped", component: <SetDeliveryDateModal order={order} context={context} type="ship" /> }
          ];
        }
        if (isAdmin) {
          return [
            getButton(duplicateButton, false),
            { key: "revokeFulfillment", component: <RevokeFulfillmentModal order={order} context={context} /> },
            { key: "reviewReport", component: <ReviewProductionReportModal order={order} context={context} /> },
            { key: "orderShipped", component: <SetDeliveryDateModal order={order} context={context} type="ship" /> }
          ];
        }
        if (isSales)
          return [
            getButton(duplicateButton, false),
            { key: "reviewReport", component: <ReviewProductionReportModal order={order} context={context} /> },
            { key: "orderShipped", component: <SetDeliveryDateModal order={order} context={context} type="ship" /> }
          ];
        return [
          getButton(duplicateButton, false),
          { key: "orderShipped", component: <SetDeliveryDateModal order={order} context={context} type="ship" /> }
        ];
      case CREATEINVOICE:
        if (isProd) return [];
        const canFinish = accessUtils.canPerformAction(ACTIONS.ORDERFINISH);
        if (isAdmin)
          return [
            getButton(duplicateButton, false),
            { key: "revokeFulfillment", component: <RevokeFulfillmentModal order={order} context={context} /> },
            getButton(finishButton, !canFinish)
          ];
        return [getButton(duplicateButton, false), getButton(finishButton, !canFinish)];
      case ARCHIVE:
      case DECLINED:
        if (isProd) return [];
        return [getButton(duplicateButton, false)];
      default:
        return [];
    }
  }

  switchState = async (state: string, timelineEntry?: any, additionalUpdate?: any, onSuccess?: () => any) => {
    const { order } = this.props;
    try {
      let result;
      if (timelineEntry) result = await dbOrderService.switchState(order._id, state, timelineEntry, additionalUpdate);
      else result = await dbService.getDb()?.collection(ORDERS).updateOne({ _id: order._id }, { $set: { state } });
      if (result && result.modifiedCount) {
        toast.success("Order successfully updated");
        if (onSuccess) onSuccess();
        this.props.context.updateDocumentInContext(ORDERS, order._id);
      } else toast.error("Order could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  handleSimpleSwitchState = async (state: string, timelineType: string, onSuccess?: () => any) => {
    const timelineEntry = {
      _id: new BSON.ObjectId(),
      type: timelineType,
      date: new Date(),
      person: userService.getUserId()
    };
    await this.switchState(state, timelineEntry, undefined, onSuccess);
  };

  handleDecline = async () =>
    await this.handleSimpleSwitchState(DECLINED, T_DECLINED, () =>
      notificationService.notify(R_ORDERDECLINED, this.props.order._id)
    );

  handleRequestApproval = async () => {
    const timelineEntry = {
      type: T_REQUESTPENDING,
      date: new Date(),
      person: userService.getUserId()
    };
    await this.switchState(REQUESTPENDING, timelineEntry, {
      $set: {
        "calculations.$[].prices.$[].requested": false,
        "calculations.$[].prices.$[].updated": false,
        "calculations.$[].packagings.$[].requested": false,
        "calculations.$[].packagings.$[].updated": false
      }
    });
  };

  handleRevoke = async () => await this.handleSimpleSwitchState(OFFER, T_REQUESTREVOKED);
  handleNextStep = async () => await this.handleSimpleSwitchState(WAITING, T_WAITING);
  handleNextStepQueue = async () => await this.switchState(PRODUCTIONQUEUE);
  handleStartProduction = async () =>
    await this.handleSimpleSwitchState(PRODUCTION, T_PRODUCTION, () =>
      notificationService.notify(R_ORDERPRODUCTIONSTARTED, this.props.order._id)
    );
  handleArchive = async () =>
    await this.handleSimpleSwitchState(ARCHIVE, T_ORDERARCHIVED, () =>
      notificationService.notify(R_ORDERARCHIVED, this.props.order._id)
    );
  handleApprove = () => {
    const { order, onSave } = this.props;
    this.setState({ approving: true }, () =>
      onSave(
        true,
        () => {
          notificationService.notify(R_OFFERAPPROVED, order._id);

          const url =
            process.env.NODE_ENV === "production"
              ? "https://www.admincentral.private-label-factory.com/"
              : "http://localhost:3000/";

          // notify responsive person via slack
          const notificationMessage = `:information_source: Offer <${url}order/${order._id.toString()}|*${
            "AN-" + order.identifier
          }*> has been approved.`;

          // send message to sales employee. For dev cases #novacode-reviews will be used (done by backend function).
          slackService.sendMessage(order.createdFrom._id, notificationMessage);
        },
        () => this.setState({ approving: false })
      )
    );
  };

  handleSave = () => this.props.onSave();

  /**
   * Render the price information
   * @param description the description to display
   * @param data triple consisting of string, number and list of tuples or null
   * @param noLocale flag if value should not be parsed to locale currency string
   * @param valueClasses additional classes for the value
   * @returns {JSX.Element} element to render the information
   */
  renderPriceInfo(
    description: string,
    data: [string, number, any],
    noLocale?: boolean,
    valueClasses?: string
  ): JSX.Element {
    let averageValue = data[1].toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
    if (noLocale) averageValue = (Math.round(data[1] * 100) / 100).toString() + " %";
    return (
      <div className="kt-widget__item">
        <div className="kt-widget__icon">
          <i className="flaticon-piggy-bank" />
        </div>
        <div className="kt-widget__details">
          <span className="kt-widget__title">{description}</span>
          <span className={"kt-widget__value " + (valueClasses ? valueClasses : "")}>
            {data[0] + averageValue}
            {OrderHelper.renderDetailsTooltip(data[2], noLocale)}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { order, context } = this.props;
    const progress = orderUtils.getOrderProgress(order.state);
    const contract =
      order.contractInformation && order.contractInformation.contractId
        ? baseUtils.getDocFromCollection(context.orders, order.contractInformation.contractId)
        : null;
    const nameSplit = order.createdFor.name.split(/[ -]+/);
    const hasFfPriceInfo =
      [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state) && order.fulfillment && order.fulfillment.priceInfo;
    const unitPrice = hasFfPriceInfo ? this.getFulfillmentValue("unitPrice") : this.getAverageValue("unitprice");
    const unitPriceNaked = hasFfPriceInfo
      ? this.getFulfillmentValue("unitPriceNaked")
      : this.getAverageValue("unitpricenaked");
    const unitMargin = hasFfPriceInfo ? this.getFulfillmentValue("unitMargin") : this.getAverageValue("unitmargin");
    const totalPrice = hasFfPriceInfo ? this.getFulfillmentValue("totalPrice") : this.getAverageValue("totalprice");
    const percentMargin = hasFfPriceInfo
      ? this.getFulfillmentValue("percentMargin")
      : this.getAverageValue("percentmargin");
    const totalMargin = hasFfPriceInfo ? this.getFulfillmentValue("totalMargin") : this.getAverageValue("totalmargin");
    const marginClasses = orderUtils.getMarginProperties(+percentMargin[1]);
    const buttonDefinition = this.getButtons();
    const unitsDescription = this.getCalculationUnits();
    const orderCalculationInfo = order.calculations[0].info;
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    return (
      <div className="kt-portlet ">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
              <div className="kt-widget__media">
                <div className="kt-badge kt-badge--xl kt-badge--primary">
                  {(nameSplit.length > 1
                    ? nameSplit[0][0] + nameSplit[1][0]
                    : order.createdFor.name.slice(0, 2)
                  ).toUpperCase()}
                </div>
              </div>
              <div className="kt-widget__content">
                <div className="kt-widget__head">
                  <span className={order.state === DECLINED ? "kt-widget__title text-danger" : "kt-widget__title"}>
                    {this.getOrderDescription()}
                    {order.state === OFFER && (
                      <span style={{ fontSize: "12px", fontWeight: 100 }}>
                        {order.version !== null ? " Version " + order.version : "no version"}
                      </span>
                    )}
                    {order.state !== CONTRACT &&
                      order.contractInformation &&
                      order.contractInformation.contractId &&
                      contract && (
                        <span className="kt-font-regular">
                          <span className="mx-2">from Contract</span>
                          <Link
                            className="kt-link text-dark"
                            to={"/order/" + order.contractInformation.contractId.toString()}
                          >
                            {"AT-" + contract.identifier}
                          </Link>
                        </span>
                      )}
                    <br />
                    <span style={{ fontWeight: 200 }}>{order.title}</span>
                    <span className="ml-2" style={{ fontWeight: 200, fontSize: "14px" }}>
                      {order.subtitle}
                      {![ARCHIVE, DECLINED].includes(order.state) &&
                        accessUtils.canEditData(EDITLOCATIONS.ORDERTITLE) && (
                          <EditTitleModal context={context} order={order} />
                        )}
                    </span>
                  </span>
                  <div className="kt-widget__action">
                    {buttonDefinition.map(button => (
                      <React.Fragment key={button.key}>{button.component}</React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="kt-widget__info">
                  <CompanyWidget company={order.createdFor} additionalClasses={"mr-3"} />
                  <PersonWidget
                    orderForChangeModal={order}
                    person={order.createdFrom}
                    additionalClasses={"mr-3 mt-1"}
                  />
                  <div className="kt-widget__desc" />
                  <div className="kt-widget__stats d-flex align-items-center">
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Start Date
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {order.createdOn.toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Target Week
                        <EditTargetDateModal order={order} />
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {order.targetDate ? "CW " + dateUtils.getCW(order.targetDate) : "not set"}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Delivery
                        {(userService.hasOneOfRoles([ROLES.ADMIN, ROLES.DATAMAINTENANCE]) ||
                          [PRODUCTION, FULFILLMENT, CREATEINVOICE].includes(order.state)) && (
                          <SetDeliveryDateModal order={order} context={context} type="edit" />
                        )}
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-danger btn-sm btn-bold" style={{ width: "100%" }}>
                          {order.delivery ? "CW " + dateUtils.getCW(order.delivery) : "not set"}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Scheduled for
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-danger btn-sm btn-bold" style={{ width: "100%" }}>
                          {order.productionWeek ? "CW " + order.productionWeek.split("-")[0] : "not set"}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__item flex-fill" style={{ width: "200px", textAlign: "center" }}>
                      <span className="kt-widget__subtitel">Progress</span>
                      <div className="kt-widget__progress d-flex  align-items-center">
                        <div className="progress" style={{ height: "5px", width: "200px" }}>
                          <div
                            className={"progress-bar " + (order.state === DECLINED ? "kt-bg-danger" : "kt-bg-success")}
                            style={{ width: progress }}
                          />
                        </div>
                        <span className="kt-widget__stat">{progress}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-widget__bottom">
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-piggy-bank" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Units</span>
                  <span className="kt-widget__value">
                    {hasFfPriceInfo ? order.fulfillment?.totalUnits : unitsDescription}
                    {hasFfPriceInfo && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="originalUnitsInfo">
                            <span style={{ fontSize: "14px" }}>
                              Originally planned: <b>{unitsDescription} units</b>
                              {canSeeFinanceData && (
                                <>
                                  <br />
                                  Orig. Turnover (Unit): <b>{baseUtils.formatEuro(orderCalculationInfo.unitprice)}</b>
                                  <br />
                                  Orig. Turnover (Total): <b>{baseUtils.formatEuro(orderCalculationInfo.totalprice)}</b>
                                  <br />
                                  Orig. Margin (%): <b>{orderCalculationInfo.percentmargin.toFixed(2) + "%"}</b>
                                  <br />
                                  Orig. Margin (Unit): <b>{baseUtils.formatEuro(orderCalculationInfo.unitmargin)}</b>
                                  <br />
                                  Orig. Cost (Unit): <b>{baseUtils.formatEuro(orderCalculationInfo.unitpricenaked)}</b>
                                  <br />
                                  Orig. Margin (Total): <b>{baseUtils.formatEuro(orderCalculationInfo.totalmargin)}</b>
                                </>
                              )}
                            </span>
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-info-circle ml-2 text-muted" />
                      </OverlayTrigger>
                    )}
                  </span>
                </div>
              </div>
              {canSeeFinanceData && (
                <>
                  {this.renderPriceInfo("Turnover (Unit)", unitPrice)}
                  {this.renderPriceInfo("Turnover (Total)", totalPrice)}
                  {this.renderPriceInfo("Margin (%)", percentMargin, true, marginClasses)}
                  {this.renderPriceInfo("Margin (Unit)", unitMargin, false, marginClasses)}
                  {this.renderPriceInfo("Cost (Unit)", unitPriceNaked)}
                  {this.renderPriceInfo("Margin (Total)", totalMargin)}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface PlaceOrderButtonProps {
  order: CustomOrder;
}

const PlaceOrderButton: React.FunctionComponent<PlaceOrderButtonProps> = ({ order }) => {
  const context = useDataContext();
  return (
    <div className="btn-group">
      <PlaceOrderModal order={order} context={context} />
      {order.settings.type !== T_SERVICE && <CreateContractModal order={order} context={context} />}
    </div>
  );
};

interface NextStepButtonProps {
  order: CustomOrder;
  packaging: Array<PackagingsDocument>;
  onNextStep: () => Promise<void>;
}

const NextStepButton: React.FunctionComponent<NextStepButtonProps> = ({ order, packaging, onNextStep }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const containsLabel = () => {
    const calculation = order.calculations[0];
    return calculation.packagings.some(p => {
      const pack = packaging.find(pack => pack._id.toString() === p._id.toString());
      return pack && [PackagingTypes.LABEL, PackagingTypes.MULTILAYER_LABEL].includes(pack.packaging_type);
    });
  };

  const handleNextStep = () => {
    if (containsLabel()) handleShow();
    else onNextStep();
  };

  return (
    <>
      <SimpleConfirmationModal.SimpleConfirmationModal
        show={show}
        confirmButtonText={"Continue"}
        cancelButtonText={"Close"}
        modalDescription={
          <p>
            Please check if you uploaded a label file. <br />
            Do you want to continue?
          </p>
        }
        modalTitle={
          <>
            <i className="kt-font-brand fa fa-info mr-2" />
            Label required
          </>
        }
        onClose={handleClose}
        onConfirm={onNextStep}
      />
      <button className={"btn btn-sm btn-upper btn-success"} onClick={handleNextStep}>
        Next Step
      </button>
    </>
  );
};

export default OrderHeader;

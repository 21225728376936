import React, { useCallback, useMemo, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { BatchStatus } from "../../../utils/batchUtils";
import i18n from "../../../translations/i18n";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../utils/warehouseActionUtils";

export interface StatusContextMenuProps {
  status: BatchStatus;
  batchId: string;
  locationId: string;
}

export const StatusContextMenu: React.FC<StatusContextMenuProps> = ({ status, batchId, locationId }) => {
  const dispatch = useWarehouseDispatch();
  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const [show, setShow] = useState(false);
  const [statusColor, statusText] = useMemo(() => {
    switch (status) {
      case BatchStatus.AVAILABLE:
        return ["text-success", i18n.t("warehouse:available")];
      case BatchStatus.RESERVED:
        return ["text-danger", i18n.t("warehouse:reserved")];
      case BatchStatus.PARTIALLY_RESERVED:
        return ["text-warning", i18n.t("warehouse:partiallyReserved")];
      default:
        return ["text-warning", "N/A"];
    }
  }, [status]);

  const handleShow = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      setShow(!show);
    },
    [show]
  );
  const handleHide = useCallback(() => setShow(false), []);

  const handleTriggerAction = useCallback(
    (number: WarehouseActionNumber) => {
      setShow(false);
      dispatch({ type: WarehouseActionType.TRIGGER_ACTION, payload: { actionNumber: number, batchId, locationId } });
    },
    [batchId, locationId]
  );

  const handleCreateReservation = useCallback(() => handleTriggerAction(WarehouseActionNumber.CREATE_RESERVATION), []);
  const handleCancelReservation = useCallback(() => handleTriggerAction(WarehouseActionNumber.CANCEL_RESERVATION), []);

  return (
    <>
      <span className={"kt-font-bold " + statusColor} onClick={handleShow} role="button" style={{ paddingLeft: "1px" }}>
        <span ref={overlayRef}>{statusText}</span>
      </span>
      <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement={"bottom-start"}>
        <Popover id={"dropdown-popover"}>
          <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
            <span className="dropdown-item text-black" onClick={handleCreateReservation}>
              {i18n.t("warehouse:reserve")}
            </span>
            <span className="dropdown-item text-black" onClick={handleCancelReservation}>
              {i18n.t("warehouse:release")}
            </span>
            <span className="dropdown-item disabled text-muted">{i18n.t("warehouse:exchange")}</span>
            <span className="dropdown-item disabled text-muted">{i18n.t("warehouse:split")}</span>
            <span className="dropdown-item disabled text-muted">{i18n.t("warehouse:combine")}</span>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

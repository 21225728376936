import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps, LocationType, PUStorageSpaceAssignment } from "../../../model/warehouse/common.types";
import { useWarehouseContext } from "../../../context/warehouseContext";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import {
  DEFAULTWEIGHTUNIT,
  getWarehouseSelectOptions,
  SelectedBatchEntryType,
  SelectedCommodityEntryType
} from "../../../utils/warehouseUtils";
import { useDataContext } from "../../../context/dataContext";
import { BatchStatus, compareBatchLocations, getBatchLocationStatus } from "../../../utils/batchUtils";
import { resolveTranslation } from "../../../utils/translationUtils";
import { hasDuplicateLocations, hasOneLocation } from "../../../utils/warehouseStorageSpaceUtils";
import PackagingUnitEntry from "../common/PackagingUnitEntry";
import { WarehouseTypes } from "../../../model/configuration/warehouseConfiguration.types";
import AmountEntry, { RemoteWarehouseAssignment } from "../common/AmountEntry";
import { formatNumValue, getNumericValue } from "../../../utils/baseUtils";
import dbService, { BATCH } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import { SelectOption } from "../../../model/common.types";
import { Batch, BatchLocation } from "../../../model/warehouse/batch.types";
import { ReservationState } from "../../../model/warehouse/reservation.types";
import { isSelectedBatchEntries, isSelectedCommodityEntries } from "../../../utils/warehouseActionUtils";

enum ViewAction {
  NEXT = "next",
  PREVIOUS = "previous"
}

interface ChangeWarehouseModalState {
  view: number;
  batchEntry?: Batch;
  relevantLocations: Array<BatchLocation>;
  currentLocation?: BatchLocation;
  pUWarehouseAssignment: PUStorageSpaceAssignment;
  initialPUWarehouseAssignment: PUStorageSpaceAssignment;
  amountWarehouseAssignment?: Array<RemoteWarehouseAssignment>;
  initialAmountWarehouseAssignment?: RemoteWarehouseAssignment;
}

const getDefaultState = (): ChangeWarehouseModalState => {
  return {
    view: 0,
    batchEntry: undefined,
    relevantLocations: [],
    currentLocation: undefined,
    pUWarehouseAssignment: {},
    initialPUWarehouseAssignment: {},
    amountWarehouseAssignment: [],
    initialAmountWarehouseAssignment: {
      _id: new BSON.ObjectId(),
      amountForNewLocation: { value: 0, unit: DEFAULTWEIGHTUNIT },
      location: undefined
    }
  };
};

const getUpdatedAssignments = (newLocation: BatchLocation | undefined) => {
  const updatedData: PUStorageSpaceAssignment = {};
  const updatedAmountData: Array<RemoteWarehouseAssignment> = [];
  if (newLocation && newLocation.packagingUnits) {
    updatedAmountData.push({
      _id: new BSON.ObjectId(),
      amountForNewLocation: newLocation.amountAtLocation,
      location: newLocation.location.warehouseArea._id.toString()
    });
    newLocation.packagingUnits.forEach(packagingUnit => {
      const key = packagingUnit._id.toString();
      updatedData[key] = [
        {
          quantity: packagingUnit.quantity ?? 0,
          location: newLocation.location.warehouseArea._id.toString(),
          locationType: LocationType.PHYSICAL_WAREHOUSE
        }
      ];
    });
  }
  return { initialpUWarehouseAssignment: updatedData, amountWarehouseAssignment: updatedAmountData };
};

const ChangeWarehouseModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { selectedEntries, configuration } = warehouseContext;
  const { batch, reservation, updateDocumentInContext } = dataContext;

  const [state, setState] = useState(getDefaultState());
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (show) {
      let newBatch: Batch | undefined = undefined;
      let relevantLocations: Array<BatchLocation> = [];
      let currentLocation: BatchLocation | undefined = undefined;
      let initialpUWarehouseAssignment: PUStorageSpaceAssignment = {};
      let amountWarehouseAssignment: Array<RemoteWarehouseAssignment> = [];
      let selectedLocations: Array<string> = [];
      if (selectedEntries.length > 0 || actionTrigger) {
        if (actionTrigger) {
          newBatch = batch.find(b => b._id.toString() === actionTrigger.batchId);
          if (newBatch) selectedLocations = actionTrigger.locationId ? [actionTrigger.locationId] : [];
        } else if (isSelectedBatchEntries(selectedEntries)) {
          // parent batch from selected location
          newBatch = batch.find(b => b._id.toString() === selectedEntries[0].parentId);
          if (newBatch) {
            // all locations from selection
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedBatchEntryType.LOCATION)
              .map(sE => sE.childId!);
          }
        } else if (isSelectedCommodityEntries(selectedEntries)) {
          const batchLocationEntry = selectedEntries.find(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION);
          newBatch = batchLocationEntry ? batch.find(b => b._id.toString() === batchLocationEntry.batchId) : undefined;
          if (newBatch) {
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedCommodityEntryType.BATCH_LOCATION)
              .map(sE => sE.locationId!);
          }
        }
        if (newBatch) {
          relevantLocations = newBatch.locations.filter(l => selectedLocations.includes(l._id.toString()));
          if (relevantLocations.length > 0) {
            // start on first location
            currentLocation = relevantLocations[0];
            if (currentLocation) {
              const assignments = getUpdatedAssignments(currentLocation);
              initialpUWarehouseAssignment = assignments.initialpUWarehouseAssignment;
              amountWarehouseAssignment = assignments.amountWarehouseAssignment;
            }
          }
        }
      }

      setState(prevState => {
        return {
          ...prevState,
          batchEntry: newBatch,
          relevantLocations,
          currentLocation,
          initialPUWarehouseAssignment: initialpUWarehouseAssignment,
          initialAmountWarehouseAssignment: amountWarehouseAssignment[0],
          amountWarehouseAssignment,
          pUWarehouseAssignment: initialpUWarehouseAssignment
        };
      });
    }
  }, [show, batch, selectedEntries]);

  const currentLocationReservations = useMemo(() => {
    const { batchEntry, currentLocation } = state;
    if (!batchEntry || !currentLocation) return undefined;
    return reservation.filter(r =>
      r.batches.some(
        b =>
          b.batch._id.toString() === batchEntry._id.toString() &&
          compareBatchLocations(b.location, currentLocation.location) &&
          r.state !== ReservationState.DONE
      )
    );
  }, [state.batchEntry, reservation, state.currentLocation]);

  const warehouses = useMemo(
    () => (configuration ? configuration.values.warehouseStructure : undefined),
    [configuration]
  );

  const currentPhysicalWarehouse = useMemo(() => {
    const { currentLocation } = state;
    const logicalWarehouse = warehouses?.find(
      logicalWarehouse => logicalWarehouse._id.toString() === currentLocation?.location.warehouseSnapshot._id.toString()
    );
    return logicalWarehouse
      ? logicalWarehouse.physicalWarehouses.find(
          pw => pw._id.toString() === currentLocation?.location.warehouseArea._id.toString()
        )
      : undefined;
  }, [warehouses, state.currentLocation]);

  const warehouseSelections = useMemo(() => {
    return currentPhysicalWarehouse ? getWarehouseSelectOptions(warehouses, currentPhysicalWarehouse.type) : undefined;
  }, [warehouses, currentPhysicalWarehouse]);

  const locationStatus = useMemo(() => {
    const { batchEntry, currentLocation } = state;
    if (!batchEntry || !currentLocation) return undefined;
    return getBatchLocationStatus(batchEntry._id.toString(), currentLocation, reservation);
  }, [state.batchEntry, reservation, state.currentLocation]);

  const lastPage = useMemo(() => {
    const { relevantLocations, view } = state;
    return (
      relevantLocations === undefined ||
      relevantLocations?.length <= 1 ||
      (relevantLocations && relevantLocations.length - 1 === view)
    );
  }, [state.relevantLocations, state.view]);

  const disabled = useMemo(() => locationStatus === undefined, [locationStatus]);

  const { errors, warnings } = useMemo(() => {
    const { currentLocation } = state;
    const errors = new Set<string>();
    const warnings = new Set<string>();
    if (!currentLocation) errors.add(i18n.t("warehouse:noWarehouseToAssignError"));
    else {
      // errors and warnings for remote warehouse assignments through amount
      if (
        state.amountWarehouseAssignment &&
        currentPhysicalWarehouse &&
        currentPhysicalWarehouse.type === WarehouseTypes.REMOTE
      ) {
        const selectedAmount = state.amountWarehouseAssignment.reduce((acc, currentAssignment) => {
          return acc + currentAssignment.amountForNewLocation.value;
        }, 0);
        const sameDestination = hasDuplicateLocations(state.amountWarehouseAssignment);
        const diff = currentLocation.amountAtLocation.value - selectedAmount;
        if (diff && diff > 0) {
          const text = `${diff} ${currentLocation.amountAtLocation.unit} ${i18n.t("common:tooFew")}`;
          errors.add(text);
          warnings.add(text);
        } else if (diff && diff < 0) {
          const text = `${Math.abs(diff)} ${currentLocation.amountAtLocation.unit} ${i18n.t("common:tooMany")}`;
          errors.add(text);
          warnings.add(text);
        }
        if (sameDestination) errors.add(i18n.t("warehouse:warehouseAssignmentError"));
        if (state.amountWarehouseAssignment.some(wwa => !wwa.location))
          errors.add(i18n.t("warehouse:noLocationSelectedError"));
        if (currentLocation.amountAtLocation.value !== selectedAmount)
          errors.add(i18n.t("warehouse:storageSpaceAssignmentQuantityError"));
        const oneLocation = hasOneLocation(state.pUWarehouseAssignment);
        if (locationStatus === BatchStatus.RESERVED && !oneLocation)
          errors.add(i18n.t("warehouse:storageSpaceAssignmentDestinationError"));
        // errors for directly managed warehouse assignments through packaging units
      } else {
        for (let i = 0; i < currentLocation.packagingUnits.length; i++) {
          const pUId = currentLocation.packagingUnits[i]._id.toString();
          const pUQuantity = currentLocation.packagingUnits[i].quantity;
          const directlyManagedAssignementEntry = state.pUWarehouseAssignment[pUId];
          if (directlyManagedAssignementEntry) {
            const totalAmount = directlyManagedAssignementEntry.reduce(
              (acc, currentEntry) => acc + currentEntry.quantity,
              0
            );
            if (totalAmount !== pUQuantity) {
              errors.add(i18n.t("warehouse:storageSpaceAssignmentQuantityError"));
            }
            const duplicateLocations = hasDuplicateLocations(directlyManagedAssignementEntry);
            if (duplicateLocations) errors.add(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
            const remoteWarehouseAssignments = directlyManagedAssignementEntry.filter(
              ssae =>
                ssae.locationType === "physicalWarehouse" &&
                warehouses?.find(wh =>
                  wh.physicalWarehouses.find(
                    pwh => pwh._id.toString() === ssae.location && pwh.type === WarehouseTypes.REMOTE
                  )
                )
            );
            if (
              remoteWarehouseAssignments &&
              remoteWarehouseAssignments.length > 0 &&
              currentLocation.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED
            )
              warnings.add(i18n.t("warehouse:changeToRemoteWarehouseWarning"));
          }
        }
        const oneLocation = hasOneLocation(state.pUWarehouseAssignment);
        if (locationStatus === BatchStatus.RESERVED && !oneLocation)
          errors.add(i18n.t("warehouse:storageSpaceAssignmentDestinationError"));
      }
      // errors for both warehouse types
      if (locationStatus === BatchStatus.PARTIALLY_RESERVED) errors.add(i18n.t("warehouse:partiallyReservedWarning"));
    }
    return { errors: Array.from(errors), warnings: Array.from(warnings) };
  }, [
    state.currentLocation,
    state.pUWarehouseAssignment,
    state.amountWarehouseAssignment,
    currentPhysicalWarehouse,
    locationStatus,
    warehouses
  ]);

  const handleLocationChange = useCallback(
    (direction: ViewAction) => {
      setState(prevState => {
        const newView = direction === ViewAction.NEXT ? prevState.view + 1 : prevState.view - 1;
        const newLocation = state.relevantLocations.length > 0 ? state.relevantLocations[newView] : undefined;
        const newAssignment = getUpdatedAssignments(newLocation);
        return {
          ...prevState,
          view: newView,
          currentLocation: newLocation,
          pUWarehouseAssignment: newAssignment.initialpUWarehouseAssignment,
          initialPUWarehouseAssignment: newAssignment.initialpUWarehouseAssignment,
          amountWarehouseAssignment: newAssignment.amountWarehouseAssignment,
          initialAmountWarehouseAssignment: newAssignment.amountWarehouseAssignment[0]
        };
      });
    },
    [state.relevantLocations]
  );

  const handleAddAmountRow = useCallback(() => {
    const rows = state.amountWarehouseAssignment ? _.cloneDeep(state.amountWarehouseAssignment) : [];
    rows.push({
      _id: new BSON.ObjectId(),
      amountForNewLocation: { value: 0, unit: DEFAULTWEIGHTUNIT },
      location: undefined
    });
    setState(prevState => ({
      ...prevState,
      amountWarehouseAssignment: rows
    }));
  }, [state.amountWarehouseAssignment]);

  const handleAddRow = useCallback(
    (packagingUnitId: string) => {
      const rows = _.cloneDeep(state.pUWarehouseAssignment);
      rows[packagingUnitId].push({ quantity: 0, location: undefined, locationType: LocationType.PHYSICAL_WAREHOUSE });
      setState(prevState => ({
        ...prevState,
        pUWarehouseAssignment: rows
      }));
    },
    [state.pUWarehouseAssignment]
  );

  const handleDeleteAmountRow = useCallback(
    (id: string) => {
      const rows = state.amountWarehouseAssignment ? _.cloneDeep(state.amountWarehouseAssignment) : [];
      const idx = _.indexOf(
        rows,
        rows.find(r => r._id.toString() === id)
      );
      rows.splice(idx, 1);
      setState(prevState => ({
        ...prevState,
        amountWarehouseAssignment: rows
      }));
    },
    [state.amountWarehouseAssignment]
  );

  const handleDeleteRow = useCallback(
    (packagingUnitId: string, index: number) => {
      const rows = _.cloneDeep(state.pUWarehouseAssignment);
      rows[packagingUnitId].splice(index, 1);
      setState(prevState => ({
        ...prevState,
        pUWarehouseAssignment: rows
      }));
    },
    [state.pUWarehouseAssignment]
  );

  const handleChangeAmount = useCallback(
    (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const rows = state.amountWarehouseAssignment ? _.cloneDeep(state.amountWarehouseAssignment) : [];
      const row = rows.find(r => r._id.toString() === id);
      const value = getNumericValue(e);
      if (row && value) {
        row.amountForNewLocation.value = +value;
        setState(prevState => ({
          ...prevState,
          amountWarehouseAssignment: rows
        }));
      }
    },
    [state.amountWarehouseAssignment]
  );

  const handleChangeQuantityPUs = useCallback(
    (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const rows = _.cloneDeep(state.pUWarehouseAssignment);
      const value = getNumericValue(e);
      const entry = rows[packagingUnitId][index];
      entry.quantity = Number(value) ?? 0;
      setState(prevState => ({
        ...prevState,
        pUWarehouseAssignment: rows
      }));
    },
    [state.pUWarehouseAssignment]
  );

  const handeChangeAmountLocation = useCallback(
    (id: string, value?: SelectOption<LocationType>) => {
      const rows = state.amountWarehouseAssignment ? _.cloneDeep(state.amountWarehouseAssignment) : [];
      const row = rows.find(r => r._id.toString() === id);
      if (row) {
        row.location = value ? value.value : undefined;
        setState(prevState => ({
          ...prevState,
          amountWarehouseAssignment: rows
        }));
      }
    },
    [state.amountWarehouseAssignment]
  );

  const handeChangeLocation = useCallback(
    (packagingUnitId: string, index: number, value?: SelectOption<LocationType>) => {
      const rows = _.cloneDeep(state.pUWarehouseAssignment);
      const entry = rows[packagingUnitId][index];
      entry.location = value ? value.value : undefined;
      entry.locationType = LocationType.PHYSICAL_WAREHOUSE;
      setState(prevState => ({
        ...prevState,
        pUWarehouseAssignment: rows
      }));
    },
    [state.pUWarehouseAssignment]
  );

  const handleSave = useCallback(async () => {
    const {
      batchEntry,
      currentLocation,
      initialAmountWarehouseAssignment,
      amountWarehouseAssignment,
      initialPUWarehouseAssignment,
      pUWarehouseAssignment
    } = state;
    if (!batchEntry || !currentLocation) return;
    setSaving(true);
    try {
      const res = await dbService.callFunction<boolean>(
        "changeWarehouse",
        [
          batchEntry._id,
          currentLocation._id,
          pUWarehouseAssignment,
          initialPUWarehouseAssignment,
          amountWarehouseAssignment,
          initialAmountWarehouseAssignment,
          locationStatus,
          currentLocationReservations
        ],
        true
      );
      await toastUtils.databaseOperationToast(
        res,
        `${i18n.t("common:upload")} ${i18n.t("warehouse:successfully")}`,
        `${i18n.t("common:error")} ${i18n.t("common:upload")}`,
        () => updateDocumentInContext(BATCH, batchEntry._id)
      );
    } catch (e) {
      toast.error(`${i18n.t("common:error")} ${i18n.t("common:upload")} ${e}`);
    } finally {
      setSaving(false);
      if (lastPage) {
        onHide();
      }
    }
  }, [state, lastPage, locationStatus, currentLocationReservations]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton onClick={onHide}>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:putGoodsInOtherWarehouse")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {state.batchEntry && currentPhysicalWarehouse ? (
            <>
              <div className="alert alert-warning mt-2" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-warning" />
                </div>
                <div className="alert-text"> {i18n.t("warehouse:justWarehouseChangeWarning")}</div>
              </div>
              <div className="row">
                <div className="col-9 kt-font-bold kt-font-dark">
                  {resolveTranslation(state.batchEntry.content.details.title)}
                </div>
                {state.currentLocation && (
                  <div className="col-3 kt-font-dark">
                    <small className="float-right">{`${i18n.t("common:page")} ${state.view + 1}/${
                      state.relevantLocations?.length
                    }`}</small>
                  </div>
                )}
                <div className="col-12 kt-font-bold kt-font-dark">
                  <small className="kt-font-regular">
                    {resolveTranslation(state.batchEntry.content.details.subtitle)}
                  </small>
                </div>
                <div className="col-12 kt-font-dark">
                  {i18n.t("warehouse:lot")}: {state.batchEntry.lot}
                </div>
              </div>
              <div className="mt-3">
                <div className="font-size-lg text-black d-inline mt-3 mb-2" style={{ fontWeight: 500 }}>
                  {i18n.t("warehouse:currentPlace")}:
                </div>
                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot d-inline-flex my-0">
                  <li className="breadcrumb-item text-black">
                    {state.currentLocation &&
                      resolveTranslation(state.currentLocation.location.warehouseSnapshot.warehouseName)}
                  </li>
                  <li className="breadcrumb-item text-black">
                    {state.currentLocation &&
                      resolveTranslation(state.currentLocation.location.warehouseArea.warehouseName)}
                  </li>
                  {state.currentLocation && state.currentLocation.location.storageSpace && (
                    <li className="breadcrumb-item text-black">
                      {state.currentLocation.location.storageSpace.storageSpaceNo}
                    </li>
                  )}
                </ul>
              </div>
              {currentPhysicalWarehouse.type === WarehouseTypes.REMOTE ? (
                <>
                  <hr />
                  <div className="row my-2">
                    <div className="col-12 text-black">
                      {`${i18n.t("invoice:totalAmount")}: ${
                        state.currentLocation && formatNumValue(state.currentLocation.amountAtLocation)
                      }`}
                    </div>
                  </div>
                  {state.amountWarehouseAssignment?.map(wWA => {
                    if (warehouseSelections && state.currentLocation && state.amountWarehouseAssignment)
                      return (
                        <AmountEntry
                          key={wWA._id.toString()}
                          amountAtLocation={state.currentLocation.amountAtLocation}
                          remoteWarehouseAssignment={wWA}
                          allRemoteWarehouseAssignments={state.amountWarehouseAssignment}
                          selectOptions={warehouseSelections}
                          disabled={locationStatus === BatchStatus.PARTIALLY_RESERVED}
                          onDeleteRow={handleDeleteAmountRow}
                          onChangeAmount={handleChangeAmount}
                          onChangeLocation={handeChangeAmountLocation}
                        />
                      );
                  })}
                  <div className="row mt-2">
                    <div className="col-11 text-warning">{warnings.join(", ")}</div>
                    <div className="col-1 text-right pr-0">
                      <button className={"btn btn-success px-2 py-1 "} onClick={handleAddAmountRow}>
                        <i className="fa fa-plus p-0" />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                state.currentLocation?.packagingUnits.map(pU => {
                  if (warehouseSelections)
                    return (
                      <PackagingUnitEntry
                        key={pU._id.toString()}
                        pU={pU}
                        storageSpaceAssignment={state.pUWarehouseAssignment[pU._id.toString()]}
                        selectOptions={warehouseSelections}
                        additionalWarnings={warnings}
                        forWarehouse={true}
                        onAddRow={handleAddRow}
                        onDeleteRow={handleDeleteRow}
                        onChangeAmount={handleChangeQuantityPUs}
                        onChangeLocation={handeChangeLocation}
                      />
                    );
                })
              )}
              <hr className="w-100" />
              <span className="font-size-lg text-black d-inline mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:bookingNotes")}:
              </span>
              <textarea className="form-control" rows={3} placeholder={i18n.t("warehouse:hintsForHistory")} />
            </>
          ) : (
            <div className="text-muted mt-3">{i18n.t("warehouse:noWarehouseToAssignError")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={saving ? undefined : state.view === 0 ? onHide : () => handleLocationChange(ViewAction.PREVIOUS)}
        >
          {i18n.t(`common:${state.view === 0 ? "close" : "back"}`)}
        </button>
        {!lastPage && (
          <button
            type="button"
            className={"btn btn-secondary " + (saving ? "disabled" : "")}
            onClick={saving ? undefined : () => handleLocationChange(ViewAction.NEXT)}
          >
            {i18n.t("common:next")}
          </button>
        )}
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className={"btn btn-success"}
          errors={errors}
          saving={saving}
          disabled={disabled}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeWarehouseModal;

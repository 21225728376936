import React, { PureComponent } from "react";
import { toAbsoluteUrl } from "../../_metronic";
import Pagination from "../common/Pagination";

interface BaseTableProps {
  headerDefinition: Array<{ title: string; size?: number; colSpan?: number; spanClasses?: string }>;
  documents: Array<any>;
  bodyContent: JSX.Element;
  currentPage: number;
  pageSize: number;
  baseSize?: number;
  tableStyle?: React.CSSProperties;
  additionalTableClasses?: string;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

interface BaseTableState {}

class BaseListing extends PureComponent<BaseTableProps, BaseTableState> {
  render() {
    const {
      headerDefinition,
      documents,
      bodyContent,
      currentPage,
      pageSize,
      baseSize,
      tableStyle,
      additionalTableClasses,
      onPageChange,
      onPageSizeChange
    } = this.props;
    return (
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
        <table className={"kt-datatable__table d-table " + (additionalTableClasses ?? "")} style={tableStyle}>
          <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
            <tr className="kt-datatable__row d-table-row">
              {headerDefinition.map(def => (
                <th
                  key={def.title + def.size}
                  className="kt-datatable__cell d-table-cell"
                  style={{ width: `${def.size}%` }}
                >
                  <span className={def.spanClasses || "text-black"}>{def.title}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
            {documents && documents.length > 0 ? (
              bodyContent
            ) : (
              <tr>
                <td colSpan={headerDefinition.length}>
                  <img
                    src={toAbsoluteUrl("/media/img/no_results.jpg")}
                    className="d-block my-0 mx-auto "
                    style={{ height: "500px" }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {documents && pageSize && documents.length > pageSize && (
          <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
            <Pagination
              itemsCount={documents.length}
              pageSize={pageSize}
              baseSize={baseSize}
              onPageChange={onPageChange}
              currentPage={currentPage}
              onPageSizeChange={onPageSizeChange}
              additionalPageSizeClasses={"ml-2"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BaseListing;

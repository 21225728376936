import { Document, Model } from "mongoose";
import { BSON } from "realm-web";

export enum ManufacturerFeature {
  PRODUCTION_REPORT_CONFIRMATION = "productionReportConfirmation",
  PRODUCTION_REPORT_AUTO_CONFIRMATION = "productionReportAutoConfirmation"
}

export interface Manufacturers {
  name: string;
  person: BSON.ObjectId;
  city: string;
  country: string;
  notificationSettings?: ManufacturerNotificationSettings;
  tablets?: CapabilitiesTablets;
  powder?: CapabilitiesPowder;
  liquids?: CapabilitiesLiquids;
  capsules?: CapabilitiesCapsules;
  productionLines: Array<ProductionLine>;
  employees: Array<BSON.ObjectId>;
  features?: Array<ManufacturerFeature>;
}

export interface ManufacturerNotificationSettings {
  targetWeekChanges?: {
    mailAddress: string;
    isEnabled: boolean;
  };
}

interface CapabilitiesTablets {
  tableting: [{ amount: string; price: string }];
  bottling: [{ amount: string; price: string }];
}

interface CapabilitiesPowder {
  blending: [{ amount: string; price: string }];
  bottling: [{ amount: string; price: string }];
}

interface CapabilitiesLiquids {
  blending: [{ amount: string; price: string }];
  bottling: [{ amount: string; price: string }];
}

interface CapabilitiesCapsules {
  bottling: [{ amount: string; price: string }];
  encapsulation: [{ size: string; cost: number }];
  blistering: [{ amount: string; price: string }];
}

export interface Capabilities {
  tablets?: CapabilitiesTablets;
  powder?: CapabilitiesPowder;
  liquids?: CapabilitiesLiquids;
  capsules?: CapabilitiesCapsules;
}

export interface ProductionLine {
  _id: BSON.ObjectId;
  image: string;
  name: string;
  lastUpdate: Date;
  capacity: number;
  type: string;
}

export interface ManufacturersDocument extends Manufacturers, Document {}

export interface ManufacturersModel extends Model<ManufacturersDocument> {}

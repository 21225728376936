import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import { Table } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import dbService, { COMMODITYCATEGORIES } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import { SearchBar } from "../../listings/common/Filters";
import DeleteSettingValueModal from "../DeleteSettingValueModal";
import Pagination, { paginate } from "../../common/Pagination";
import { PaginationState } from "../../common/CustomTypes";
import CreateNewCommodityCategoryModal from "./CreateNewCommodityCategoryModal";

interface CommodityCategoriesProps extends RouteComponentProps<{}, {}, {}> {}

interface CommodityCategoriesState extends PaginationState {
  query: string;
}

class CommodityCategories extends PureComponent<CommodityCategoriesProps, CommodityCategoriesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CommodityCategoriesProps) {
    super(props);
    this.state = { query: "", pageSize: 10, currentPage: 1 };
  }

  /**
   * Handles deleting a commodity category.
   * @param _id: ID of the commodity category that should be deleted
   */
  handleDeleteCommodityCategories = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(COMMODITYCATEGORIES, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Commodity category successfully deleted",
      "Deleting commodity category failed",
      () => updateDocumentInContext(COMMODITYCATEGORIES, _id)
    );
  };

  render() {
    const { history } = this.props;
    const { query, currentPage, pageSize } = this.state;
    const { commodities, commoditycategories } = this.context;

    const ql = query.toLowerCase();
    const categories = _.cloneDeep(
      commoditycategories
        .filter(c => c.name.de.toLowerCase().includes(ql) || c.name.en.toLowerCase().includes(ql))
        .sort((c1, c2) => c1.identifierReference.localeCompare(c2.identifierReference))
    );

    const paginatedCategories = paginate(categories, currentPage, pageSize);

    return (
      <>
        <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
          <div className="alert-icon">
            <i className="flaticon-warning kt-font-danger" />
          </div>
          <div className="alert-text">
            Please note that changing these properties will also have a retroactive effect. The new wording is used
            wherever this property has already been used. In order to avoid complications, the system does a check if
            the properties are already used somewhere, so these properties cannot be deleted afterwards. Please be
            careful when changing these properties.
          </div>
        </div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fas fa-fill-drip" />
              </span>
              <h3 className="kt-portlet__head-title">Commodity Categories</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-clean kt-margin-r-10"
                >
                  <i className="la la-arrow-left" />
                  <span className="kt-hidden-mobile">Back</span>
                </button>
                <CreateNewCommodityCategoryModal />
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-form kt-form--label-right">
              <div className="row align-items-center">
                <SearchBar
                  onSearch={e => this.setState({ query: e.target.value })}
                  additionalSizeClasses={"col-md-2"}
                />
                <div className="col-xl-4 order-1 order-xl-2 kt-align-right">
                  <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none" />
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>German</th>
                    <th style={{ width: "25%" }}>English</th>
                    <th style={{ width: "15%" }}>Code Reference</th>
                    <th style={{ width: "15%" }}>
                      Identifier Reference <i className="la la-arrow-up" />
                    </th>
                    <th className="text-right" style={{ width: "20%" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body">
                  {paginatedCategories.map(cat => {
                    const isUsed = commodities.some(com => com.category.toString() === cat._id.toString());
                    return (
                      <tr key={cat._id.toString()}>
                        <td className="align-middle">{cat.name.de}</td>
                        <td className="align-middle">{cat.name.en}</td>
                        <td className="align-middle">{cat.internal_code_reference}</td>
                        <td className="align-middle">{cat.identifierReference}</td>
                        <td className="text-right align-middle">
                          <span className="mr-2">
                            <CreateNewCommodityCategoryModal commodityCategory={cat} />
                          </span>
                          <DeleteSettingValueModal
                            _id={cat._id}
                            name={cat.name}
                            title={"Commodity Category"}
                            onDelete={() => this.handleDeleteCommodityCategories(cat._id)}
                            disabled={isUsed}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={categories.length}
                  pageSize={pageSize}
                  onPageChange={page => this.setState({ currentPage: page })}
                  currentPage={currentPage}
                  onPageSizeChange={pageSize => this.setState({ currentPage: 1, pageSize })}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CommodityCategories);

import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import { BSON } from "realm-web";
import i18n from "../../../translations/i18n";
import { useDataContext } from "../../../context/dataContext";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import { useWarehouseContext, WarehouseContext } from "../../../context/warehouseContext";
import {
  Batch,
  BatchFile,
  PackagingUnit,
  SenderType,
  StorageSpaceSnapshot
} from "../../../model/warehouse/batch.types";
import { BaseActionModalProps, BatchContentSpecificType, ContentType } from "../../../model/warehouse/common.types";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { CompaniesDocument } from "../../../model/companies.types";
import { getBatchFileEntry } from "../../../utils/batchUtils";
import supplierUtils from "../../../utils/suppliersUtils";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import {
  FileType,
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition,
  WarehouseTypes
} from "../../../model/configuration/warehouseConfiguration.types";
import dbService, { BATCH } from "../../../services/dbService";
import baseUtils, { getNumericValue } from "../../../utils/baseUtils";
import fileUtils from "../../../utils/fileUtils";
import toastUtils from "../../../utils/toastUtils";
import { DEFAULTWEIGHTUNIT } from "../../../utils/warehouseUtils";
import { resolveTranslation } from "../../../utils/translationUtils";
import Tooltip from "../../common/Tooltip";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import AddStorageSpaceOnBookIn from "../common/AddStorageSpaceOnBookIn";
import UploadFileDragNDrop from "../common/UploadFileDragNDrop";
import commodityUtils from "../../../utils/commodityUtils";

enum Views {
  SELECTPRODUCTANDSUPPLIER,
  ADDDETAILS,
  ASSIGNSTORAGESPACE
}

interface SenderSelectOption {
  value: string;
  label: string;
  typeLabel: string;
  senderType: string;
}

interface PackagingInputs {
  _id: BSON.ObjectId;
  quantityPUs: string;
  packagingUnit: { value: string; label: string };
  amountPerUnit: string;
  totalAmount: string;
  disabled: boolean;
}

export interface BatchInput {
  _id: BSON.ObjectId;
  packagingInputs: Array<PackagingInputs>;
  lot: string;
  bbd: Date;
  files: Array<BatchFile>;
  damageFiles: Array<string>;
  damage: boolean;
  damageMessage: string;
  price: string;
  showUpload: boolean;
  existingBatch?: Batch;
  storageSpace?: { value: string; label: string; storageSpace: StorageSpace | undefined };
}

interface CreateStockModalState {
  view: Views;
  sender: SenderSelectOption;
  contentId: { value: string; label: string };
  contentObject?: CommoditiesDocument;
  category?: string;
  form?: string;
  senderObject?: SuppliersDocument | ManufacturersDocument | CompaniesDocument;
  batches: Array<BatchInput>;
  warehouseSelect: {
    value: string;
    label: string;
    object?: { warehouse?: WarehouseDefinition; physicalWarehouse?: PhysicalWarehouse };
  };
  specificProductType: { value: string; label: string };
  showArticleDetails: boolean;
}

const getWarehouseOption = (wh: WarehouseDefinition | undefined, pwh: PhysicalWarehouse | undefined) => {
  return {
    value: pwh ? pwh._id.toString() : "",
    label:
      wh && pwh
        ? `${resolveTranslation(wh.warehouseName)} (${wh.shortName}) - ${resolveTranslation(pwh.warehouseName)} (${
            pwh.shortName
          })`
        : "",
    object: {
      warehouse: wh,
      physicalWarehouse: pwh
    }
  };
};

const getSenderSelectOption = (sender: SuppliersDocument | ManufacturersDocument | CompaniesDocument) => {
  const type = supplierUtils.isSupplier(sender)
    ? SenderType.SUPPLIER
    : manufacturerUtils.isManufacturer(sender)
    ? SenderType.MANUFACTURER
    : SenderType.CUSTOMER;
  const typeLabel = supplierUtils.isSupplier(sender)
    ? i18n.t("warehouse:supplier")
    : manufacturerUtils.isManufacturer(sender)
    ? i18n.t("warehouse:manufacturer")
    : i18n.t("warehouse:customer");
  return {
    value: sender._id.toString(),
    label: sender.name,
    typeLabel,
    senderType: type
  };
};

const getDefaultState = (warehouseContext: WarehouseContext): CreateStockModalState => {
  const firstWarehouse = warehouseContext.configuration?.values.warehouseStructure[0];
  const firstPhysicalWarehouse = firstWarehouse?.physicalWarehouses[0];
  const emptySelect = { value: "", label: "" };
  return {
    view: Views.SELECTPRODUCTANDSUPPLIER,
    sender: { value: "", label: "", senderType: "", typeLabel: "" },
    contentId: emptySelect,
    contentObject: undefined,
    category: undefined,
    form: undefined,
    senderObject: undefined,
    batches: [],
    warehouseSelect: getWarehouseOption(firstWarehouse, firstPhysicalWarehouse),
    specificProductType: emptySelect,
    showArticleDetails: false
  };
};

const CreateStockModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const articleRef = useRef(null);
  const senderRef = useRef(null);

  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { updateDocumentInContext } = dataContext;

  const [state, setState] = useState<CreateStockModalState>(getDefaultState(warehouseContext));
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    setState(getDefaultState(warehouseContext));
  }, [warehouseContext, show]);

  useEffect(() => {
    if (show && articleRef.current) {
      //@ts-ignore
      articleRef.current.focus();
    }
  }, [show]);

  const productTypes = useMemo(() => {
    return [
      { value: BatchContentSpecificType.POWDER, label: i18n.t("warehouse:powder") },
      {
        value: BatchContentSpecificType.LIQUID,
        label: i18n.t("warehouse:liquid")
      }
      // filtered atm until we can handle softgels properly with the units
      // { value: BatchContentSpecificType.SOFTGELS, label: i18n.t("warehouse:softgels") }
    ];
  }, []);

  const commoditySelections = useMemo(() => {
    const filteredCommodities = dataContext.commodities.filter(
      c =>
        !(c.type === "purchased" || (!c.type && c.form === "custom") || c.type === "softgel") &&
        commodityUtils.isCommodityApproved(c)
    );
    return filteredCommodities.map(c => {
      return {
        value: c._id.toString(),
        label: baseUtils.truncateString(
          resolveTranslation(c.title) + (c.subtitle.de !== "" ? " (" + resolveTranslation(c.subtitle) + ")" : ""),
          55
        )
      };
    });
  }, [dataContext.commodities]);

  const senderSelectOptions = useMemo(() => {
    let allSenders = [];
    const suppliers = dataContext.suppliers.map(s => {
      return getSenderSelectOption(s);
    });
    const manufacturer = dataContext.manufacturers.map(m => {
      return getSenderSelectOption(m);
    });
    const customers = dataContext.companies.map(c => {
      return getSenderSelectOption(c);
    });
    allSenders = suppliers.concat(manufacturer);
    allSenders = allSenders.concat(customers);
    return allSenders;
  }, [dataContext.suppliers, dataContext.manufacturers, dataContext.companies]);

  const warehouseSelectOptions = useMemo(() => {
    return warehouseContext.configuration?.values.warehouseStructure.flatMap(wh => {
      return wh.physicalWarehouses.map(pwh => {
        return getWarehouseOption(wh, pwh);
      });
    });
  }, [warehouseContext]);

  const errors = useMemo(() => {
    const newErrors = [];
    let lotExistsAlready = false;
    let hasPUTooSmall = false;
    if (state.view === Views.SELECTPRODUCTANDSUPPLIER) {
      if (state.sender.value === "" && !state.senderObject)
        newErrors.push(i18n.t("warehouse:createStockErrorMissingSupplier"));
      if (state.contentId.value === "" && !state.contentObject)
        newErrors.push(i18n.t("warehouse:createStockErrorMissingCommodity"));
      if (state.specificProductType.value === "")
        newErrors.push(`${i18n.t("warehouse:articleCategory")} ${i18n.t("warehouse:missing")}`);
    } else if (state.view === Views.ADDDETAILS) {
      for (let i = 0; i < state.batches.length; i++) {
        const b = state.batches[i];
        if (
          dataContext.batch.some(
            dcB =>
              dcB.lot === b.lot &&
              (dcB.content.details._id.toString() !== state.contentId.value ||
                dcB.sender.senderId !== state.sender.value)
          )
        )
          lotExistsAlready = true;
        for (let j = 0; j < b.packagingInputs.length; j++) {
          const pU = warehouseContext.configuration?.values.packagingUnitDefinitions.find(
            pu => pu._id.toString() === b.packagingInputs[j].packagingUnit.value
          );
          if (pU && pU.maxFillingWeight && pU.maxFillingWeight.value < +b.packagingInputs[j].amountPerUnit)
            hasPUTooSmall = true;
        }
      }
      if (lotExistsAlready) newErrors.push(i18n.t("warehouse:lotAlreadyExists"));
      if (state.batches.some(b => b.packagingInputs.some(pui => +pui.quantityPUs < 0)))
        newErrors.push(i18n.t("warehouse:createStockErrorAmount"));
      if (state.batches.some(b => b.packagingInputs.some(pui => +pui.amountPerUnit < 0)))
        newErrors.push(i18n.t("warehouse:amountPerPUError"));
      if (
        state.batches.some(b =>
          b.packagingInputs.some(pui => pui.quantityPUs.includes(",") || pui.quantityPUs.includes("."))
        )
      )
        newErrors.push(i18n.t("warehouse:pUAmountNoCommaError"));
      if (
        state.batches.some(b =>
          b.packagingInputs.some(pui => +pui.totalAmount !== +pui.quantityPUs * +pui.amountPerUnit)
        )
      )
        newErrors.push(i18n.t("warehouse:amountsNotMatchingError"));
      if (state.batches.some(b => b.packagingInputs.some(pui => pui.packagingUnit.value === "")))
        newErrors.push(i18n.t(`warehouse:pUMissing`));
      if (state.batches.some(b => +b.price < 0)) newErrors.push(i18n.t("warehouse:stockCreationPriceError"));
      if (state.batches.some(b => b.packagingInputs.some(pui => pui.quantityPUs === "0" || pui.quantityPUs === "")))
        newErrors.push(i18n.t("warehouse:pUQuantityMissing"));
      if (state.batches.some(b => b.packagingInputs.some(pui => pui.amountPerUnit === "0" || pui.amountPerUnit === "")))
        newErrors.push(i18n.t("warehouse:pUAmountPerUnitMissing"));
      if (state.batches.some(b => b.lot === "")) newErrors.push(i18n.t("warehouse:lotMissing"));
      if (state.batches.some(b => b.price === "")) newErrors.push(i18n.t("warehouse:priceMissing"));
      if (hasPUTooSmall) newErrors.push(i18n.t("warehouse:packagingUnitTooSmall"));
    }
    return newErrors;
  }, [state, dataContext.batch]);

  const handleChangeCommodity = useCallback(
    (selected: any) => {
      if (selected.length > 0) {
        const newContentId = selected[0].value;
        const content = dataContext.commodities.find(c => c._id.toString() === newContentId);
        const formObj = dataContext.compositions.find(comp => comp._id.toString() === content?.form.toString())?.name;
        const categoryObj = dataContext.commoditycategories.find(
          cc => cc._id.toString() === content?.category.toString()
        )?.name;
        const form = formObj ? resolveTranslation(formObj) : "-";
        const category = categoryObj ? resolveTranslation(categoryObj) : "-";
        if (content)
          setState(prevState => {
            return {
              ...prevState,
              contentId: {
                value: newContentId,
                label: baseUtils.truncateString(
                  resolveTranslation(content.title) +
                    (content.subtitle.de !== "" ? " (" + resolveTranslation(content.subtitle) + ")" : ""),
                  55
                )
              },
              contentObject: content,
              form,
              category
            };
          });
        if (senderRef.current) {
          //@ts-ignore
          senderRef.current.focus();
        }
      } else {
        setState(prevState => {
          return {
            ...prevState,
            contentId: { value: "", label: "" },
            contentObject: undefined,
            form: undefined,
            category: undefined
          };
        });
      }
    },
    [dataContext]
  );

  const handleChangeType = useCallback(
    (e: any) => {
      const newType = e.value;
      const productType = productTypes.find(pt => pt.value === newType);
      if (productType)
        setState(prevState => {
          return { ...prevState, specificProductType: productType };
        });
    },
    [productTypes]
  );

  const handleChangeSupplier = useCallback(
    (e: any) => {
      if (e[0]) {
        const newSender = e[0];
        const supplier =
          newSender.senderType === SenderType.SUPPLIER
            ? dataContext.suppliers.find(s => s._id.toString() === newSender.value)
            : newSender.senderType === SenderType.MANUFACTURER
            ? dataContext.manufacturers.find(m => m._id.toString() === newSender.value)
            : dataContext.companies.find(c => c._id.toString() === newSender.value);
        if (supplier)
          setState(prevState => {
            return { ...prevState, sender: newSender, senderObject: supplier };
          });
      } else {
        setState(prevState => {
          return {
            ...prevState,
            sender: { value: "", label: "", senderType: "", typeLabel: "" },
            senderObject: undefined
          };
        });
      }
    },
    [dataContext.suppliers, dataContext.companies, dataContext.manufacturers]
  );

  const handleChangeWarehouse = useCallback((e: any) => {
    const newWarehouse = e;
    setState(prevState => {
      return {
        ...prevState,
        warehouseSelect: newWarehouse
      };
    });
  }, []);

  const handleChangeView = useCallback(
    (view: number) => {
      if (
        state.view === Views.SELECTPRODUCTANDSUPPLIER &&
        view === Views.ADDDETAILS &&
        state.batches.length === 0 &&
        state.contentObject &&
        state.senderObject
      ) {
        const batches = _.clone(state.batches) ?? [];
        batches.push(getDefaultBatchInput());
        setState(prevState => {
          return { ...prevState, view, batches };
        });
      } else {
        setState(prevState => {
          return { ...prevState, view };
        });
      }
    },
    [state.view, state.batches, state.contentObject, state.senderObject]
  );

  const handleAddRow = useCallback(() => {
    if (state.contentObject && state.senderObject) {
      const batches = _.clone(state.batches) ?? [];
      batches.push(getDefaultBatchInput());
      setState(prevState => {
        return { ...prevState, batches };
      });
    }
  }, [state.batches, state.contentObject, state.senderObject]);

  const handleRemoveRow = useCallback(
    (batchId: string) => {
      const batches = _.cloneDeep(state.batches);
      const changedBatches = batches.filter(b => b._id.toString() !== batchId);
      setState(prevState => {
        return { ...prevState, batches: changedBatches };
      });
    },
    [state.batches]
  );

  const handleChangePackagingUnit = useCallback(
    (batchId: string, pUId: string, e: { value: string; label: string }) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      const pU = changeBatch?.packagingInputs.find(pui => pui._id.toString() === pUId);
      const packagingUnit = warehouseContext.configuration?.values.packagingUnitDefinitions.find(
        pu => pu._id.toString() === e.value
      );
      if (changeBatch && pU !== undefined && packagingUnit) {
        pU.packagingUnit = {
          value: e.value,
          label: resolveTranslation(packagingUnit.label)
        };
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches, warehouseContext.configuration?.values.packagingUnitDefinitions]
  );

  const handleChangeInputs = useCallback(
    (batchId: string, value: React.ChangeEvent<HTMLInputElement>) => {
      const batches = _.cloneDeep(state.batches);
      const key = value.target.name;
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        if (key === "price") {
          let newValue = value.currentTarget.value.replaceAll(/^0+/g, "0");
          if (!Number(newValue) && Number(newValue) !== 0) return;
          if (!newValue.includes(".")) newValue = newValue !== "" ? Number(newValue).toString() : "";
          changeBatch.price = newValue;
        }
        if (key === "mhd") changeBatch.bbd = new Date(value.currentTarget.value);
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleChangePackagingInputs = useCallback(
    (batchId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const batches = _.cloneDeep(state.batches);
      const key = e.target.name;
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      const pU = changeBatch?.packagingInputs.find(pui => pui._id.toString() === pUId);
      if (pU) {
        if (key === "quantityPus") pU.quantityPUs = (Number(parseInt(e.target.value) || "0") || "").toString();
        else if (key === "amountPerUnit") pU.amountPerUnit = getNumericValue(e) || "";
        else if (key === "totalAmount") pU.totalAmount = getNumericValue(e) || "";
      }
      setState(prevState => {
        return { ...prevState, batches };
      });
    },
    [state.batches]
  );

  const handleChangeLot = useCallback(
    (batchId: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const batches = _.cloneDeep(state.batches);
      const value = e.currentTarget.value.trim();
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.lot = value;
        const existingBatch = dataContext.batch.find(
          b =>
            b.lot === value &&
            b.content.details._id.toString() === state.contentId.value &&
            b.sender.senderId === state.sender.value
        );
        if (existingBatch) {
          changeBatch.existingBatch = existingBatch;
          changeBatch.price = existingBatch.unitPrice.value.toString();
          changeBatch.packagingInputs = existingBatch.locations.flatMap(l =>
            l.packagingUnits.map(pu => {
              return getDefaultPUInput(pu, l.amountAtLocation.value);
            })
          );
          changeBatch.packagingInputs.push(getDefaultPUInput());
        } else {
          changeBatch.existingBatch = undefined;
          changeBatch.price = "";
          changeBatch.packagingInputs = changeBatch.packagingInputs.filter(pi => !pi.disabled);
        }
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches, dataContext.batch]
  );

  const handleChangeDamage = useCallback(
    (batchId: string, value: boolean) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.damage = value;
        if (!value) changeBatch.damageMessage = "";
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleChangeDamageMessage = useCallback(
    (batchId: string, value: React.ChangeEvent<HTMLTextAreaElement>) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.damageMessage = value.currentTarget.value;
        setState(prevState => {
          return { ...prevState, view: Views.ADDDETAILS, batches };
        });
      }
    },
    [state.batches]
  );

  const handleAddPackagingRow = useCallback(
    (batchId: string) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.packagingInputs.push(getDefaultPUInput());
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleRemovePackagingRow = useCallback(
    (batchId: string, pUId: string) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.packagingInputs = changeBatch.packagingInputs.filter(pui => pui._id.toString() !== pUId);
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleShowUpload = useCallback(
    (batchId: string) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.showUpload = !changeBatch.showUpload;
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleFileUpload = useCallback(
    (batchId: string, file: File, title: string, type: FileType) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        const fileType = fileUtils.getFileExtension(file!.name);
        const fileName = baseUtils.encodeString(title) + "." + fileType;
        const path = fileUtils.uploadFile(file, fileName);
        if (path) {
          const batchFile = getBatchFileEntry(path, file, type);
          changeBatch.files.push(batchFile);

          setState(prevState => {
            return { ...prevState, batches };
          });
        } else {
          toast.error(i18n.t("common:errorFileUpload"));
        }
      }
    },
    [state.batches]
  );

  const handleChangeStorageSpace = useCallback(
    (batchId: string, e: any) => {
      const batches = _.cloneDeep(state.batches);
      const changeBatch = batches.find(b => b._id.toString() === batchId);
      if (changeBatch) {
        changeBatch.storageSpace = e[0];
        setState(prevState => {
          return { ...prevState, batches };
        });
      }
    },
    [state.batches]
  );

  const handleSave = useCallback(async () => {
    setSaving(true);
    try {
      const batchInputArray = [];
      for (let i = 0; i < state.batches.length; i++) {
        const { _id, lot, bbd, files, price, existingBatch, packagingInputs, storageSpace } = state.batches[i];
        const packagingInputsArray = [];
        for (let j = 0; j < packagingInputs.length; j++) {
          const pUI = packagingInputs[j];
          if (!pUI.disabled)
            packagingInputsArray.push({
              quantity: +pUI.quantityPUs,
              packagingUnitId: pUI.packagingUnit.value,
              amountPerUnit: +pUI.amountPerUnit
            });
        }
        const storageSpaceSnap: StorageSpaceSnapshot | undefined = storageSpace?.storageSpace
          ? {
              _id: storageSpace.storageSpace._id,
              storageSpaceNo: storageSpace.storageSpace.storageSpaceNo
            }
          : undefined;
        batchInputArray.push({
          _id: _id,
          packagingInputs: packagingInputsArray,
          lot,
          bbd,
          files,
          price: +price,
          existingBatchId: existingBatch?._id,
          storageSpace: storageSpaceSnap
        });
      }
      if (
        state.contentObject &&
        state.senderObject &&
        state.warehouseSelect.object &&
        state.warehouseSelect.object.warehouse &&
        state.warehouseSelect.object.physicalWarehouse
      ) {
        const res = await dbService.callFunction<false | { id: BSON.ObjectId | null }>(
          "addToBatchOnBookIn",
          [
            state.contentObject,
            state.senderObject,
            state.sender.senderType,
            ContentType.COMMODITY,
            batchInputArray,
            state.warehouseSelect.object.warehouse,
            state.warehouseSelect.object.physicalWarehouse,
            state.specificProductType.value ?? BatchContentSpecificType.POWDER
          ],
          true
        );
        await toastUtils.databaseOperationToast(
          !!res,
          i18n.t("warehouse:createStockSuccess"),
          i18n.t("warehouse:createStockFailure"),
          () => {
            if (res && "id" in res && res.id) updateDocumentInContext(BATCH, res.id);
            onHide();
          }
        );
      }
    } finally {
      setSaving(false);
    }
  }, [state]);

  const getDefaultPUInput = (pU?: PackagingUnit, amountAtLocation?: number): PackagingInputs => {
    const firstPU = warehouseContext.configuration?.values.packagingUnitDefinitions[0];
    const emptySelect = { value: "", label: "" };
    if (pU && amountAtLocation)
      return {
        _id: new BSON.ObjectId(),
        quantityPUs: pU.quantity ? pU.quantity.toString() : "",
        packagingUnit: {
          value: pU.puSnapshot._id.toString(),
          label: resolveTranslation(pU.puSnapshot.label)
        },
        amountPerUnit: pU.amountPerPu.value.toString(),
        totalAmount: amountAtLocation.toString(),
        disabled: true
      };
    else
      return {
        _id: new BSON.ObjectId(),
        quantityPUs: "",
        packagingUnit: firstPU
          ? {
              value: firstPU._id.toString(),
              label: resolveTranslation(firstPU.label)
            }
          : emptySelect,
        amountPerUnit: "",
        totalAmount: "",
        disabled: false
      };
  };

  const getDefaultBatchInput = (): BatchInput => {
    const today = new Date();
    return {
      _id: new BSON.ObjectId(),
      packagingInputs: [getDefaultPUInput()],
      lot: "",
      bbd: new Date(today.setFullYear(today.getFullYear() + 1)),
      files: [],
      damageFiles: [],
      damage: false,
      damageMessage: "",
      price: "",
      showUpload: false
    };
  };

  return (
    <Modal show={show} onHide={onHide} centered size={state.view === Views.ADDDETAILS ? "lg" : undefined}>
      <Modal.Header closeButton onClick={onHide}>
        <Modal.Title as={"h5"}>
          <b>
            {state.view === Views.ASSIGNSTORAGESPACE
              ? i18n.t("warehouse:assignStorageSpace")
              : i18n.t("warehouse:bookInGoods")}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" px-2">
          {state.view === Views.SELECTPRODUCTANDSUPPLIER && (
            <>
              <h5 className="mb-10 font-weight-bold text-black">{i18n.t("common:article")}:</h5>
              <div className="form-group fv-plugins-icon-container" style={{ marginBottom: 5 }}>
                <Typeahead
                  id="article"
                  ref={articleRef}
                  inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                  labelKey="label"
                  onChange={handleChangeCommodity}
                  options={commoditySelections}
                  selected={state.contentId.value !== "" ? [state.contentId] : []}
                  placeholder={i18n.t("warehouse:commodityInputPlaceholder")}
                />
                {state.contentObject && (
                  <i
                    className={`fa ${
                      state.showArticleDetails ? "fa-window-close" : "fa-info-circle"
                    } float-right mt-3 mr-2`}
                    style={
                      state.showArticleDetails || state.specificProductType.value === ""
                        ? { color: "#fa0043" }
                        : { color: "#d0d4d7" }
                    }
                    onClick={() =>
                      setState(prevState => ({ ...prevState, showArticleDetails: !prevState.showArticleDetails }))
                    }
                  />
                )}
              </div>
              {state.showArticleDetails && state.contentObject && (
                <div className="card-body bg-light">
                  <div className="d-block">
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productType")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        {i18n.t("warehouse:rawMaterial")}
                      </div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productTitle")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        {resolveTranslation(state.contentObject.title)}
                      </div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">
                        {i18n.t("warehouse:productInformation")}:
                      </div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        {resolveTranslation(state.contentObject.subtitle)}
                      </div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("common:articleNumber")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        {state.contentObject.article_number || "-"}
                      </div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:organic")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        {state.contentObject.organic ? "BIO" : "-"}
                      </div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:category")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">{state.category}</div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:form")}:</div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">{state.form}</div>
                    </div>
                    <div className="form-group form-group-xs d-flex info-row">
                      <div
                        className="col-4 col-form-label p-m-0 text-black"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {i18n.t("warehouse:articleCategory")}:
                      </div>
                      <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                        <Select
                          className=" w-100 bg-white"
                          classNamePrefix=" bg-white"
                          placeholder={`${i18n.t("warehouse:commodity")} ${i18n.t("packaging:type")}`}
                          onChange={handleChangeType}
                          value={state.specificProductType}
                          options={productTypes.map(pt => {
                            return {
                              value: pt.value,
                              label: pt.label
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="fv-plugins-icon-container d-block mt-2">
                <h5 className="mb-10 font-weight-bold text-black">{i18n.t("warehouse:supplier")}:</h5>
                <Typeahead
                  id="sender"
                  ref={senderRef}
                  inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                  labelKey="label"
                  onChange={handleChangeSupplier}
                  options={senderSelectOptions}
                  selected={state.sender.value !== "" ? [state.sender] : []}
                  placeholder={i18n.t("warehouse:senderInputPlaceholder")}
                  renderMenuItemChildren={option => (
                    <React.Fragment>
                      <span>
                        {
                          //@ts-ignore
                          option.label
                        }
                      </span>
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded ml-auto"
                        style={{
                          backgroundColor: "#F3F6F9",
                          color: "#71797E"
                        }}
                      >
                        {
                          //@ts-ignore
                          option.typeLabel
                        }
                      </span>
                    </React.Fragment>
                  )}
                />
              </div>
            </>
          )}
          {state.view === Views.ADDDETAILS && (
            <>
              <h5 className="mb-10 font-weight-bold text-black">{i18n.t("warehouse:bookTo")}:</h5>
              <Select
                className="select-warehouse w-100"
                classNamePrefix="select-warehouse"
                placeholder={i18n.t("warehouse:warehouse")}
                onChange={handleChangeWarehouse}
                value={state.warehouseSelect}
                options={warehouseSelectOptions}
              />
              <div className="kt-portlet__body pt-2">
                {state.batches &&
                  state.contentObject &&
                  state.senderObject &&
                  state.batches.map(b => {
                    if (state.contentObject && state.senderObject)
                      return (
                        <BookInCard
                          key={b._id.toString()}
                          commodity={state.contentObject}
                          sender={state.senderObject}
                          batch={b}
                          numberOfBatches={state.batches.length}
                          onChangePackagingUnit={handleChangePackagingUnit}
                          onChangeInputs={handleChangeInputs}
                          onChangeLot={handleChangeLot}
                          onChangeDamage={handleChangeDamage}
                          onChangeDamageText={handleChangeDamageMessage}
                          onShowUpload={handleShowUpload}
                          onAddRow={handleAddRow}
                          onRemoveRow={handleRemoveRow}
                          onUploadFile={handleFileUpload}
                          onChangePUInputs={handleChangePackagingInputs}
                          onAddPackagingRow={handleAddPackagingRow}
                          onRemovePackagingRow={handleRemovePackagingRow}
                        />
                      );
                  })}
              </div>
            </>
          )}
          {state.view === Views.ASSIGNSTORAGESPACE &&
            state.contentObject &&
            state.senderObject &&
            state.warehouseSelect.object &&
            state.warehouseSelect.object.warehouse &&
            state.warehouseSelect.object.physicalWarehouse && (
              <AddStorageSpaceOnBookIn
                batches={state.batches}
                content={state.contentObject}
                warehouse={state.warehouseSelect.object.warehouse}
                warehouseArea={state.warehouseSelect.object.physicalWarehouse}
                onChangeStorageSpace={handleChangeStorageSpace}
              />
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={
            state.view === Views.SELECTPRODUCTANDSUPPLIER
              ? onHide
              : () =>
                  handleChangeView(
                    state.view > Views.SELECTPRODUCTANDSUPPLIER ? state.view - 1 : Views.SELECTPRODUCTANDSUPPLIER
                  )
          }
        >
          {state.view === Views.SELECTPRODUCTANDSUPPLIER ? i18n.t("common:close") : i18n.t("common:back")}
        </button>
        {state.view === Views.ADDDETAILS ? (
          <>
            {state.warehouseSelect.object?.physicalWarehouse?.type !== WarehouseTypes.REMOTE && (
              <ErrorOverlayButton
                className={"btn btn-secondary"}
                saving={saving}
                errors={errors}
                buttonText={i18n.t("warehouse:assignStorageSpace")}
                onClick={() => handleChangeView(Views.ASSIGNSTORAGESPACE)}
              />
            )}
            <ErrorOverlayButton
              buttonText={i18n.t("warehouse:bookToEntrance")}
              className="btn btn-success"
              saving={saving}
              errors={errors}
              onClick={handleSave}
            />
          </>
        ) : state.view === Views.ASSIGNSTORAGESPACE ? (
          <ErrorOverlayButton
            buttonText={i18n.t("common:save")}
            className="btn btn-success"
            saving={saving}
            errors={errors}
            onClick={handleSave}
          />
        ) : (
          <ErrorOverlayButton
            buttonText={i18n.t("common:next")}
            className="btn btn-success"
            saving={saving}
            errors={errors}
            onClick={() => handleChangeView(Views.ADDDETAILS)}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

interface BookInCardProps {
  commodity: CommoditiesDocument;
  sender: SuppliersDocument | ManufacturersDocument | CompaniesDocument;
  batch: BatchInput;
  numberOfBatches: number;
  onChangeInputs: (batchId: string, value: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLot: (batchId: string, value: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDamage: (batchId: string, value: boolean) => void;
  onChangeDamageText: (batchId: string, value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onShowUpload: (batchId: string) => void;
  onAddRow: () => void;
  onRemoveRow: (batchId: string) => void;
  onUploadFile: (batchId: string, file: File, title: string, type: FileType) => void;
  onChangePUInputs: (batchId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePackagingUnit: (batchId: string, pUId: string, e: any) => void;
  onAddPackagingRow: (batchId: string) => void;
  onRemovePackagingRow: (batchId: string, pUId: string) => void;
}

const BookInCard: React.FunctionComponent<BookInCardProps> = ({
  commodity,
  sender,
  batch,
  numberOfBatches,
  onChangePackagingUnit,
  onChangeInputs,
  onChangeLot,
  onChangeDamage,
  onChangeDamageText,
  onAddRow,
  onShowUpload,
  onRemoveRow,
  onUploadFile,
  onRemovePackagingRow,
  onAddPackagingRow,
  onChangePUInputs
}) => {
  const warehouseContext = useWarehouseContext();
  const [state, setState] = useState({ hide: false });

  const done = useMemo((): boolean => {
    return (
      batch.packagingInputs.every(
        pui =>
          pui.quantityPUs !== "0" && pui.quantityPUs !== "" && pui.amountPerUnit !== "0" && pui.amountPerUnit !== ""
      ) &&
      batch.lot !== "" &&
      batch.price !== ""
    );
  }, [batch]);

  const handleUploadFile = useCallback(
    (file: File, title: string, type: FileType) => {
      onUploadFile(batch._id.toString(), file, title, type);
    },
    [batch._id]
  );

  return (
    <>
      <div
        className="card card-custom card-fit card-border my-3 bg-light border-none"
        style={{
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
        }}
      >
        <div
          className="card-header card-header-transparent"
          style={{ background: "white!important" }}
          onClick={() => setState({ hide: false })}
        >
          <div className="row">
            <div className="col-9 card-title d-block my-3" style={{ lineHeight: 1.2 }}>
              <span className="navi-text font-weight-bold font-size-h5 text-black">
                {baseUtils.truncateString(resolveTranslation(commodity.title), 30)}&nbsp;
                <small>{baseUtils.truncateString(resolveTranslation(commodity.subtitle), 30)}</small>
              </span>
              <br />
              <span className="navi-text font-weight-medium font-size-lg text-black">
                {baseUtils.truncateString(sender.name, 50)}
              </span>
            </div>
            <div className="col-3 card-toolbar-warehouse">
              <span
                className={
                  "label label-md font-weight-bold label-rounded w-auto px-2" +
                  (done ? " label-success " : " label-danger ")
                }
              >
                {done ? i18n.t("warehouse:complete") : i18n.t("warehouse:pending")}
              </span>
              {numberOfBatches > 1 && (
                <span className="text-right pl-2">
                  <button className="btn btn-text" onClick={() => onRemoveRow(batch._id.toString())}>
                    <i className="fa fa-trash" style={{ paddingRight: 0 }} />
                  </button>
                </span>
              )}
            </div>
          </div>
          {batch.existingBatch && (
            <div className="alert alert-warning mt-2" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text"> {i18n.t("warehouse:batchExistingWarning")}</div>
            </div>
          )}
        </div>
        <div className={"card card-custom " + (state.hide && "d-none")}>
          <div className="card-body card-body-warehouse" style={{ border: "none" }}>
            {batch.packagingInputs.map(pui => {
              return (
                <PackagingRow
                  key={pui._id.toString()}
                  batchId={batch._id.toString()}
                  numberOfPUs={batch.packagingInputs.length}
                  packagingInputs={pui}
                  onChangePUInputs={onChangePUInputs}
                  onChangePackagingUnit={onChangePackagingUnit}
                  onRemovePackagingRow={onRemovePackagingRow}
                />
              );
            })}
            <div className="form-group row mb-3">
              <div className="col-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
                      {i18n.t("warehouse:lot")}
                    </div>
                  </div>
                  <input
                    className="form-control form-control-solid"
                    placeholder={i18n.t("warehouse:batchNumber")}
                    value={batch.lot}
                    onChange={e => onChangeLot(batch._id.toString(), e)}
                    name="lot"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
                      {i18n.t("warehouse:bbd")}
                    </div>
                  </div>
                  <input
                    type="date"
                    value={
                      batch.existingBatch
                        ? batch.existingBatch.expiry.toISOString().split("T")[0]
                        : batch.bbd.toISOString().split("T")[0]
                    }
                    name="mhd"
                    className={"form-control form-control-solid" + (Boolean(batch.existingBatch) ? " text-muted" : "")}
                    disabled={Boolean(batch.existingBatch)}
                    onChange={e => onChangeInputs(batch._id.toString(), e)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    value={batch.price}
                    placeholder={i18n.t("warehouse:pricePerUnit", { unit: DEFAULTWEIGHTUNIT })}
                    name="price"
                    min={0}
                    onChange={e => onChangeInputs(batch._id.toString(), e)}
                  />
                  <div className="input-group-append">
                    <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
                      EUR
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {batch.damage && (
              <div className="row mt-3">
                <div className="col-lg-12">
                  <textarea
                    className="form-control form-control-solid"
                    rows={3}
                    onChange={e => onChangeDamageText(batch._id.toString(), e)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="card-footer card-footer-warehouse" style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div className="row justify-content-between">
              <div>
                <button className="btn btn-text text-black" onClick={() => onShowUpload(batch._id.toString())}>
                  {i18n.t("warehouse:uploadFile")}
                </button>
                <Tooltip
                  tooltipText={
                    batch.files.length === 0 ? (
                      `0 ${i18n.t("common:files")}`
                    ) : (
                      <span>
                        {batch.files.map(f => {
                          return <p key={f._id.toString()}>{f.title}</p>;
                        })}
                      </span>
                    )
                  }
                >
                  <img
                    className="align-self-center"
                    src="/media/files/pdf.svg"
                    alt="pdf symbol"
                    style={batch.files.length ? { height: 20 } : { height: 20, opacity: "35%" }}
                  />
                </Tooltip>
              </div>
              <div>
                {/*TODO AC-612: Implement report damage properly and enable again*/}
                <button
                  className="btn btn-text text-black"
                  onClick={() => onChangeDamage(batch._id.toString(), !batch.damage)}
                  disabled={true}
                >
                  {i18n.t("warehouse:reportDamage")}
                </button>
                <button className="btn btn-text text-black" onClick={onAddRow}>
                  {i18n.t("warehouse:addRow")}
                </button>
                <button className="btn btn-text text-black" onClick={() => onAddPackagingRow(batch._id.toString())}>
                  {i18n.t("warehouse:addVPE")}
                </button>
              </div>
            </div>
            {batch.showUpload && warehouseContext.configuration && (
              <>
                <div className="row">
                  <UploadFileDragNDrop
                    fileTypes={warehouseContext.configuration.values.fileTypes}
                    onUploadFile={handleUploadFile}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface PackagingRowProps {
  batchId: string;
  numberOfPUs: number;
  packagingInputs: PackagingInputs;
  onChangePUInputs: (batchId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePackagingUnit: (batchId: string, pUId: string, e: any) => void;
  onRemovePackagingRow: (batchId: string, pUId: string) => void;
}

const PackagingRow: React.FunctionComponent<PackagingRowProps> = ({
  batchId,
  numberOfPUs,
  packagingInputs,
  onChangePUInputs,
  onChangePackagingUnit,
  onRemovePackagingRow
}) => {
  const warehouseContext = useWarehouseContext();

  return (
    <div className="form-group row mb-3">
      <div className="col-4">
        <div className="input-group">
          <input
            className="form-control form-control-solid"
            type="number"
            min={0}
            value={packagingInputs.totalAmount}
            placeholder={i18n.t("warehouse:totalAmount")}
            name="totalAmount"
            disabled={packagingInputs.disabled}
            onChange={e => onChangePUInputs(batchId, packagingInputs._id.toString(), e)}
          />
          <div className="input-group-append">
            <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
              {DEFAULTWEIGHTUNIT}
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group">
          <input
            className="form-control form-control-solid"
            placeholder={i18n.t("warehouse:pUQuantity")}
            value={packagingInputs.quantityPUs}
            type="number"
            min={0}
            name="quantityPus"
            disabled={packagingInputs.disabled}
            onChange={e => onChangePUInputs(batchId, packagingInputs._id.toString(), e)}
          />
          <div className="input-group-append">
            <div style={{ minWidth: "100px" }}>
              <Select
                className="select-warehouse border-radius-left-0"
                classNamePrefix="select-warehouse border-radius-left-0"
                onChange={e => onChangePackagingUnit(batchId, packagingInputs._id.toString(), e)}
                value={packagingInputs.packagingUnit}
                disabled={packagingInputs.disabled}
                options={warehouseContext.configuration?.values.packagingUnitDefinitions.map(pu => {
                  return {
                    value: pu._id.toString(),
                    label: resolveTranslation(pu.label)
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={numberOfPUs > 1 ? "col-3" : "col-4"}>
        <div className="input-group">
          <input
            className="form-control form-control-solid"
            type="number"
            min={0}
            value={packagingInputs.amountPerUnit}
            placeholder={i18n.t("warehouse:amountPerPU")}
            name="amountPerUnit"
            disabled={packagingInputs.disabled}
            onChange={e => onChangePUInputs(batchId, packagingInputs._id.toString(), e)}
          />
          <div className="input-group-append">
            <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
              {DEFAULTWEIGHTUNIT}/{packagingInputs.packagingUnit.label}
            </div>
          </div>
        </div>
      </div>
      {numberOfPUs > 1 && (
        <div className="col-1 p-0 text-center">
          <button
            className="btn btn-text"
            disabled={packagingInputs.disabled}
            onClick={() => onRemovePackagingRow(batchId, packagingInputs._id.toString())}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateStockModal;

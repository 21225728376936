import React, { useCallback } from "react";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import Tooltip from "../../../common/Tooltip";
import { resolveTranslation } from "../../../../utils/translationUtils";

interface WarehouseInformationProps {
  batch: Batch;
  location: BatchLocation;
}

const WarehouseInformation: React.FC<WarehouseInformationProps> = ({ batch, location }) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.CHANGE_WAREHOUSE,
        batchId: batch._id.toString(),
        locationId: location._id.toString()
      }
    });
  }, [batch._id, location._id]);

  return (
    <div className="kt-user-card-v2 pointer" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <Tooltip
          tooltipText={`${resolveTranslation(location.location.warehouseSnapshot.warehouseName)} - ${resolveTranslation(
            location.location.warehouseArea.warehouseName
          )}`}
        >
          <span className="kt-user-card-v2__name text-black font-weight-300">
            <span>
              {location.location.warehouseSnapshot.shortName} - {location.location.warehouseArea.shortName}
            </span>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default WarehouseInformation;

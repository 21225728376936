import React, { useCallback } from "react";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";

interface StorageSpaceInformationProps {
  batch: Batch;
  location: BatchLocation;
}

const StorageSpaceInformation: React.FC<StorageSpaceInformationProps> = ({ batch, location }) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.ASSIGN_STORAGE_SPACE,
        batchId: batch._id.toString(),
        locationId: location._id.toString()
      }
    });
  }, [batch._id, location._id]);

  return (
    <div className="kt-user-card-v2 pointer" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <span
          className={
            "kt-user-card-v2__name text-black " +
            (!!location.location.storageSpace?.storageSpaceNo ? "kt-font-bold" : "font-weight-300")
          }
        >
          <span>{location.location.storageSpace?.storageSpaceNo || "-"}</span>
        </span>
      </div>
    </div>
  );
};

export default StorageSpaceInformation;

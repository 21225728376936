import React, { useContext } from "react";
import { BSON } from "realm-web";
import { ActivesubstancesDocument } from "../model/activesubstances.types";
import { AllergensDocument } from "../model/allergens.types";
import { AdditiveDocument } from "../model/additives.types";
import { CapsulesDocument } from "../model/capsules.types";
import { ColorsDocument } from "../model/colors.types";
import { CommoditiesDocument, RawbidsCommodity } from "../model/commodities.types";
import { CommoditycategoriesDocument } from "../model/commoditycategories.types";
import { CommoditypropertiesDocument } from "../model/commodityproperties.types";
import { CompaniesDocument } from "../model/companies.types";
import { CompositionsDocument } from "../model/compositions.types";
import { FAQsDocument } from "../model/faqs.types";
import { GeneralDocument } from "../model/general.types";
import { ManufacturersDocument } from "../model/manufacturers.types";
import { NewsDocument } from "../model/news.types";
import { OrdersDocument } from "../model/orders.types";
import { PackagingsDocument } from "../model/packagings.types";
import { RequestsDocument } from "../model/requests.types";
import { SampleRecipesDocument } from "../model/sampleRecipes.types";
import { SuppliersDocument } from "../model/suppliers.types";
import { TabletsDocument } from "../model/tablets.types";
import { UserdataDocument } from "../model/userdata.types";
import { SolventsDocument } from "../model/solvents.types";
import { ProductionWeekDocument } from "../model/productionPlan.types";
import { ExternalManufacturerOrdersDocument } from "../model/externalManufacturerOrders.types";
import { PackagingOrderDocument } from "../model/packagingOrders.types";
import { PackagingStockDocument } from "../model/packagingStock.types";
import { PohodaHistoryDocument } from "../model/pohodaHistory.types";
import { Batch } from "../model/warehouse/batch.types";
import { Reservation } from "../model/warehouse/reservation.types";
import { BaseConfiguration } from "../model/configuration/configuration.types";

export const DataContext = React.createContext({
  additives: [] as Array<AdditiveDocument>,
  activesubstances: [] as Array<ActivesubstancesDocument>,
  allergens: [] as Array<AllergensDocument>,
  batch: [] as Array<Batch>,
  capsules: [] as Array<CapsulesDocument>,
  colors: [] as Array<ColorsDocument>,
  commodities: [] as Array<CommoditiesDocument>,
  commoditycategories: [] as Array<CommoditycategoriesDocument>,
  commodityproperties: [] as Array<CommoditypropertiesDocument>,
  companies: [] as Array<CompaniesDocument>,
  compositions: [] as Array<CompositionsDocument>,
  configuration: [] as Array<BaseConfiguration>,
  externalManufacturerOrders: [] as Array<ExternalManufacturerOrdersDocument>,
  faqs: [] as Array<FAQsDocument>,
  general: [] as Array<GeneralDocument>,
  manufacturers: [] as Array<ManufacturersDocument>,
  news: [] as Array<NewsDocument>,
  orderFeedback: [] as Array<any>,
  orders: [] as Array<OrdersDocument>,
  packagings: [] as Array<PackagingsDocument>,
  requests: [] as Array<RequestsDocument>,
  reservation: [] as Array<Reservation>,
  sampleRecipes: [] as Array<SampleRecipesDocument>,
  solvents: [] as Array<SolventsDocument>,
  suppliers: [] as Array<SuppliersDocument>,
  tablets: [] as Array<TabletsDocument>,
  userdata: [] as Array<UserdataDocument>,
  currencies: [] as Array<{ code: string; name: string; symbol: string }>,
  productionPlan: [] as Array<ProductionWeekDocument>,
  packagingOrders: [] as Array<PackagingOrderDocument>,
  packagingStock: [] as Array<PackagingStockDocument>,
  pohodaHistory: [] as Array<PohodaHistoryDocument>,
  rawbidsCommodities: { commodities: [], date: new Date(0) } as { commodities: Array<RawbidsCommodity>; date: Date },
  savedState: {} as any,
  addDocuments: (collection: string, documents: Array<any>) => true,
  saveComponentState: (key: string, state: any) => undefined,
  setRawbidsCommodities: (commodities: Array<RawbidsCommodity>) => undefined,
  updateDocumentInContext: (collection: string, _id: BSON.ObjectId | string) => new Promise<boolean>(() => true)
});

export interface DataContextType {
  additives: Array<AdditiveDocument>;
  activesubstances: Array<ActivesubstancesDocument>;
  allergens: Array<AllergensDocument>;
  batch: Array<Batch>;
  capsules: Array<CapsulesDocument>;
  colors: Array<ColorsDocument>;
  commodities: Array<CommoditiesDocument>;
  commoditycategories: Array<CommoditycategoriesDocument>;
  commodityproperties: Array<CommoditypropertiesDocument>;
  companies: Array<CompaniesDocument>;
  compositions: Array<CompositionsDocument>;
  configuration: Array<BaseConfiguration>;
  externalManufacturerOrders: Array<ExternalManufacturerOrdersDocument>;
  faqs: Array<FAQsDocument>;
  general: Array<GeneralDocument>;
  manufacturers: Array<ManufacturersDocument>;
  news: Array<NewsDocument>;
  orderFeedback: Array<any>;
  orders: Array<OrdersDocument>;
  packagings: Array<PackagingsDocument>;
  requests: Array<RequestsDocument>;
  reservation: Array<Reservation>;
  sampleRecipes: Array<SampleRecipesDocument>;
  suppliers: Array<SuppliersDocument>;
  tablets: Array<TabletsDocument>;
  userdata: Array<UserdataDocument>;
  productionPlan: Array<ProductionWeekDocument>;
  packagingOrders: Array<PackagingOrderDocument>;
  packagingStock: Array<PackagingStockDocument>;
  pohodaHistory: Array<PohodaHistoryDocument>;
}

export function useDataContext() {
  return useContext(DataContext);
}

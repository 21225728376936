/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { LayoutContextProvider } from "../../_metronic";
import HomePage from "../pages/home/HomePage";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import Login from "../../components/authentication/Login";
import Logout from "../../components/authentication/Logout";
import authenticationService from "../../services/authenticationService";
import PasswordResetRequest from "../../components/authentication/PasswordResetRequest";
import PasswordReset from "../../components/authentication/PasswordReset";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  let { menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );
  const isAuthorized = authenticationService.isLoggedIn();
  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized &&
        history.location.pathname !== "/login" &&
        !history.location.pathname.startsWith("/passwordReset")
          ? (window.location.href = "/login")
          : null}
        <Route path="/logout" component={Logout} />
        <Route path="/login" component={Login} />
        <Route path="/passwordResetRequest" component={PasswordResetRequest} />
        <Route path="/passwordReset" component={PasswordReset} />
        <Layout>
          <HomePage userLastLocation={userLastLocation} history={history} />
        </Layout>
      </Switch>
    </LayoutContextProvider>
  );
});

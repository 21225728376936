import React, { useCallback, useMemo } from "react";
import i18n from "../../../../translations/i18n";
import baseUtils, { formatNumValue, getNumericValue } from "../../../../utils/baseUtils";
import Tooltip from "../../../common/Tooltip";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { BatchLocationReservationInformation } from "../../../../model/warehouse/customTypes.types";
import { useDataContext } from "../../../../context/dataContext";
import {
  BatchStatus,
  getBatchLocationAvailableAmount,
  getBatchLocationStatusIndicationText
} from "../../../../utils/batchUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT, getBatchPackagingUnitDescription } from "../../../../utils/warehouseUtils";
import { Input } from "../../../common/Input";

interface ReservationRowProps {
  edit: boolean;
  batch: Batch;
  batchReservations: Array<BatchLocationReservationInformation>;
  onChangeReservationAmount: (
    batchId: string,
    locationId: string,
    commodityId: string,
    amount: number,
    amountValid: boolean
  ) => void;
}

const ReservationRow: React.FC<ReservationRowProps> = ({
  edit,
  batch,
  batchReservations,
  onChangeReservationAmount
}) => {
  const uniqueWarehouses = useMemo(
    () => Array.from(new Set(batch.locations.map(l => l.location.warehouseSnapshot.shortName))),
    [batch]
  );

  const filteredLocations = useMemo(() => {
    if (edit) {
      return batch.locations;
    } else {
      const relevantLocations = new Set(batchReservations.map(r => r.locationId));
      return batch.locations.filter(l => relevantLocations.has(l._id.toString()));
    }
  }, [edit, batch, batchReservations]);

  const reservationRowHeader = edit
    ? [
        { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
        { title: i18n.t("warehouse:reserve"), size: 20 },
        { title: i18n.t("warehouse:available"), size: 15 },
        { title: i18n.t("warehouse:warehouseShort"), size: 15 },
        { title: i18n.t("warehouse:storageSpaceShort"), size: 15 },
        { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 25 }
      ]
    : [
        { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
        { title: i18n.t("warehouse:available"), size: 30 },
        { title: i18n.t("warehouse:warehouseShort"), size: 20 },
        { title: i18n.t("warehouse:storageSpaceShort"), size: 20 },
        { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 30 }
      ];

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding">
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{batch.sender.name}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{batch.lot}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email " + (new Date() < batch.expiry ? "text-black" : "text-danger")}>
            {baseUtils.formatDate(batch.expiry)}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black kt-font-bold">{formatNumValue(batch.totalAmount)}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell text-right">
          <Tooltip
            tooltipText={
              uniqueWarehouses.length === 1
                ? resolveTranslation(batch.locations[0].location.warehouseSnapshot.warehouseName)
                : i18n.t("warehouse:variousWarehouses")
            }
          >
            <div className={"label label-light label-inline kt-font-bold text-black my-auto"} style={{ width: 60 }}>
              {uniqueWarehouses.length === 1 ? uniqueWarehouses[0] : "VAR"}
            </div>
          </Tooltip>
        </td>
      </tr>
      <tr className="kt-datatable__row d-table-row">
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
            <table className="kt-datatable__table d-table p-5 bg-light">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  {reservationRowHeader.map((def, idx) => (
                    <th key={idx} className="kt-datatable__cell d-table-cell" style={{ width: `${def.size}%` }}>
                      <span>{def.title}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                {filteredLocations.map(fL => (
                  <ReservationRowDetailRow
                    key={fL._id.toString()}
                    edit={edit}
                    batch={batch}
                    location={fL}
                    locationReservations={batchReservations.filter(r => r.locationId === fL._id.toString())}
                    onChangeReservationAmount={onChangeReservationAmount}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

interface ReservationRowDetailRowProps {
  edit: boolean;
  batch: Batch;
  location: BatchLocation;
  locationReservations: Array<BatchLocationReservationInformation>;
  onChangeReservationAmount: (
    batchId: string,
    locationId: string,
    commodityId: string,
    amount: number,
    amountValid: boolean
  ) => void;
}

const ReservationRowDetailRow: React.FC<ReservationRowDetailRowProps> = ({
  edit,
  batch,
  location,
  locationReservations,
  onChangeReservationAmount
}) => {
  const dataContext = useDataContext();
  const { reservation } = dataContext;

  const selectedReservedAmount = useMemo(() => {
    const locationReservation =
      locationReservations.length > 0
        ? locationReservations.find(r => r.batchId === batch._id.toString() && r.locationId === location._id.toString())
        : undefined;
    return locationReservation // make sure value is in DEFAULTWEIGHTUNIT for display
      ? Number(
          calculationUtils.convertAmount(
            locationReservation.amount.value.toString(),
            locationReservation.amount.unit,
            DEFAULTWEIGHTUNIT
          )
        )
      : 0;
  }, [locationReservations]);

  const availableAmount = useMemo(
    () => getBatchLocationAvailableAmount(batch._id.toString(), location, reservation),
    [batch, location, reservation]
  );

  const remainingAmount = useMemo(
    () => ({
      value: availableAmount.value - selectedReservedAmount,
      unit: DEFAULTWEIGHTUNIT
    }),
    [selectedReservedAmount, availableAmount]
  );

  const [, color] = useMemo(() => {
    if (edit) {
      return getBatchLocationStatusIndicationText(batch._id.toString(), location, reservation);
    } else {
      const status = remainingAmount.value === 0 ? BatchStatus.RESERVED : BatchStatus.PARTIALLY_RESERVED;
      const color = status === BatchStatus.RESERVED ? "text-danger" : "text-warning";
      return [status, color];
    }
  }, [batch, location, reservation, remainingAmount]);

  const handleChangeReservationAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputNumericValue = getNumericValue(e);
      if (!inputNumericValue) return;
      const amountValid =
        Number(inputNumericValue) <=
        Number(
          calculationUtils.convertAmount(availableAmount.value.toString(), availableAmount.unit, DEFAULTWEIGHTUNIT)
        );
      onChangeReservationAmount(
        batch._id.toString(),
        location._id.toString(),
        batch.content.details._id.toString(),
        Number(inputNumericValue),
        amountValid
      );
    },
    [onChangeReservationAmount, batch, location]
  );

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md " + color} />
          </div>
        </div>
      </td>
      {edit && (
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details flex-fill">
              <div className="input-group">
                <Input
                  className="form-control form-control-noArrows py-0"
                  type="number"
                  min={0}
                  max={availableAmount.value}
                  name="reservationAmount"
                  value={selectedReservedAmount}
                  onChange={handleChangeReservationAmount}
                  style={{ height: "25px" }}
                />
                <div className="input-group-append">
                  <span className="input-group-text input-group-solid py-0">{DEFAULTWEIGHTUNIT}</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {formatNumValue(availableAmount)}
            {!edit ? (
              <>
                {" "}
                → <span className="text-warning">{formatNumValue(remainingAmount)}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details text-black">
            <Tooltip
              tooltipText={`${resolveTranslation(
                location.location.warehouseSnapshot.warehouseName
              )} - ${resolveTranslation(location.location.warehouseArea.warehouseName)}`}
            >
              <span>
                {location.location.warehouseSnapshot.shortName} - {location.location.warehouseArea.shortName}
              </span>
            </Tooltip>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div
            className={
              "kt-user-card-v2__details text-black " +
              (!!location.location.storageSpace?.storageSpaceNo && "kt-font-bold")
            }
          >
            {location.location.storageSpace?.storageSpaceNo || "-"}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2__email mt-0 text-black">
          {getBatchPackagingUnitDescription(location.packagingUnits)}
        </div>
      </td>
    </tr>
  );
};

export default ReservationRow;

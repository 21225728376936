import _ from "lodash";
import { SelectedBatchEntry, SelectedCommodityEntry, WarehouseActionType } from "../context/warehouseContext";
import { SelectedBatchEntryType, SelectedCommodityEntryType } from "./warehouseUtils";
import { BatchStatus, getBatchLocationStatus } from "./batchUtils";
import { Reservation } from "../model/warehouse/reservation.types";
import { Batch } from "../model/warehouse/batch.types";

export enum WarehouseActionNumber {
  CREATE_STOCK = 0,
  BOOK_DELIVERY = 1,
  ASSIGN_STORAGE_SPACE = 2,
  CHANGE_WAREHOUSE = 3,
  SPLIT_GROUP = 4,
  CREATE_RESERVATION = 5,
  CANCEL_RESERVATION = 6,
  BOOK_OUT = 7,
  SEND_TO_PRODUCTION = 8,
  PRINT_DELIVERY_NOTES = 9,
  PRINT_PICK_LIST = 10,
  REPORT_DAMAGE = 11,
  CHANGE_BATCH_DATA = 12,
  CHANGE_BDD = 13,
  RETROFITTING_STORAGE = 14,
  EXPORT_CSV = 15
}

/**
 * Checks if all selected entries are from the same batch
 * @param selection the selected entries to check
 * @returns true if only one batch is selected, all selected locations belong to that batch and/or all selected locations have the same batch as parent; false if not
 */
export function selectionFromOneBatch(selection: Array<SelectedBatchEntry | SelectedCommodityEntry>): boolean {
  // Check if nothing or more than one batch is selected
  if (selection.length === 0) return false;
  if (isSelectedBatchEntries(selection)) {
    const batchSelections = selection.filter(s => s.type === SelectedBatchEntryType.BATCH);
    if (batchSelections.length > 1) {
      return false;
    }
    // Check if selected locations are all from selected batch
    const locationSelections = selection.filter(s => s.type === SelectedBatchEntryType.LOCATION);
    if (batchSelections.length === 1 && locationSelections.length > 0) {
      const batchParentId = batchSelections[0].parentId;
      return locationSelections.every(locationEntry => locationEntry.parentId === batchParentId);
    }
    // Check if one batch and no other locations are selected
    // or if no batch is selected and all selected locations are from the same parent
    return (
      (batchSelections.length === 1 && locationSelections.length === 0) ||
      (batchSelections.length === 0 &&
        locationSelections.length > 0 &&
        new Set(locationSelections.map(entry => entry.parentId)).size === 1)
    );
  } else if (isSelectedCommodityEntries(selection)) {
    const uniqueBatches = Array.from(
      new Set(selection.filter(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION).map(entry => entry.batchId))
    );
    return uniqueBatches.length === 1 && !!uniqueBatches[0];
  }
  return false;
}

/**
 * Checks if the selection still can be reserved (or parts of it)
 * @param selection the selected entries to check
 * @param reservations the reservations to check if any of the selection is contained in them
 * @param batches the batch collection to resolve the location
 * @returns false if the whole selection is already reserved, true if not (at least one available/partially reserved entry)
 */
export function selectionIsReservable(
  selection: Array<SelectedBatchEntry | SelectedCommodityEntry>,
  reservations: Array<Reservation>,
  batches: Array<Batch>
): boolean {
  if (selection.length === 0) return false;
  if (isSelectedBatchEntries(selection)) {
    const locationSelections = selection.filter(s => s.type === SelectedBatchEntryType.LOCATION);
    const everythingReserved = locationSelections.every(lS => {
      const batch = batches.find(b => b._id.toString() === lS.parentId);
      if (batch && lS.childId) {
        const batchLocation = batch.locations.find(bL => bL._id.toString() === lS.childId);
        if (batchLocation) {
          return getBatchLocationStatus(lS.parentId, batchLocation, reservations) === BatchStatus.RESERVED;
        }
      }
      return true;
    });
    return !everythingReserved;
  } else if (isSelectedCommodityEntries(selection)) {
    const locationSelections = selection.filter(s => s.type === SelectedCommodityEntryType.BATCH_LOCATION);
    const everythingReserved = locationSelections.every(lS => {
      const batch = batches.find(b => b._id.toString() === lS.batchId);
      if (batch && lS.locationId) {
        const batchLocation = batch.locations.find(bL => bL._id.toString() === lS.locationId);
        if (batchLocation) {
          return getBatchLocationStatus(batch._id.toString(), batchLocation, reservations) === BatchStatus.RESERVED;
        }
      }
      return true;
    });
    return !everythingReserved;
  }
  return false;
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedBatchEntry>} the updated selected entries
 */
export function updateSelectedBatchEntries(
  selectedEntries: Array<SelectedBatchEntry | SelectedCommodityEntry>,
  action: { type: WarehouseActionType.SELECT_BATCH_ENTRY; payload: SelectedBatchEntry }
): Array<SelectedBatchEntry> {
  const entries = isSelectedBatchEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isBatchWithChildren =
    action.payload.type === SelectedBatchEntryType.BATCH &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    entry =>
      entry.type === action.payload.type &&
      entry.parentId === action.payload.parentId &&
      entry.childId === action.payload.childId
  );
  // batch was checked and is now unchecked (deselect batch and locations beneath)
  if (checked && isBatchWithChildren) {
    return entries.filter(e => e.parentId !== action.payload.parentId);
  } else if (checked && action.payload.type === SelectedBatchEntryType.LOCATION) {
    // batch location was checked and is now unchecked (deselect location and parent batch)
    return entries.filter(
      e =>
        !(
          e.parentId === action.payload.parentId &&
          e.childId === action.payload.childId &&
          e.type === SelectedBatchEntryType.LOCATION
        ) && !(e.parentId === action.payload.parentId && e.type === SelectedBatchEntryType.BATCH)
    );
  } else if (checked) {
    return entries.filter(
      e =>
        e.parentId !== action.payload.parentId || e.childId !== action.payload.childId || e.type !== action.payload.type
    );
  }
  // batch was unchecked and is now checked (select batch and locations beneath)
  if (isBatchWithChildren) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedBatchEntryType.LOCATION,
        parentId: action.payload.parentId,
        numberOfChildren: action.payload.currentChildren!.length,
        childId: action.payload.currentChildren![i]
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedBatchEntryType.LOCATION) {
    // batch location was unchecked and is now checked (select location, if all sibling locations are checked, select parent batch)
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.parentId === action.payload.parentId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedBatchEntryType.BATCH,
        parentId: action.payload.parentId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload]; // entry was unchecked and is now checked
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedCommodityEntry>} the updated selected entries
 */
export function updateSelectedCommodityEntries(
  selectedEntries: Array<SelectedBatchEntry | SelectedCommodityEntry>,
  action: { type: WarehouseActionType.SELECT_COMMODITY_ENTRY; payload: SelectedCommodityEntry }
): Array<SelectedCommodityEntry> {
  const entries = isSelectedCommodityEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isCommodityWithChildren =
    action.payload.type === SelectedCommodityEntryType.COMMODITY &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    entry =>
      entry.type === action.payload.type &&
      entry.commodityId === action.payload.commodityId &&
      entry.batchId === action.payload.batchId &&
      entry.locationId === action.payload.locationId
  );

  if (checked && isCommodityWithChildren) return entries.filter(e => e.commodityId !== action.payload.commodityId);
  else if (checked && action.payload.type === SelectedCommodityEntryType.BATCH_LOCATION)
    return entries.filter(
      e =>
        !(
          e.commodityId === action.payload.commodityId &&
          e.batchId === action.payload.batchId &&
          e.locationId === action.payload.locationId &&
          e.type === SelectedCommodityEntryType.BATCH_LOCATION
        ) && !(e.commodityId === action.payload.commodityId && e.type === SelectedCommodityEntryType.COMMODITY)
    );
  else if (checked)
    return entries.filter(
      e =>
        e.commodityId !== action.payload.commodityId ||
        e.batchId !== action.payload.batchId ||
        e.locationId !== action.payload.locationId ||
        e.type !== action.payload.type
    );

  if (isCommodityWithChildren) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedCommodityEntryType.BATCH_LOCATION,
        commodityId: action.payload.commodityId,
        batchId: action.payload.currentChildren![i][0],
        locationId: action.payload.currentChildren![i][1],
        numberOfChildren: action.payload.currentChildren!.length
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedCommodityEntryType.BATCH_LOCATION) {
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.commodityId === action.payload.commodityId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedCommodityEntryType.COMMODITY,
        commodityId: action.payload.commodityId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload];
}

/**
 * Check if given entries are of type SelectedCommodityEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedCommodityEntry, else false
 */
export function isSelectedCommodityEntries(
  entries: Array<SelectedBatchEntry | SelectedCommodityEntry>
): entries is Array<SelectedCommodityEntry> {
  return entries.length === 0 || "commodityId" in entries[0];
}

/**
 * Check if given entries are of type SelectedBatchEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedBatchEntry, else false
 */
export function isSelectedBatchEntries(
  entries: Array<SelectedBatchEntry | SelectedCommodityEntry>
): entries is Array<SelectedBatchEntry> {
  return entries.length === 0 || "parentId" in entries[0];
}

/**
 * Get the batch id of a selected entry
 * @param entries list of selected commodity or batch entry
 * @returns {string} id of a selected batch
 */
export function getBatchIdFromSelectedEntries(
  entries: Array<SelectedCommodityEntry | SelectedBatchEntry>
): string | undefined {
  if (isSelectedBatchEntries(entries)) return entries[0].parentId;
  const entry = entries.find(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION) as SelectedCommodityEntry;
  return entry.batchId;
}

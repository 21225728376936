import React, { useCallback, useMemo } from "react";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { useDataContext } from "../../../../context/dataContext";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { ReservationState } from "../../../../model/warehouse/reservation.types";
import { compareBatchLocations } from "../../../../utils/batchUtils";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";

interface ReservationInformationProps {
  batch: Batch;
  location: BatchLocation;
}

const ReservationInformation: React.FC<ReservationInformationProps> = ({ batch, location }) => {
  const dataContext = useDataContext();
  const dispatch = useWarehouseDispatch();
  const { reservation } = dataContext;

  const uniqueReservations = useMemo(() => {
    const reservationsForLocation = reservation.filter(
      r =>
        r.state === ReservationState.OPEN &&
        r.batches.some(
          b => b.batch._id.toString() === batch._id.toString() && compareBatchLocations(b.location, location.location)
        )
    );
    return Array.from(new Set(reservationsForLocation.map(r => r.order.identifier)));
  }, [batch._id, location.location, reservation]);

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber:
          uniqueReservations.length === 0
            ? WarehouseActionNumber.CREATE_RESERVATION
            : WarehouseActionNumber.CANCEL_RESERVATION,
        batchId: batch._id.toString(),
        locationId: location._id.toString()
      }
    });
  }, [uniqueReservations.length, batch._id, location._id]);

  return (
    <div className="kt-user-card-v2 pointer" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <span className="kt-user-card-v2__name text-black font-weight-300">
          <span>{uniqueReservations.length === 0 ? "-" : uniqueReservations.map(r => `AT-${r}`).join(", ")}</span>
        </span>
      </div>
    </div>
  );
};

export default ReservationInformation;

import _ from "lodash";
import { BSON } from "realm-web";
import Fuse from "fuse.js";
import React from "react";
import { CapsulesDocument } from "../model/capsules.types";
import { TabletsDocument } from "../model/tablets.types";
import http from "../services/httpService";
import config from "../config/config.json";
import { GeneralDocument } from "../model/general.types";
import { DataContext } from "../context/dataContext";
import { ExternalManufacturerContext } from "../context/externalManufacturerContext";
import { NumValue } from "../model/common.types";

/**
 * Gets a document from a given collection
 * @param collection the collection to search
 * @param id the objectId to search for
 * @returns found document or null
 */
function getDocFromCollection(collection: Array<any>, id: BSON.ObjectId | string) {
  const doc = collection.find(d => d._id.toString() === id.toString());
  if (doc) return { ...doc };
  return null;
}

/**
 * Get a value from the general collection
 * @param general the general collection
 * @param key the key to get the value for
 * @returns {string | number | null} the string or number value or null
 */
function getValueFromGeneral(general: Array<GeneralDocument>, key: string): string | number | null {
  const value = general.find(g => g.data === key);
  if (value) return value.value;
  return null;
}

/**
 * Build a string to display capsule information
 * @param capsule the capsule object
 * @returns {string} a formatted string with capsule data
 */
function buildCapsuleString(capsule: CapsulesDocument): string {
  return `${capsule.capsule_size} - ${capsule.capsule_material.en} (${capsule.capsule_color.en})`;
}

/**
 * Build a string to display table information
 * @param tablet the table object
 * @returns {string} a formatted string with tablet data
 */
function buildTabletString(tablet: TabletsDocument): string {
  return `${tablet.shape[0].toUpperCase() + tablet.shape.slice(1)} - Volume: ${tablet.volume}ml/cm\xB3`;
}

/**
 * Truncate a string
 * @param str the string to truncate
 * @param maxCharacters the maximum amount of characters of the result string
 * @returns {string} the truncated string with a max length of maxCharacters + 3 (...)
 */
function truncateString(str: string, maxCharacters: number): string {
  if (str && str.length <= maxCharacters) return str;
  else if (str) return str.slice(0, maxCharacters) + "...";
  return "";
}

/**
 * Encode a string and replace umlaute and special character
 * @param str the string to encode
 * @returns {string} the encoded string
 */
export function encodeString(str: string): string {
  if (!str || str.length === 0) {
    return "";
  } else {
    str = str
      .replaceAll("+", "")
      .replaceAll(" ", "_")
      .replaceAll("'", "")
      .replaceAll("/", "")
      .replaceAll("#", "")
      .replaceAll(".", "")
      .replaceAll("%", "")
      .replaceAll("‐", "-")
      .replaceAll("‑", "-")
      .replaceAll("‒", "-")
      .replaceAll("–", "-")
      .replaceAll("—", "-")
      .replaceAll("―", "-")
      .replaceAll("á", "a")
      .replaceAll("Ä", "Ae")
      .replaceAll("Ä", "Ae")
      .replaceAll("ä", "ae")
      .replaceAll("ä", "ae")
      .replaceAll("Ö", "Oe")
      .replaceAll("Ö", "Oe")
      .replaceAll("ö", "oe")
      .replaceAll("ö", "oe")
      .replaceAll("Ü", "Ue")
      .replaceAll("Ü", "Ue")
      .replaceAll("ü", "ue")
      .replaceAll("ü", "ue")
      .replaceAll("ß", "ss")
      .replaceAll(":", "")
      .replaceAll('"', "")
      .replaceAll("?", "")
      .replaceAll("=", "")
      .replaceAll("&", "")
      .replaceAll("!", "")
      .replaceAll(",", "")
      .replaceAll(">", "")
      .replaceAll("<", "")
      .replaceAll("®", "")
      .replace(/\s/g, "")
      .replace(/#/g, "Nr-")
      .replace(/%/g, "");
    return encodeURIComponent(str);
  }
}

/**
 * Handle file Upload
 * @param files Selected file(s)
 * @param prefix Prefix for the file name
 * @param callback Callback that is used to update the state etc
 * @param baseUrl Indicates whether the baseUrl (mediaHub path) should be part of the callback url or not
 */
async function handleFileUpload(files: File[], prefix: string, callback: (image: string) => void, baseUrl: boolean) {
  // Only one file is allowed
  if (files.length === 1) {
    const file = files.pop()!;
    // file name current timestamp and the given local file name
    const fileName = encodeURIComponent(`${prefix}_${Date.now()}_${file.name}`);
    const header = {
      headers: { "Content-Type": "application/octet-stream" }
    };
    try {
      const { data: name } = await http.post(config.uploadEndpoint.concat(fileName), file, header);
      const img_url = baseUrl
        ? config.mediahubFileBase.concat(encodeURIComponent(name))
        : "/files/" + encodeURIComponent(name);
      callback(img_url);
    } catch (ex) {
      console.error(ex.message);
    }
  }
}

export const CURRENCIES: Array<{ code: string; name: string; symbol: string }> = [
  { code: "USD", name: "US Dollar", symbol: "US$" },
  { code: "EUR", name: "Euro", symbol: "€" },
  { code: "JPY", name: "Japanese Yen", symbol: "JP¥" },
  { code: "GBP", name: "British Pound Sterling", symbol: "£" },
  { code: "CNY", name: "Chinese Yuan Renminbi", symbol: "CN¥" },
  { code: "AUD", name: "Australian Dollar", symbol: "A$" },
  { code: "CAD", name: "Canadian Dollar", symbol: "C$" },
  { code: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { code: "HKD", name: "Hong Kong Dollar", symbol: "HK$" }
];

/**
 * Format a value to €
 * @param value A numeric value
 * @returns { string } Currency string
 */
function formatEuro(value: number): string {
  return formatCurrency(value, "EUR");
}

/**
 * Format a value to €. Using special formatting for CSV-files to avoid column breaks
 * @param value A numeric value
 * @returns { string } Currency string
 */
function formatEuroForCSV(value: number): string {
  return '"' + formatCurrency(value, "EUR") + '"';
}

/**
 * Format a string value to an escaped csv string. Using special formatting for CSV-files to avoid column breaks
 * @param value A string value
 * @returns { string } result
 */
function formatStringForCSV(value: string): string {
  return '"' + value + '"';
}

/**
 * Format a value to the specified currency
 * @param value A numeric value
 * @param currency A currency string
 * @returns { string } Currency string
 */
function formatCurrency(value: number, currency: string): string {
  return value.toLocaleString("de-DE", {
    style: "currency",
    currency
  });
}

/**
 * Format a date to default preferred format.
 * @param date Date that should be formatted
 * @param withTime flag if time should be added
 * @param options optional, options adjusting the output format
 * @param locale optional, overwrite locale
 * @returns { string } Formatted date
 */
function formatDate(date: Date, withTime?: boolean, options?: Intl.DateTimeFormatOptions, locale?: string): string {
  if (withTime) {
    return date.toLocaleDateString(locale ?? "de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      ...options
    });
  } else {
    return date.toLocaleDateString(locale ?? "de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      ...options
    });
  }
}

/**
 * Get saved state from context
 * @param context the data context or external manufacturer context
 * @param key the key, i.e. the component name to get the state for
 * @returns { object | null } the saved components state or null if the state is too old or not existing
 */
export function getComponentState(
  context: React.ContextType<typeof DataContext | typeof ExternalManufacturerContext>,
  key: string
) {
  const { savedState } = context;
  const now = new Date().getTime();
  const state = key in savedState ? savedState[key] : null;
  // Check if state is older than 2 minutes
  if (state && now - state.date.getTime() < 2 * 60 * 1000) return state.state;
  return null;
}

/**
 * Measure the size of a text for a given font
 * @param canvas a canvas element
 * @param font the font including the size, e.g. '28px Helvetica'
 * @param text the text to measure
 * @returns {number} the size of the text in pixels
 */
function measureTextSize(canvas: HTMLElement | null, font: string, text: string): number {
  if (!canvas) return 0;
  // @ts-ignore
  let ctx = canvas.getContext("2d");
  ctx.font = font;
  return ctx.measureText(text).width;
}

/**
 * Recursively crops the text until it matches the given target size
 * @param canvas a canvas element
 * @param text the text to crop
 * @param font the font including the size, e.g. '28px Helvetica'
 * @param targetSize the maximum width the text should have
 * @returns {string} the cropped text
 */
function cropTextSize(canvas: HTMLElement | null, text: string, font: string, targetSize: number): string {
  const size = measureTextSize(canvas, font, text);
  let croppedText = text;
  if (size > targetSize) {
    croppedText = croppedText.slice(0, -4) + "...";
    return cropTextSize(canvas, croppedText, font, targetSize);
  }
  return croppedText;
}

/**
 * Execute Fuse search
 * @param documents list of documents to filter/search
 * @param query the search query
 * @param keys keys of a document to compare
 * @param additionalOptions additional Fuse Options
 * @returns {Array<T>} list of filtered documents
 */
function doFuseSearch<T = any>(
  documents: Array<T>,
  query: string,
  keys: Array<string>,
  additionalOptions?: Fuse.IFuseOptions<T>
): Array<T> {
  let options: Fuse.IFuseOptions<T> = {
    includeScore: true,
    includeMatches: true,
    ignoreLocation: true,
    threshold: 0.2,
    keys: keys
  };
  if (additionalOptions) options = _.merge(options, additionalOptions);
  const fuse = new Fuse(documents.slice(), options);
  const result = fuse.search(query);
  return result.map(res => res.item);
}

/**
 * Round to 2 decimals
 * @param value numeric value to round
 * @returns { number } value rounded to 2 decimals
 */
export function round(value: number): number {
  return roundToDigits(value, 2);
}

/**
 * Round a number to the give amount of decimal digits
 * @param value the value to round
 * @param digits amount of desired decimal digits
 * @returns {number} rounded number
 */
export const roundToDigits = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.round(value * factor) / factor;
};

/**
 * Get and properly convert number values from a change event
 * @param e Input change event
 * @param allowNegative Allows negative values in the input field
 * @returns {undefined | string} converted number without leading zeros etc.
 */
export const getNumericValue = (
  e: React.ChangeEvent<HTMLInputElement>,
  allowNegative?: boolean
): undefined | string => {
  let value = e.target.value.replaceAll(/^0+/g, "0");
  if (!Number(value) && Number(value) !== 0) return;
  if (!allowNegative && Number(value) < 0) value = Math.abs(Number(value)).toString();
  if (!value.includes(".")) value = Number(value).toString();
  return value;
};

/**
 * Get a formatted string for a num value
 * @param value a num value object with numeric value and unit
 * @param round optional, parameter indicating if and to how many digits it should be rounded
 * @returns {string} formatted string with value and unit
 */
export function formatNumValue(value: NumValue, round?: number): string {
  if (round) return `${roundToDigits(value.value, round)}${value.unit}`;
  return `${value.value}${value.unit}`;
}

/**
 * Capitalizes the first letter of all words of a string
 * @param str the string for which the first letters should be capitalized
 * @returns {string} the string with the first letters capitalized
 */
export function capitalizeAllWords(str: string): string {
  const words = str.split(/\s+/);
  const capitalizedWords = words.map(word => {
    return _.upperFirst(word);
  });
  return capitalizedWords.join(" ");
}

// eslint-disable-next-line
export default {
  doFuseSearch,
  getDocFromCollection,
  getValueFromGeneral,
  handleFileUpload,
  buildCapsuleString,
  buildTabletString,
  truncateString,
  encodeString,
  formatCurrency,
  formatDate,
  formatEuro,
  formatEuroForCSV,
  formatStringForCSV,
  measureTextSize,
  cropTextSize,
  capitalizeAllWords
};

export enum LengthUnit {
  mm = "mm",
  cm = "cm",
  m = "m"
}

export enum WeightUnit {
  ug = "\u00b5g",
  mg = "mg",
  g = "g",
  kg = "kg"
}

export interface LanguageObject {
  de: string;
}

export interface NumValue {
  value: number;
  unit: string;
}

export interface BasicSelectOption {
  value: string;
  label: string;
}

export interface SelectOption<T> {
  value: string;
  label: string;
  data: T;
}

export enum ModalMode {
  DELETE = "delete",
  EDIT = "edit",
  CREATE = "create"
}

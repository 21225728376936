import Select from "react-select";
import React, { useMemo } from "react";
import i18n from "../../../translations/i18n";
import { PackagingUnit } from "../../../model/warehouse/batch.types";
import { hasDuplicateLocations } from "../../../utils/warehouseStorageSpaceUtils";
import { getFormattedPackagingUnitAmount } from "../../../utils/warehouseUtils";
import { resolveTranslation } from "../../../utils/translationUtils";
import { LocationType, StorageSpaceAssignment } from "../../../model/warehouse/common.types";
import { SelectOption } from "../../../model/common.types";

interface PackagingUnitEntryProps {
  pU: PackagingUnit;
  storageSpaceAssignment?: Array<StorageSpaceAssignment>;
  selectOptions: Array<SelectOption<LocationType>>;
  additionalWarnings?: Array<string>;
  forWarehouse?: boolean;
  onAddRow: (packagingUnitId: string) => void;
  onDeleteRow: (packagingUnitId: string, index: number) => void;
  onChangeAmount: (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (packagingUnitId: string, index: number, value?: SelectOption<LocationType>) => void;
}

const PackagingUnitEntry: React.FC<PackagingUnitEntryProps> = ({
  pU,
  storageSpaceAssignment,
  selectOptions,
  additionalWarnings,
  forWarehouse,
  onAddRow,
  onDeleteRow,
  onChangeAmount,
  onChangeLocation
}) => {
  const warnings = useMemo(() => {
    const warnings = [];
    const selectedAmount = storageSpaceAssignment
      ? storageSpaceAssignment.reduce((acc, currentAssignment) => {
          return acc + currentAssignment.quantity;
        }, 0)
      : undefined;
    const sameDestination = storageSpaceAssignment ? hasDuplicateLocations(storageSpaceAssignment) : false;
    const diff = pU.quantity !== null && selectedAmount !== undefined ? pU.quantity - selectedAmount : undefined;
    if (diff && diff > 0) {
      warnings.push(`${diff} ${i18n.t("warehouse:packagingUnitAbbreviation")} ${i18n.t("common:tooFew")}`);
    } else if (diff && diff < 0) {
      warnings.push(`${Math.abs(diff)} ${i18n.t("warehouse:packagingUnitAbbreviation")} ${i18n.t("common:tooMany")}`);
    }
    if (sameDestination) warnings.push(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
    return warnings;
  }, [storageSpaceAssignment, pU]);

  return (
    <>
      <hr />
      <div className="row my-2">
        <div className="col-12 text-black">{getFormattedPackagingUnitAmount(pU, true, true, true)}</div>
      </div>
      {storageSpaceAssignment ? (
        <>
          {storageSpaceAssignment.map((stA, index) => (
            <PackingUnitEntryInputs
              key={index}
              pU={pU}
              storageSpaceAssignment={stA}
              selectOptions={selectOptions}
              index={index}
              onDeleteRow={onDeleteRow}
              onChangeAmount={onChangeAmount}
              onChangeLocation={onChangeLocation}
            />
          ))}
          <div className="row mt-2">
            <div className="col-11 text-warning">{warnings.join(", ")}</div>
            {additionalWarnings && <div className="col-11 text-warning">{additionalWarnings.join(", ")}</div>}
            <div className="col-1 text-right pr-0">
              <button
                className={
                  "btn btn-success px-2 py-1 " + (storageSpaceAssignment.length === pU.quantity ? "disabled" : "")
                }
                onClick={storageSpaceAssignment.length === pU.quantity ? undefined : () => onAddRow(pU._id.toString())}
              >
                <i className="fa fa-plus p-0" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <div>
          {forWarehouse
            ? i18n.t("warehouse:noWarehouseToAssignError")
            : i18n.t("warehouse:storageSpaceAssignmentError")}
        </div>
      )}
    </>
  );
};

interface PackingUnitEntryInputsProps {
  pU: PackagingUnit;
  storageSpaceAssignment: StorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
  index: number;
  onDeleteRow: (packagingUnitId: string, index: number) => void;
  onChangeAmount: (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (packagingUnitId: string, index: number, value?: SelectOption<LocationType>) => void;
}

const PackingUnitEntryInputs: React.FC<PackingUnitEntryInputsProps> = ({
  pU,
  storageSpaceAssignment,
  selectOptions,
  index,
  onDeleteRow,
  onChangeAmount,
  onChangeLocation
}) => {
  const storageSpace = useMemo(() => {
    return selectOptions.find(sto => sto.value === storageSpaceAssignment.location);
  }, [selectOptions, storageSpaceAssignment]);

  return (
    <div className="row align-items-center mt-2">
      <div className="col-4">
        <div className="input-group">
          <input
            className="form-control form-control-solid"
            type="number"
            placeholder={i18n.t("common:quantity")}
            min={0}
            max={pU.quantity ?? undefined}
            step={1}
            name="pUAmount"
            value={storageSpaceAssignment.quantity}
            onChange={e => onChangeAmount(pU._id.toString(), index, e)}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{resolveTranslation(pU.puSnapshot.label)}</span>
          </div>
        </div>
      </div>
      <div className="col-7">
        <Select
          className="select-warehouse"
          isClearable={true}
          value={storageSpace}
          onChange={(value: any) => onChangeLocation(pU._id.toString(), index, value)}
          options={selectOptions}
        />
      </div>
      <div className="col-1">
        <button
          className={"btn btn-danger px-2 py-1 " + (index === 0 ? "disabled" : "")}
          onClick={index === 0 ? undefined : () => onDeleteRow(pU._id.toString(), index)}
        >
          <i className="fa fa-trash p-0" />
        </button>
      </div>
    </div>
  );
};

export default PackagingUnitEntry;

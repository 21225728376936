import i18n from "i18next";
import { LanguageObject } from "../model/common.types";

/**
 * Function to create the new translation string corresponding to the language
 * @param key the key of the datasheet locals file
 * @param content the content to be translated
 * @param translationFile the file for searching and replacing translations
 * @returns {string} the content string with the corresponding translation
 */
function findAndReplaceTranslation(key: string, content: string, translationFile: string) {
  // receive the language before language change
  const keyValue = translationFile + ":" + key;

  // replace standard translation
  return key !== "" ? i18n.t(keyValue, { lng: i18n.language }) : content;
}

export const language = () =>
  (i18n.language.slice(0, 2) || window.localStorage.i18nextLng.slice(0, 2)) as keyof LanguageObject;

export const fullLanguage = () => i18n.language || window.localStorage.i18nextLng;

/**
 * Resolve the translation of a database object.
 * @param translations Object containing the different translations (like DE, EN etc.)
 * @returns {string} translation
 */
export function resolveTranslation(translations: LanguageObject): string {
  return translations[language()] ? translations[language()] : translations.de;
}

export default findAndReplaceTranslation;

import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import i18n from "../../../translations/i18n";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";
import { resolveTranslation } from "../../../utils/translationUtils";

interface UploadFileProps {
  fileTypes: Array<FileType>;
  onUploadFile: (file: File, title: string, type: FileType) => void;
}

interface UploadFileState {
  file?: File;
  type: { value: string; label: string; object: FileType };
  name: string;
}

const getFileTypeSelections = (ft: FileType) => {
  return {
    value: ft._id.toString(),
    label: resolveTranslation(ft.type) + " - " + ft.shortName,
    object: ft
  };
};

const UploadFile: React.FunctionComponent<UploadFileProps> = ({ fileTypes, onUploadFile }) => {
  const uploadRef: React.RefObject<HTMLInputElement> = useMemo(() => React.createRef(), []);
  const [state, setState] = useState<UploadFileState>({
    file: undefined,
    type: getFileTypeSelections(fileTypes[0]),
    name: ""
  });

  const handleSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setState(prevState => {
        return { ...prevState, file, name: file.name.split(".")[0] };
      });
    }
  }, []);

  const handleChangeType = useCallback(
    (e: any) => {
      const newType = e.value;
      const fileType = fileTypes.find(ft => ft._id.toString() === newType);
      if (fileType)
        setState(prevState => {
          return {
            ...prevState,
            type: getFileTypeSelections(fileType)
          };
        });
    },
    [fileTypes]
  );

  const handleChangeFileName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    if (name) {
      setState(prevState => {
        return { ...prevState, name };
      });
    }
  }, []);

  const handleUploadFile = useCallback(() => {
    if (state.file) {
      onUploadFile(state.file, state.name, state.type.object);
      setState({
        file: undefined,
        type: getFileTypeSelections(fileTypes[0]),
        name: ""
      });
    }
  }, [state]);

  return (
    <>
      <div className="my-2">
        <label className="mb-2">{i18n.t("common:file")}</label>
        <input
          type="file"
          ref={uploadRef}
          accept="*"
          className="form-control form-control-solid custom-form-control mb-2"
          style={{ padding: "0.375rem 0.75rem" }}
          name="file"
          onChange={handleSelectFile}
        />
      </div>
      <div className="my-2">
        <label className="mb-2">{i18n.t("common:name")}</label>
        <input
          className="form-control form-control-solid custom-form-control mb-2"
          value={state.name}
          onChange={handleChangeFileName}
        />
      </div>
      <div className="my-2">
        <label className="mb-2">{i18n.t("common:filetype")}</label>
        <Select
          className="select-warehouse w-100"
          classNamePrefix="select-warehouse w-100"
          onChange={handleChangeType}
          value={state.type}
          placeholder={i18n.t("common:filetype")}
          options={fileTypes.map(ft => {
            return getFileTypeSelections(ft);
          })}
        />
      </div>
      <button
        className={"btn btn-secondary text-muted" + (!state.file && state.name !== "" ? " disabled" : "")}
        onClick={handleUploadFile}
        disabled={!state.file && state.name !== ""}
      >
        {i18n.t("common:upload")}
      </button>
    </>
  );
};

export default UploadFile;

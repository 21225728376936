import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import {
  CustomOrder,
  ExtendedBatch,
  ExtendedPackagingBatch,
  PackagingStockUpdate,
  ShippingGroupType,
  StockUpdate
} from "../CustomTypes";
import ShippingGroup from "./common/ShippingGroup";
import { DataContext } from "../../../context/dataContext";
import dbService, { COMMODITIES, ORDERS, PACKAGINGS } from "../../../services/dbService";
import userService from "../../../services/userService";
import {
  Fulfillment,
  FulfillmentPriceInfo,
  OrderFile,
  OrderFileTypes,
  UsedBatch,
  UsedPackagingBatch
} from "../../../model/orders.types";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import OrderHelper, { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SOFTGEL, T_TABLET } from "../OrderHelper";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERFULFILLMENTSTARTED } from "../../../services/notificationService";
import DateInput from "../../common/DateInput";
import StockUpdateItem from "./common/StockUpdateItem";
import orderUtils from "../../../utils/orderUtils";
import baseUtils, { round } from "../../../utils/baseUtils";
import { MARGIN_BUFFER } from "../../../utils/calculationUtils";
import AllocatedInfoTooltip from "./common/AllocatedInfoTooltip";
import RemainingInfoTooltip from "./common/RemainingInfoTooltip";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import { ManufacturerFeature } from "../../../model/manufacturers.types";
import fileUtils from "../../../utils/fileUtils";
import slackService from "../../../services/slackService";
import { AlternativeProviders } from "../../../utils/suppliersUtils";
import packagingUtils from "../../../utils/packagingUtils";

interface FinishProductionModalProps extends RouteComponentProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface FinishProductionModalState {
  show: boolean;
  step: number; // Step 1 general data, Step 2 commodity usage, Step 3 packaging usage, Step 4 summary
  lot: string;
  exp: Date;
  fulfillmentDate: Date;
  shippingGroups: Array<ShippingGroupType>;
  note: string;
  commodities: Array<StockUpdate>;
  packaging: Array<PackagingStockUpdate>;
  loading: boolean;
  totalUnits?: string;
  bulkConfirmation?: boolean;
  productionReport?: File;
}

class FinishProductionModal extends PureComponent<FinishProductionModalProps, FinishProductionModalState> {
  _requireProductionReport = false;
  uploadRef: React.RefObject<HTMLInputElement>;
  constructor(props: FinishProductionModalProps) {
    super(props);
    const { order } = props;
    this._requireProductionReport = manufacturerUtils.checkManufacturerFeature(
      order.settings.manufacturer,
      ManufacturerFeature.PRODUCTION_REPORT_CONFIRMATION
    );
    this.uploadRef = React.createRef();
    const query = new URLSearchParams(props.location.search);
    const isBulk = OrderHelper.isBulk(order, true);
    this.state = {
      show: !!query.get("productionFinished"),
      step: 1,
      lot: order.fulfillment ? order.fulfillment.lot : "",
      exp: this.getStandardExpiry(order),
      fulfillmentDate: new Date(),
      shippingGroups: [],
      note: "",
      commodities: [],
      packaging: [],
      loading: false,
      totalUnits: isBulk ? ([T_POWDER, T_LIQUID].includes(order.settings.type) ? "1" : "") : undefined,
      bulkConfirmation: isBulk ? false : undefined
    };
  }

  componentDidMount() {
    const { context, order } = this.props;
    this.setState({
      commodities: orderUtils.generateCommoditiesStockList(order, context.commodities),
      packaging: orderUtils.generatePackagingStockList(order, context.packagings, context.packagingStock)
    });
  }

  componentDidUpdate(prevProps: Readonly<FinishProductionModalProps>, prevState: Readonly<FinishProductionModalState>) {
    const { context, order } = this.props;
    if (this.state.loading) return;
    if (!_.isEqual(order.settings.manufacturer, prevProps.order.settings.manufacturer))
      this._requireProductionReport = manufacturerUtils.checkManufacturerFeature(
        order.settings.manufacturer,
        ManufacturerFeature.PRODUCTION_REPORT_CONFIRMATION
      );
    if (this.state.show && prevState.show !== this.state.show) {
      this.setState({
        lot: order.fulfillment ? order.fulfillment.lot : "",
        exp: this.getStandardExpiry(order),
        fulfillmentDate: new Date(),
        shippingGroups: [
          {
            id: new BSON.ObjectId(),
            number: 1,
            boxes: "0",
            items: "0",
            weight: "0"
          }
        ],
        note: "",
        commodities: orderUtils.generateCommoditiesStockList(order, context.commodities),
        packaging: orderUtils.generatePackagingStockList(order, context.packagings, context.packagingStock)
      });
    } else if (
      this.state.show &&
      (!_.isEqual(prevProps.order.calculations, this.props.order.calculations) ||
        prevProps.context.commodities !== this.props.context.commodities ||
        prevProps.context.packagingStock !== this.props.context.packagingStock)
    ) {
      const newCommodityStock = orderUtils.generateCommoditiesStockList(order, context.commodities);
      const newPackagingStock = orderUtils.generatePackagingStockList(
        order,
        context.packagings,
        context.packagingStock
      );
      this.setState({
        commodities: orderUtils.mergeStockLists(this.state.commodities, newCommodityStock) as Array<StockUpdate>,
        packaging: orderUtils.mergeStockLists(this.state.packaging, newPackagingStock) as Array<PackagingStockUpdate>
      });
    }
  }

  /**
   * Get the standard expiry date which is two years from now
   * @param order an order document
   * @returns { Date } Standard expiry
   */
  getStandardExpiry = (order: CustomOrder): Date => {
    const expiry = new Date();
    // Default expiry date is 2 years into the future
    expiry.setMonth(expiry.getMonth() + (order.fulfillment?.shelfLife || 24));
    return expiry;
  };

  handleShow = () =>
    this.setState({
      show: true,
      step: 1,
      bulkConfirmation: OrderHelper.isBulk(this.props.order, true) ? false : undefined,
      productionReport: undefined
    });
  handleClose = () => {
    if (!this.state.loading) this.setState({ show: false });
  };

  handleNextStep = () => {
    if (this.state.bulkConfirmation === false) return;
    const { step } = this.state;
    if (step < 4) this.setState({ step: step + 1 });
  };
  handlePrevStep = () => {
    const { step } = this.state;
    if (step > 1) this.setState({ step: step - 1 });
  };

  handleFinishProduction = async () => {
    if (this.state.bulkConfirmation === false) return;
    this.setState({ loading: true });
    const { order, context } = this.props;
    const { shippingGroups, note, lot, exp, fulfillmentDate, commodities, packaging, totalUnits, productionReport } =
      this.state;
    const timelineEntry = {
      type: T_FULFILLMENT,
      fulfillmentInformation: shippingGroups,
      date: fulfillmentDate,
      person: userService.getUserId()
    };
    const fulfillment: Fulfillment = {
      lot: lot,
      exp: exp!,
      shippingNote: note,
      shippingGroups: shippingGroups.map(sg => {
        return {
          number: sg.number,
          id: sg.id.toString(),
          boxes: parseInt(sg.boxes),
          items: +sg.items,
          weight: +sg.weight
        };
      }),
      productionReportApproval: {
        required: this._requireProductionReport
      }
    };
    if (order.fulfillment?.shelfLife) fulfillment.shelfLife = order.fulfillment.shelfLife;
    const finalUnits = totalUnits ? +totalUnits : fulfillment.shippingGroups.reduce((a, b) => a + b.boxes * b.items, 0);
    let contract;
    if (order.contractInformation) {
      contract = { ...order.contractInformation };
    }
    const stockUpdate = commodities.map(c => {
      return {
        id: c.id,
        stock: c.stock.map(s => {
          return { id: s.id, used: +s.used };
        })
      };
    });
    let packagingStockUpdate: Array<{ id: string; used: number }> = [];
    packaging.forEach(p => p.stock.forEach(pS => packagingStockUpdate.push({ id: pS.id, used: +pS.used })));

    const usedBatches = this.getUsedBatches();
    const usedPackagingBatches = this.getUsedPackagingBatches();

    // Add extended price info
    fulfillment.priceInfo = this.getUpdatedPriceInfo(usedBatches, usedPackagingBatches, finalUnits);
    fulfillment.totalUnits = finalUnits;

    let orderProductionReport: OrderFile | null = null;
    if (productionReport) {
      const fileName = orderUtils.getOrderFileName(OrderFileTypes.PRODUCTION_REPORT, productionReport, order);
      orderProductionReport = orderUtils.getOrderFile(
        productionReport,
        OrderFileTypes.PRODUCTION_REPORT,
        undefined,
        fileName
      );
    }
    if (this._requireProductionReport && !orderProductionReport) return;

    try {
      const result = await dbService.callFunction("finishOrderProduction", [
        order,
        timelineEntry,
        fulfillment,
        usedBatches,
        finalUnits,
        stockUpdate,
        contract,
        usedPackagingBatches,
        packagingStockUpdate,
        orderProductionReport
      ]);
      if (result) {
        toast.success("Order and stock successfully updated");
        notificationService.notify(R_ORDERFULFILLMENTSTARTED, order._id);
        if (this._requireProductionReport && orderProductionReport) {
          const url =
            process.env.NODE_ENV === "production"
              ? "https://www.admincentral.private-label-factory.com/"
              : "http://localhost:3000/";
          const notificationMessage = `:information_source: Production of order <${url}order/${order._id.toString()}|*${
            "AT-" + order.identifier
          }*> is finished. Please review and approve the <${orderProductionReport.path}|*production report*>.`;
          // send message to sales employee. For dev cases #novacode-reviews will be used (done by backend function).
          slackService.sendMessage(order.createdFrom._id, notificationMessage);
        }
        this.setState({ loading: false, show: false }, () => {
          context.updateDocumentInContext(ORDERS, order._id);
          commodities.forEach(c => context.updateDocumentInContext(COMMODITIES, c.commodity._id));
          packaging.forEach(p => context.updateDocumentInContext(PACKAGINGS, p.packaging._id));
        });
      } else toast.error("Order and stock could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * Get all used batches
   * @returns Array<UsedBatch> List of used batches
   */
  getUsedBatches = () => {
    const { commodities } = this.state;
    let usedBatches: Array<UsedBatch> = [];
    for (let i = 0; i < commodities.length; i++) {
      const commodity = commodities[i];
      for (let j = 0; j < commodity.stock.length; j++) {
        const stock = commodity.stock[j];
        const batch = stock.stock;
        if (+stock.used > 0) {
          usedBatches.push({
            id: batch._id,
            commodityId: commodity.commodity._id,
            supplier: batch.supplier,
            used: +stock.used,
            lot: batch.lot,
            location: batch.location,
            price: batch.price,
            files: batch.files
          });
        }
      }
    }
    return usedBatches;
  };

  /**
   * Get all used batches for packaging
   * @returns Array<UsedPackagingBatch> List of used batches
   */
  getUsedPackagingBatches = () => {
    const { packaging } = this.state;
    let usedBatches: Array<UsedPackagingBatch> = [];
    for (let i = 0; i < packaging.length; i++) {
      const p = packaging[i];
      for (let j = 0; j < p.stock.length; j++) {
        const stock = p.stock[j];
        const batch = stock.stock;
        if (+stock.used > 0) {
          usedBatches.push({
            id: batch._id,
            packagingId: p.packaging._id,
            supplier: batch.supplier,
            used: +stock.used,
            lot: batch.lot,
            location: batch.location,
            price: batch.price,
            files: batch.files
          });
        }
      }
    }
    return usedBatches;
  };

  /**
   * Get update price info calculated with actual commodity usage and actual produced units
   * @param usedBatches list of used batches with used amount and kg price
   * @param usedPackagingBatches list of used packaging batches with used amount and price
   * @param finalUnits the final produced units
   * @returns PriceInfo the final price information for the order
   */
  getUpdatedPriceInfo = (
    usedBatches: Array<UsedBatch>,
    usedPackagingBatches: Array<UsedPackagingBatch>,
    finalUnits: number
  ): FulfillmentPriceInfo => {
    const { order } = this.props;
    const calculation = order.calculations[0];

    const unitPrice = calculation.info.unitprice;
    const commodityUnitPrice = orderCalculationUtils.calculateCommoditiesUnitPrice(order);
    const packagingUnitPrice = orderCalculationUtils.calculatePackagingUnitPrice(order);
    // unit price without commodities
    const restUnitPrice = calculation.info.unitpricenaked - commodityUnitPrice - packagingUnitPrice;

    const actualCommodityUnitPrice = usedBatches.reduce((a, b) => a + +b.price * +b.used, 0) / finalUnits;
    const actualPackagingUnitPrice = usedPackagingBatches.reduce((a, b) => a + +b.price * +b.used, 0) / finalUnits;
    // actual unit price is unit price without commodities + the actual commodity unit price computed via batches
    const actualUnitPriceNaked =
      restUnitPrice +
      actualCommodityUnitPrice +
      actualPackagingUnitPrice +
      (calculation.info.marginBuffer !== undefined && calculation.info.marginBuffer !== null ? 0 : MARGIN_BUFFER);
    // actual margin could also be negative
    const actualUnitMargin = unitPrice - actualUnitPriceNaked;
    const actualTotalMargin = actualUnitMargin * finalUnits;
    const totalPrice = unitPrice * finalUnits;
    const percentMargin = (unitPrice / actualUnitPriceNaked - 1) * 100;
    return {
      unitPrice: unitPrice,
      unitPriceNaked: actualUnitPriceNaked,
      unitMargin: actualUnitMargin,
      totalPrice: totalPrice,
      totalMargin: actualTotalMargin,
      percentMargin: percentMargin,
      marginBuffer:
        calculation.info.marginBuffer !== undefined && calculation.info.marginBuffer !== null
          ? calculation.info.marginBuffer
          : MARGIN_BUFFER
    };
  };

  handleTotalUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    this.setState({ totalUnits: val });
  };

  handleShippingGroupValueChange =
    (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { order } = this.props;
      const shippingGroups = _.cloneDeep(this.state.shippingGroups);
      const shippingGroup = shippingGroups.find(p => p.id.toString() === id.toString())!;
      const key = e.target.name as keyof ShippingGroupType;
      let val = e.target.value;
      if ("boxes" === key) shippingGroup[key] = Number(parseInt(val) || "0").toString();
      else if (["items", "weight"].includes(key)) {
        val = val.replaceAll(/^0+/g, "0");
        if (!val.includes(".")) val = Number(val).toString();
        // @ts-ignore
        shippingGroup[key] = val;
      }
      // @ts-ignore
      else shippingGroup[key] = val;
      const newState: any = { shippingGroups };
      // Also update total units
      if (
        ["boxes", "items"].includes(key) &&
        this.state.totalUnits !== undefined &&
        [T_CAPSULE, T_TABLET, T_SOFTGEL, T_CUSTOM].includes(order.settings.type)
      ) {
        newState.totalUnits = (shippingGroups.reduce((a, b) => a + +b.boxes * +b.items, 0) / 1000).toString();
      }
      this.setState(newState);
    };

  handleShippingGroupRemove = (id: BSON.ObjectId) => {
    const shippingGroups = _.cloneDeep(this.state.shippingGroups);
    if (shippingGroups.length < 2) return;
    let sgs = shippingGroups.filter(sg => sg.id.toString() !== id.toString());
    for (let i = 0; i < sgs.length; i++) sgs[i].number = i + 1;
    this.setState({ shippingGroups: sgs });
  };

  handleAddShippingGroup = (shippingGroup: ShippingGroupType) => {
    if (this.state.bulkConfirmation === false) return;
    const shippingGroups = _.cloneDeep(this.state.shippingGroups);
    shippingGroups.push(shippingGroup);
    this.setState({ shippingGroups });
  };

  handleUpdateStock = (commodityID: string, batchID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const commodities = _.cloneDeep(this.state.commodities);
    const commodity = commodities.find(c => c.id.toString() === commodityID)!;
    const stock = commodity.stock.find(s => s.id.toString() === batchID)!;
    stock.used = Number(parseFloat(e.target.value) || "0").toString();
    this.setState({ commodities });
  };

  handleUpdatePackagingStock = (packagingId: string, batchID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const packagings = _.cloneDeep(this.state.packaging);
    const packaging = packagings.find(c => c.id.toString() === packagingId)!;
    const stock = packaging.stock.find(s => s.id.toString() === batchID)!;
    stock.used = Number(parseFloat(e.target.value) || "0").toString();
    this.setState({ packaging: packagings });
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) toast.error("No file selected");
    this.setState({ productionReport: e.target.files![0] });
  };

  isStep1Complete = () => {
    const { lot, exp, shippingGroups, productionReport } = this.state;
    return (
      !!lot &&
      !!exp &&
      (!this._requireProductionReport || productionReport) &&
      shippingGroups.length > 0 &&
      shippingGroups.reduce((sum, sg) => sum + +sg.items * +sg.boxes, 0) > 0
    );
  };

  render() {
    const { order, context } = this.props;
    const {
      show,
      step,
      note,
      lot,
      exp,
      fulfillmentDate,
      shippingGroups,
      commodities,
      packaging,
      loading,
      totalUnits,
      bulkConfirmation,
      productionReport
    } = this.state;
    const disabled =
      (step === 2 && commodities.some(c => c.stock.some(b => +b.used !== 0 && +b.used > +b.stock.amount))) ||
      (step === 3 && packaging.some(p => p.stock.some(b => +b.used !== 0 && +b.used > +b.stock.amount)));
    const canFinish = accessUtils.canPerformAction(ACTIONS.ORDERPRODUCTIONFINISH);
    return (
      <>
        <button
          className={"btn btn-sm btn-upper btn-success" + (canFinish ? "" : " disabled")}
          onClick={canFinish ? this.handleShow : undefined}
          disabled={!canFinish}
        >
          Production Finished
        </button>
        <Modal
          show={show}
          onHide={this.handleClose}
          size={step > 1 ? "xl" : "lg"}
          centered
          name={"finishProductionModal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {step === 1
                ? "Prepare Fulfillment"
                : step === 2
                ? "Commodity Usage"
                : step === 3
                ? "Packaging Usage"
                : "Fulfillment Summary"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={"overflow-auto"} style={{ maxHeight: "80vh" }}>
            {step === 1 && (
              <div className="form-group form-group-last">
                {totalUnits !== undefined && (
                  <div className="alert alert-warning" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text">
                      This is a <b>bulk</b> order. Take care when entering the shipping groups and check that the
                      produced units roughly match the calculated units. The total units can be entered{" "}
                      <b>separately</b> from the shipping groups for bulk orders.
                      <br />
                      {[T_POWDER, T_LIQUID].includes(order.settings.type) ? (
                        <span>
                          For <b>powder and liquid</b> if, e.g., only 9 of 10kg were produced enter the total units as a{" "}
                          <b>fraction</b>, e.g., 0.9 for 1 originally planned unit
                        </span>
                      ) : (
                        <span>
                          For this order there is a <b>factor of {order.settings.perUnit}</b> that has to be respected.
                          E.g. 12500 capsules result in {round(12500 / order.settings.perUnit)} Units
                        </span>
                      )}
                      {bulkConfirmation === false && (
                        <div className="text-center">
                          <button
                            className="btn btn-primary p-2 mt-2"
                            onClick={() => this.setState({ bulkConfirmation: true })}
                          >
                            Understood
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group row" style={{ marginBottom: "1rem" }}>
                  <label className="col-3 col-form-label kt-font-bold kt-font-dark">Fulfillment date</label>
                  <div className="col-9">
                    <div className="input-group">
                      <DateInput
                        value={fulfillmentDate}
                        onBlur={e => this.setState({ fulfillmentDate: new Date(e.target.value) })}
                        name="fulfillment"
                        max={new Date()}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="la la-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="form-group row" style={{ marginBottom: "1rem" }}>
                  <label htmlFor="example-text-input" className="col-3 col-form-label kt-font-bold kt-font-dark">
                    LOT
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      disabled={bulkConfirmation === false}
                      type="text"
                      placeholder="e.g. 1-123"
                      value={lot}
                      onChange={e => this.setState({ lot: e.target.value })}
                    />
                  </div>
                </div>
                {order.fulfillment?.shelfLife && (
                  <div className="form-group row" style={{ marginBottom: "1rem" }}>
                    <label htmlFor="example-text-input" className="col-3 col-form-label kt-font-bold kt-font-dark">
                      Shelf Life
                    </label>
                    <div className="col-9">
                      <div className="input-group">
                        <input
                          type="number"
                          disabled={true}
                          className="form-control"
                          value={order.fulfillment?.shelfLife}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">months</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group row" style={{ marginBottom: "1rem" }}>
                  <label className="col-3 col-form-label kt-font-bold kt-font-dark">EXP.</label>
                  <div className="col-9">
                    <div className="input-group">
                      <DateInput
                        value={exp}
                        disabled={bulkConfirmation === false}
                        onBlur={e => this.setState({ exp: new Date(e.target.value) })}
                        name="exp"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="la la-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {shippingGroups.map(sg => (
                  <ShippingGroup
                    key={sg.id.toString()}
                    shippingGroup={sg}
                    shippingGroups={shippingGroups}
                    onAddShippingGroup={() => {}}
                    onRemoveShippingGroup={() => this.handleShippingGroupRemove(sg.id)}
                    onChangeShippingGroup={this.handleShippingGroupValueChange(sg.id)}
                  />
                ))}
                <ShippingGroup
                  shippingGroups={shippingGroups}
                  onAddShippingGroup={this.handleAddShippingGroup}
                  onRemoveShippingGroup={() => {}}
                  onChangeShippingGroup={() => {}}
                />
                {totalUnits !== undefined && (
                  <div className="form-group row" style={{ marginBottom: "1rem" }}>
                    <label className="col-3 col-form-label kt-font-bold kt-font-dark">Total Units</label>
                    <div className="col-9 mt-1">
                      <input
                        className="form-control"
                        disabled={bulkConfirmation === false}
                        type="number"
                        value={totalUnits}
                        onChange={this.handleTotalUnits}
                      />
                      {totalUnits &&
                        (+totalUnits / order.calculations[0].units >= 2 ||
                          +totalUnits / order.calculations[0].units <= 0.5) && (
                          <div className="form-text text-danger kt-font-bold" style={{ paddingLeft: "1rem" }}>
                            {totalUnits} massively differs from calculated units of {order.calculations[0].units}
                          </div>
                        )}
                    </div>
                  </div>
                )}
                <div className="form-group row" style={{ marginBottom: "1rem" }}>
                  <label className="col-3 col-form-label kt-font-bold kt-font-dark">Note</label>
                  <div className="col-9 mt-1">
                    <textarea
                      className="form-control"
                      disabled={bulkConfirmation === false}
                      rows={3}
                      value={note}
                      onChange={e => this.setState({ note: e.target.value })}
                    />
                  </div>
                </div>
                {this._requireProductionReport && (
                  <>
                    <div className="form-group row" style={{ marginBottom: "1rem" }}>
                      <label className="col-3 col-form-label kt-font-bold kt-font-dark">Production Report</label>
                      <div className="col-9">
                        <div className="row">
                          <div className="col-8 col-form-label">
                            <span className="kt-font-bold kt-font-dark">
                              {fileUtils.getFileDescription(productionReport)}
                            </span>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn btn-success float-right"
                              onClick={() => this.uploadRef.current?.click()}
                            >
                              Select File
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={this.uploadRef}
                      accept="*"
                      style={{ display: "none" }}
                      onChange={this.handleSelectFile}
                    />
                  </>
                )}
              </div>
            )}
            {step === 2 && (
              <div className="form-group form-group-last">
                <div className="row">
                  <div className="col-4">
                    <span className="form-text kt-font-dark">Used Batch</span>
                  </div>
                  <div className="col-3">
                    <span className="form-text kt-font-dark">Used Amount</span>
                  </div>
                  <div className="col-3">
                    <span className="form-text kt-font-dark">
                      Remaining
                      <RemainingInfoTooltip />
                    </span>
                  </div>
                  <div className="col-2">
                    <span className="form-text kt-font-dark">
                      Other Allocated
                      <AllocatedInfoTooltip />
                    </span>
                  </div>
                </div>
                {commodities.map(commodity => (
                  <StockUpdateItem
                    key={commodity.id.toString()}
                    stockUpdate={commodity}
                    onUpdateStock={this.handleUpdateStock}
                    context={context}
                    order={order}
                    showEditedBatches={true}
                  />
                ))}
              </div>
            )}
            {step === 3 && (
              <div className="form-group form-group-last">
                <div className="row">
                  <div className="col-4">
                    <span className="form-text kt-font-dark">Used Batch</span>
                  </div>
                  <div className="col-3">
                    <span className="form-text kt-font-dark">Used Amount</span>
                  </div>
                  <div className="col-3">
                    <span className="form-text kt-font-dark">
                      Remaining
                      <RemainingInfoTooltip />
                    </span>
                  </div>
                  <div className="col-2">
                    <span className="form-text kt-font-dark">
                      Other Allocated
                      <AllocatedInfoTooltip />
                    </span>
                  </div>
                </div>
                {packaging.map(packaging => (
                  <StockUpdateItem
                    key={packaging.id.toString()}
                    stockUpdate={packaging}
                    onUpdateStock={this.handleUpdatePackagingStock}
                    context={context}
                    order={order}
                    showEditedBatches={true}
                  />
                ))}
              </div>
            )}
            {step === 4 && (
              <FulfillmentSummary
                order={order}
                context={context}
                packaging={packaging}
                shippingGroups={shippingGroups}
                commodities={commodities}
                totalUnits={totalUnits}
                updatedPriceInfo={this.getUpdatedPriceInfo(
                  this.getUsedBatches(),
                  this.getUsedPackagingBatches(),
                  totalUnits ? +totalUnits : shippingGroups.reduce((a, b) => a + +b.boxes * +b.items, 0)
                )}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-secondary " + (loading && "disabled")}
              disabled={loading}
              onClick={this.handleClose}
            >
              Close
            </button>
            {step > 1 && (
              <button
                className={"btn btn-secondary " + (loading && "disabled")}
                disabled={loading}
                onClick={this.handlePrevStep}
              >
                Back
              </button>
            )}
            <button
              className={
                "btn " +
                (!this.isStep1Complete() || disabled || (step === 1 && bulkConfirmation === false)
                  ? "btn-danger disabled"
                  : "btn-success")
              }
              disabled={!this.isStep1Complete() || loading || disabled || (step === 1 && bulkConfirmation === false)}
              title={disabled ? "Used amount of a batch is higher than the existing amount" : ""}
              onClick={step < 4 ? this.handleNextStep : this.handleFinishProduction}
            >
              {loading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {step < 4 ? "Continue" : "Complete"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface FulfillmentSummaryProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  shippingGroups: Array<ShippingGroupType>;
  commodities: Array<StockUpdate>;
  packaging: Array<PackagingStockUpdate>;
  updatedPriceInfo: FulfillmentPriceInfo;
  totalUnits: string | undefined;
}

interface FulfillmentSummaryState {}

class FulfillmentSummary extends PureComponent<FulfillmentSummaryProps, FulfillmentSummaryState> {
  validateBatches = () => {
    const { order, commodities, packaging } = this.props;
    const warnings: Array<string> = [];
    const calculation = order.calculations[0];
    if (!calculation) return warnings;
    const { packagings, prices } = calculation;
    const allPrices = packagings.concat(prices);
    const materialUpdates: Array<StockUpdate | PackagingStockUpdate> = (
      commodities as Array<StockUpdate | PackagingStockUpdate>
    ).concat(packaging);

    // Check materials delivered by customer
    if (!allPrices.some(p => p.supplier === AlternativeProviders.CUSTOMER)) return warnings;
    allPrices.forEach(p => {
      if (p.supplier !== AlternativeProviders.CUSTOMER) return;
      const update = materialUpdates.find(u => u.id.toString() === p._id.toString());
      if (!update) return;
      const batches: Array<ExtendedBatch | ExtendedPackagingBatch> = update.stock;
      // If for a material delivered by customer a batch not from a customer is used, push a warning
      const invalidBatches = batches.filter(b => +b.used > 0 && b.stock.supplier !== AlternativeProviders.CUSTOMER);
      invalidBatches.forEach(iB =>
        warnings.push(
          `${("packaging" in update
            ? packagingUtils.getShortPackagingInfo(update.packaging)
            : update.commodity.title.en
          ).trim()} should be provided by the customer but batch ${iB.stock.lot.trim()} was not provided by the customer.`
        )
      );
    });

    return warnings;
  };

  render() {
    const { context, order, commodities, updatedPriceInfo, packaging, shippingGroups, totalUnits } = this.props;
    const finalUnits = totalUnits ? +totalUnits : shippingGroups.reduce((a, b) => a + +b.boxes * +b.items, 0);
    const priceInfoOld = order.calculations[0].info;

    const canSeeFinanceData = accessUtils.canSeeFinanceData();

    // Prepare diff amounts
    const unitsDiff = (finalUnits / order.calculations[0].units - 1) * 100;
    const unitCostDiff = (updatedPriceInfo.unitPriceNaked / priceInfoOld.unitpricenaked - 1) * 100;
    const unitMarginDiff = (updatedPriceInfo.unitMargin / priceInfoOld.unitmargin - 1) * 100;
    const percentMarginDiff = (updatedPriceInfo.percentMargin / priceInfoOld.percentmargin - 1) * 100;
    const totalMarginDiff = (updatedPriceInfo.totalMargin / priceInfoOld.totalmargin - 1) * 100;

    const warnings = this.validateBatches();

    return (
      <>
        {warnings.length > 0 && (
          <div className="alert alert-warning">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              {warnings.map(w => (
                <div>{w}</div>
              ))}
            </div>
          </div>
        )}
        <div className="form-group form-group-last">
          <div className="form-group form-group-last">
            <div className="row">
              <div className="col-4">
                <label className="kt-font-bold kt-font-dark">Calculation</label>
              </div>
              <div className="col-3">
                <span className="form-text kt-font-dark">Before Fulfillment</span>
              </div>
              <div className="col-3">
                <span className="form-text kt-font-dark">After Fulfillment</span>
              </div>
              <div className="col-2">
                <span className="form-text kt-font-dark">Difference</span>
              </div>
            </div>
          </div>
          <hr className="mb-0" />
          <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
            <div className="col-4">
              <label className="col-form-label kt-font-dark">Units</label>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">{order.calculations[0].units}</span>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">{finalUnits}</span>
            </div>
            <div className="col-2 align-self-center">
              <span
                className={
                  "kt-font-bold " +
                  (unitsDiff < 0 ? "kt-font-danger" : unitsDiff > 0 ? "kt-font-success" : "kt-font-dark")
                }
              >
                {(unitsDiff > 0 ? "+" : "") + unitsDiff.toFixed(2)}%
              </span>
            </div>
          </div>
          <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
            <div className="col-4">
              <label className="col-form-label kt-font-dark">Cost (Unit)</label>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(priceInfoOld.unitpricenaked) : "-"}
              </span>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(updatedPriceInfo.unitPriceNaked) : "-"}
              </span>
            </div>
            <div className="col-2 align-self-center">
              <span
                className={
                  "kt-font-bold " +
                  (unitCostDiff > 0 ? "kt-font-danger" : unitsDiff < 0 ? "kt-font-success" : "kt-font-dark")
                }
              >
                {(unitCostDiff > 0 ? "+" : "") + unitCostDiff.toFixed(2)}%
              </span>
            </div>
          </div>
          <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
            <div className="col-4">
              <label className="col-form-label kt-font-dark">Margin (Unit)</label>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(priceInfoOld.unitmargin) : "-"}
              </span>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(updatedPriceInfo.unitMargin) : "-"}
              </span>
            </div>
            <div className="col-2 align-self-center">
              <span
                className={
                  "kt-font-bold " +
                  (unitMarginDiff < 0 ? "kt-font-danger" : unitMarginDiff > 0 ? "kt-font-success" : "kt-font-dark")
                }
              >
                {unitMarginDiff.toFixed(2)}%
              </span>
            </div>
          </div>
          <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
            <div className="col-4">
              <label className="col-form-label kt-font-dark">Percent Margin</label>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? priceInfoOld.percentmargin.toFixed(2) + "%" : "-"}
              </span>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? updatedPriceInfo.percentMargin.toFixed(2) + "%" : "-"}
              </span>
            </div>
            <div className="col-2 align-self-center">
              <span
                className={
                  "kt-font-bold " +
                  (percentMarginDiff < 0
                    ? "kt-font-danger"
                    : percentMarginDiff > 0
                    ? "kt-font-success"
                    : "kt-font-dark")
                }
              >
                {(percentMarginDiff > 0 ? "+" : "") + percentMarginDiff.toFixed(2)}%
              </span>
            </div>
          </div>
          <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
            <div className="col-4">
              <label className="col-form-label kt-font-dark">Total Margin</label>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(priceInfoOld.totalmargin) : "-"}
              </span>
            </div>
            <div className="col-3 align-self-center">
              <span className="kt-font-dark kt-font-bold">
                {canSeeFinanceData ? baseUtils.formatEuro(updatedPriceInfo.totalMargin) : "-"}
              </span>
            </div>
            <div className="col-2 align-self-center">
              <span
                className={
                  "kt-font-bold " +
                  (totalMarginDiff < 0 ? "kt-font-danger" : totalMarginDiff > 0 ? "kt-font-success" : "kt-font-dark")
                }
              >
                {(totalMarginDiff > 0 ? "+" : "") + totalMarginDiff.toFixed(2)}%
              </span>
            </div>
          </div>
          <hr />
          <div className="row">
            <label className="col-3 col-form-label kt-font-bold kt-font-dark">Shipping groups</label>
          </div>
          {shippingGroups.map(sg => (
            <ShippingGroup
              key={sg.id.toString()}
              shippingGroup={sg}
              shippingGroups={shippingGroups}
              onAddShippingGroup={() => {}}
              onRemoveShippingGroup={() => {}}
              onChangeShippingGroup={() => {}}
              disabled={true}
            />
          ))}
          <hr />
          <div className="row">
            <label className="col-3 col-form-label kt-font-bold kt-font-dark">Commodities</label>
          </div>
          {commodities.some(c => c.stock.some(cs => +cs.used > 0)) ? (
            <div className="form-group form-group-last">
              <div className="row">
                <div className="col-4">
                  <span className="form-text kt-font-dark">Used Batch</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">Used Amount</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">
                    Remaining
                    <RemainingInfoTooltip />
                  </span>
                </div>
                <div className="col-2">
                  <span className="form-text kt-font-dark">
                    Other Allocated
                    <AllocatedInfoTooltip />
                  </span>
                </div>
              </div>
              {commodities.map(commodity => {
                return (
                  commodity.stock.some(s => +s.used > 0) && (
                    <StockUpdateItem
                      key={commodity.id.toString()}
                      stockUpdate={commodity}
                      onUpdateStock={(materialID: string, batchID: string) => (e: any) => {}}
                      context={context}
                      order={order}
                      showEditedBatches={false}
                      disabled={true}
                    />
                  )
                );
              })}
            </div>
          ) : (
            <div className="col">
              <span className="form-text kt-font-dark">No commodities used</span>
            </div>
          )}
          <hr />
          <div className="row">
            <label className="col-3 col-form-label kt-font-bold kt-font-dark">Packaging</label>
          </div>
          {packaging.some(p => p.stock.some(ps => +ps.used > 0)) ? (
            <div className="form-group form-group-last">
              <div className="row">
                <div className="col-4">
                  <span className="form-text kt-font-dark">Used Batch</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">Used Amount</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">
                    Remaining
                    <RemainingInfoTooltip />
                  </span>
                </div>
                <div className="col-2">
                  <span className="form-text kt-font-dark">
                    Other Allocated
                    <AllocatedInfoTooltip />
                  </span>
                </div>
              </div>
              {packaging.map(packaging => (
                <StockUpdateItem
                  key={packaging.id.toString()}
                  stockUpdate={packaging}
                  onUpdateStock={(materialID: string, batchID: string) => (e: any) => {}}
                  context={context}
                  order={order}
                  showEditedBatches={false}
                  disabled={true}
                />
              ))}
            </div>
          ) : (
            <div className="col">
              <span className="form-text kt-font-dark">No packaging used</span>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default FinishProductionModal;

import _ from "lodash";
import React, { useCallback } from "react";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { ProductFilterValues, ProductFilterValuesEnum } from "../../../../utils/warehouseFilterUtils";
import { BatchContentSpecificType } from "../../../../model/warehouse/common.types";

const ROOT_OPTIONS = [
  ProductFilterValuesEnum.ALL,
  ProductFilterValuesEnum.ALL_RAW_MATERIALS,
  ProductFilterValuesEnum.ALL_PACKAGING,
  BatchContentSpecificType.FINISHED_PRODUCTS
];

const DISABLED_OPTIONS: Array<ProductFilterValues> = [
  BatchContentSpecificType.CAPSULES,
  ProductFilterValuesEnum.ALL_PACKAGING,
  BatchContentSpecificType.BOTTLES,
  BatchContentSpecificType.BAGS,
  BatchContentSpecificType.BLISTERS,
  BatchContentSpecificType.LIDS,
  BatchContentSpecificType.PIPETTES,
  BatchContentSpecificType.SPRAY_PUMPS,
  BatchContentSpecificType.LABELS,
  BatchContentSpecificType.BOXES,
  BatchContentSpecificType.SLEEVES,
  BatchContentSpecificType.OTHER_PACKAGING,
  BatchContentSpecificType.FINISHED_PRODUCTS
];

interface ProductFilterOptions {
  value: ProductFilterValues;
  label: string;
  disabled?: boolean;
}

const ProductFilter: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { additionalProductFilter, productFilter } = warehouseContext;

  const options = [
    { value: ProductFilterValuesEnum.ALL, label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL), disabled: true },
    {
      value: ProductFilterValuesEnum.ALL_RAW_MATERIALS,
      label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL_RAW_MATERIALS),
      disabled: true
    },
    {
      value: BatchContentSpecificType.POWDER,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.POWDER),
      disabled: true
    },
    {
      value: BatchContentSpecificType.CAPSULES,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.CAPSULES),
      disabled: true
    },
    {
      value: BatchContentSpecificType.LIQUID,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.LIQUID),
      disabled: true
    },
    {
      value: BatchContentSpecificType.SOFTGELS,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.SOFTGELS),
      disabled: true
    },
    {
      value: ProductFilterValuesEnum.ALL_PACKAGING,
      label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL_PACKAGING),
      disabled: true
    },
    {
      value: BatchContentSpecificType.BOTTLES,
      label: "\xa0 - " + i18n.t("packaging:" + BatchContentSpecificType.BOTTLES),
      disabled: true
    },
    {
      value: BatchContentSpecificType.LIDS,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.LIDS),
      disabled: true
    },
    {
      value: BatchContentSpecificType.LABELS,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.LABELS),
      disabled: true
    },
    {
      value: BatchContentSpecificType.BOXES,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.BOXES),
      disabled: true
    },
    {
      value: BatchContentSpecificType.SLEEVES,
      label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.SLEEVES),
      disabled: true
    },
    {
      value: BatchContentSpecificType.FINISHED_PRODUCTS,
      label: i18n.t("warehouse:" + BatchContentSpecificType.FINISHED_PRODUCTS),
      disabled: true
    }
  ];

  const handleSelectProductFilter = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) =>
      dispatch({
        type: WarehouseActionType.PRODUCT_FILTER,
        payload: { productType: e.currentTarget.value as ProductFilterValues }
      }),
    []
  );

  const handleToggleCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: WarehouseActionType.ADDITIONAL_PRODUCT_FILTER,
        payload: { [e.target.name]: !_.get(additionalProductFilter, e.target.name) }
      }),
    [additionalProductFilter]
  );

  return (
    <div className="navi navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon pt-7">
      <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:typesOfGoods")}</h5>
      <hr className="w-100" />
      <div className="navi-item my-2">
        <select
          value={productFilter}
          onChange={handleSelectProductFilter}
          className="form-control"
          id="kt_datatable_search_type"
        >
          {options.map((option: ProductFilterOptions) => (
            <option disabled={DISABLED_OPTIONS.includes(option.value)} key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="navi-item my-2">
        <div className="navi-link">
          <label className="checkbox checkbox-sm checkbox-single flex-shrink-0 mr-2">
            <input
              type="checkbox"
              name={"organic"}
              onChange={handleToggleCheckBox}
              checked={additionalProductFilter.organic}
            />
            <span />
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <span className="navi-text font-weight-bolder">{i18n.t("warehouse:onlyOrganic")}</span>
          </div>
        </div>
      </div>
      <div className="navi-item my-2">
        <div className="navi-link">
          <label className="checkbox checkbox-sm checkbox-single flex-shrink-0 mr-2">
            <input
              type="checkbox"
              name={"providedByCustomer"}
              onChange={handleToggleCheckBox}
              checked={additionalProductFilter.providedByCustomer}
            />
            <span />
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <span className="navi-text font-weight-bolder">{i18n.t("warehouse:onlyDeliveredByCustomer")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;

import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { useDataContext } from "../../../../context/dataContext";
import dbService, { RESERVATION } from "../../../../services/dbService";
import { BaseActionModalProps, ContentType } from "../../../../model/warehouse/common.types";
import { OrdersDocument } from "../../../../model/orders.types";
import { Batch, SenderType } from "../../../../model/warehouse/batch.types";
import {
  BatchLocationError,
  BatchLocationReservationInformation,
  CommodityInformation
} from "../../../../model/warehouse/customTypes.types";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED, FULFILLMENT } from "../../../../utils/orderUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import OrderReservationInformation from "./OrderReservationInformation";
import OrderReservationBatchSelection from "./OrderReservationBatchSelection";
import OrderReservationOverview from "./OrderReservationOverview";
import { ReservationState } from "../../../../model/warehouse/reservation.types";
import { getBatchIdFromSelectedEntries } from "../../../../utils/warehouseActionUtils";
import baseUtils from "../../../../utils/baseUtils";

const DEFAULTORDERUNIT = "mg";

enum ReservationModalViews {
  SELECT_ORDER,
  SELECT_BATCH,
  CONFIRM_RESERVATION
}

interface CreateReservationModalState {
  view: number;
  saving: boolean;
  selectedOrder?: OrdersDocument;
  batchEntry?: Batch;
  selectedCommodityId?: string;
  commodityInformation: Map<string, CommodityInformation>;
  orderReservations: Array<BatchLocationReservationInformation>;
  batchLocationErrors: Array<BatchLocationError>;
}

const getDefaultState = (): CreateReservationModalState => {
  return {
    view: ReservationModalViews.SELECT_ORDER,
    saving: false,
    selectedOrder: undefined,
    batchEntry: undefined,
    selectedCommodityId: undefined,
    commodityInformation: new Map<string, CommodityInformation>(),
    orderReservations: [],
    batchLocationErrors: []
  };
};

const CreateReservationModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { batch, orders, commodities, reservation, updateDocumentInContext } = dataContext;
  const { selectedEntries } = warehouseContext;

  const [state, setState] = useState(getDefaultState());

  useEffect(() => {
    let batchEntry: Batch | undefined = undefined;
    let selectedCommodityId: string | undefined = undefined;
    if (show && (selectedEntries.length > 0 || actionTrigger?.batchId)) {
      batchEntry = batch.find(
        b => b._id.toString() === (actionTrigger?.batchId ?? getBatchIdFromSelectedEntries(selectedEntries))
      );
    }
    if (batchEntry) selectedCommodityId = batchEntry.content.details._id.toString();
    setState(prevState => {
      return { ...prevState, batchEntry, selectedCommodityId };
    });
  }, [show, batch, selectedEntries, actionTrigger]);

  const relevantOrders = useMemo(() => {
    const { batchEntry } = state;
    let filteredOrders = orders.filter(
      o => orderUtils.isOrderState(o.state) && ![ARCHIVE, DECLINED, FULFILLMENT, CREATEINVOICE].includes(o.state)
    );
    if (!batchEntry) return filteredOrders;
    const content = batchEntry.content.details;
    const batchContentType = batchEntry.content.type;
    const batchSender = batchEntry.sender.senderId;
    const batchSenderType = batchEntry.sender.type;
    if (batchSenderType === SenderType.CUSTOMER) {
      // If batch is a Kundenbeistellung, only show orders from that customer needing the commodity from the selected batch supplied by the customer
      filteredOrders = orderUtils.getOrdersProvidedByCustomer(
        filteredOrders,
        batchSender,
        content._id.toString(),
        batchEntry.sender.customerOrders
      );
    } else if (batchContentType === ContentType.COMMODITY) {
      // Otherwise only include orders which include the commodity from the selected batch in their recipe
      filteredOrders = filteredOrders.filter(fO =>
        fO.recipe.some(ingredient => ingredient.id.toString() === content._id.toString())
      );
    }
    return filteredOrders;
  }, [orders, state.batchEntry]);

  const globalErrors = useMemo(() => {
    const { selectedOrder, batchLocationErrors } = state;
    const errors = new Set(batchLocationErrors.map(errorObj => errorObj.error));
    if (!selectedOrder) errors.add(i18n.t("warehouse:reservationNoOrderSelectedError"));
    if (state.view >= ReservationModalViews.SELECT_BATCH && state.orderReservations.length === 0)
      errors.add(i18n.t("warehouse:reservationNoAmountChangeError"));
    return Array.from(errors);
  }, [state.selectedOrder, state.batchLocationErrors, state.view, state.orderReservations]);

  const handleClose = useCallback(() => {
    setState(getDefaultState());
    onHide();
  }, []);

  const handleNextPage = useCallback(() => {
    setState(prevState => {
      return { ...prevState, view: prevState.view + 1 };
    });
  }, []);

  const handlePreviousPage = useCallback(() => {
    setState(prevState => {
      return { ...prevState, view: prevState.view - 1 };
    });
  }, []);

  const handleSelectOrder = useCallback(
    (selected: any) => {
      let newCommodityInformation = new Map<string, CommodityInformation>();
      let selectedOrder: OrdersDocument | undefined = undefined;
      if (selected.length > 0) {
        const newOrderValue = selected[0].value;
        // Retrieve order and relevant information from that order
        selectedOrder = relevantOrders.find(rO => rO._id.toString() === newOrderValue);
        if (selectedOrder) {
          const units = selectedOrder.calculations[0].units;
          const perUnit = selectedOrder.settings.perUnit;
          const type = selectedOrder.settings.type;
          const orderId = selectedOrder._id.toString();
          const usedCommodities = selectedOrder.recipe.map(r =>
            commodities.find(c => c._id.toString() === r.id.toString())
          );
          if (units && perUnit && type && usedCommodities) {
            // Retrieve reservation for that order, if existing)
            const orderReservation = reservation.find(
              r => r.order._id.toString() === orderId && r.state === ReservationState.OPEN
            );

            // Calculate how much of each commodity is needed for the order
            for (let i = 0; i < usedCommodities.length; i++) {
              const currentCommodity = usedCommodities[i];
              if (!currentCommodity) continue;
              const price = selectedOrder.calculations[0].prices.find(
                p => p._id.toString() === currentCommodity._id.toString()
              );
              if (!price) continue;
              const totalAmount = orderUtils.getTotalAmountWithBuffer(perUnit, units, price.amount, price.buffer, type);
              // Check how much of a commodity is already reserved for that order
              let reservedAmount = 0;
              if (orderReservation) {
                const matchingBatches = orderReservation.batches.filter(
                  reservedBatch =>
                    reservedBatch.batch.content.type === ContentType.COMMODITY &&
                    reservedBatch.batch.content.details._id.toString() === currentCommodity._id.toString()
                );
                reservedAmount = matchingBatches.reduce((acc, batch) => acc + batch.amountAtLocation.value, 0);
              }
              // All amounts are saved in mg for easier calculation, since the amounts are saved in mg in orders
              newCommodityInformation.set(currentCommodity._id.toString(), {
                commodityId: currentCommodity._id.toString(),
                commodityName: resolveTranslation(currentCommodity.title),
                neededAmount: { value: totalAmount, unit: DEFAULTORDERUNIT },
                reservedAmount: {
                  value: Number(
                    calculationUtils.convertAmount(reservedAmount.toString(), DEFAULTWEIGHTUNIT, DEFAULTORDERUNIT)
                  ),
                  unit: DEFAULTORDERUNIT
                }
              });
            }
          }
        }
      }
      setState(prevState => {
        return { ...prevState, selectedOrder: selectedOrder, commodityInformation: newCommodityInformation };
      });
    },
    [relevantOrders, reservation]
  );

  const handleSelectCommodity = useCallback((commodityId: string) => {
    setState(prevState => {
      return { ...prevState, selectedCommodityId: commodityId };
    });
  }, []);

  const handleChangeReservationAmount = useCallback(
    (batchId: string, locationId: string, commodityId: string, amount: number, amountValid: boolean) => {
      const { orderReservations, batchLocationErrors } = state;
      const updatedReservations = _.cloneDeep(orderReservations);
      const updatedErrors = _.cloneDeep(batchLocationErrors);
      // Convert amount to mg to match amounts in orders and make calculation easier
      const reservedAmountInMg = Number(calculationUtils.convertAmount(amount.toString(), DEFAULTWEIGHTUNIT, "mg"));
      const amountError = i18n.t("warehouse:reservationSelectedAmountTooHighError");

      if (amountValid) {
        // Check if amount was previously invalid and is now valid; remove from errors if yes
        const existingErrorIndex = updatedErrors.findIndex(
          l => l.batchId === batchId && l.locationId === locationId && l.error === amountError
        );
        if (existingErrorIndex !== -1) {
          updatedErrors.splice(existingErrorIndex, 1);
        }
      } else {
        updatedErrors.push({ batchId, locationId, commodityId, error: amountError });
      }
      // Check if batch from changed location is already in state reservations
      const existingEntryIndex = updatedReservations.findIndex(
        r => r.batchId === batchId && r.locationId === locationId
      );
      if (existingEntryIndex !== -1) {
        // Remove entries where the amount was set back to 0 (lower should not be possible, but remove anyway)
        if (amount <= 0) {
          updatedReservations.splice(existingEntryIndex, 1);
        } else {
          updatedReservations[existingEntryIndex].amount.value = reservedAmountInMg;
        }
      } else {
        if (amount > 0)
          updatedReservations.push({
            batchId,
            locationId,
            commodityId,
            amount: { value: reservedAmountInMg, unit: "mg" }
          });
      }
      setState(prevState => ({
        ...prevState,
        orderReservations: updatedReservations,
        batchLocationErrors: updatedErrors
      }));
    },
    [state.orderReservations, state.batchLocationErrors]
  );

  const handleSaveReservation = useCallback(async () => {
    const { selectedOrder, orderReservations } = state;
    if (!selectedOrder || orderReservations.length === 0) return;
    setState(prevState => {
      return { ...prevState, saving: true };
    });
    try {
      const result = await dbService.callFunction<false | { id: BSON.ObjectId }>(
        "createReservation",
        [selectedOrder._id, orderReservations],
        true
      );
      if (result) {
        updateDocumentInContext(RESERVATION, result.id);
        toast.success(i18n.t("warehouse:reservationCreationSuccess"));
        handleClose();
      } else {
        toast.error(i18n.t("warehouse:reservationCreationFailure"));
      }
    } finally {
      setState(prevState => {
        return { ...prevState, saving: false };
      });
    }
  }, [state.selectedOrder, state.orderReservations]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={state.view > ReservationModalViews.SELECT_ORDER ? "lg" : undefined}
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title as={"h5"} className="text-black">
          <b>{i18n.t("warehouse:createReservation")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {state.view === ReservationModalViews.SELECT_ORDER && (
            <>
              <h4 className="font-weight-bold text-black">{i18n.t("warehouse:selectOrder")}</h4>
              <div className="row mt-4">
                <div className="col-12">
                  {relevantOrders && relevantOrders.length > 0 ? (
                    <Typeahead
                      id="order"
                      inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                      labelKey="label"
                      options={relevantOrders.map(o => {
                        return {
                          value: o._id.toString(),
                          label: baseUtils.truncateString(`AT-${o.identifier} ${o.title} ${o.subtitle}`, 55)
                        };
                      })}
                      selected={
                        state.selectedOrder
                          ? [
                              {
                                value: state.selectedOrder._id.toString(),
                                label: baseUtils.truncateString(
                                  `AT-${state.selectedOrder.identifier} ${state.selectedOrder.title} ${state.selectedOrder.subtitle}`,
                                  55
                                )
                              }
                            ]
                          : []
                      }
                      placeholder={i18n.t("warehouse:searchForOrders")}
                      onChange={handleSelectOrder}
                    />
                  ) : (
                    <div className="text-muted">{i18n.t("warehouse:reservationNoOrderFoundError")}</div>
                  )}
                </div>
              </div>
            </>
          )}
          {state.view === ReservationModalViews.SELECT_BATCH && state.selectedOrder && state.commodityInformation && (
            <>
              <OrderReservationInformation
                selectedOrder={state.selectedOrder}
                selectedCommodityId={state.selectedCommodityId}
                commodityInformation={state.commodityInformation}
                orderReservations={state.orderReservations}
                batchLocationErrors={state.batchLocationErrors}
                onSelectCommodity={handleSelectCommodity}
              />
              <hr />
              <OrderReservationBatchSelection
                edit={true}
                selectedOrder={state.selectedOrder}
                selectedCommodity={
                  state.selectedCommodityId ? state.commodityInformation.get(state.selectedCommodityId) : undefined
                }
                orderReservations={state.orderReservations}
                onChangeReservationAmount={handleChangeReservationAmount}
              />
            </>
          )}
          {state.view === ReservationModalViews.CONFIRM_RESERVATION &&
            state.selectedOrder &&
            state.commodityInformation && (
              <>
                <OrderReservationOverview
                  selectedOrder={state.selectedOrder}
                  commodityInformation={state.commodityInformation}
                  orderReservations={state.orderReservations}
                />
                <hr />
                <OrderReservationBatchSelection
                  edit={false}
                  selectedOrder={state.selectedOrder}
                  selectedCommodity={
                    state.selectedCommodityId ? state.commodityInformation.get(state.selectedCommodityId) : undefined
                  }
                  orderReservations={state.orderReservations}
                  onChangeReservationAmount={handleChangeReservationAmount}
                />
              </>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (state.saving ? "disabled" : "")}
          onClick={
            state.saving
              ? undefined
              : state.view === ReservationModalViews.SELECT_ORDER
              ? handleClose
              : handlePreviousPage
          }
        >
          {i18n.t(`common:${state.view === ReservationModalViews.SELECT_ORDER ? "close" : "back"}`)}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t(
            `${state.view === ReservationModalViews.CONFIRM_RESERVATION ? "warehouse:reserve" : "common:next"}`
          )}
          className="btn btn-success"
          errors={globalErrors}
          saving={state.saving}
          onClick={state.view === ReservationModalViews.CONFIRM_RESERVATION ? handleSaveReservation : handleNextPage}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CreateReservationModal;

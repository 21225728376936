import React, { useCallback, useEffect, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { getBatchLocationStatusIndicationText } from "../../../../utils/batchUtils";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import baseUtils, { formatNumValue } from "../../../../utils/baseUtils";
import {
  useWarehouseContext,
  useWarehouseDispatch,
  WarehouseActionType,
  WarehouseListingTabNames
} from "../../../../context/warehouseContext";
import Tooltip from "../../../common/Tooltip";
import { getBBDColor, getPackagingUnitAmount, SelectedBatchEntryType } from "../../../../utils/warehouseUtils";
import { useDataContext } from "../../../../context/dataContext";
import { filterLocations } from "../../../../utils/warehouseFilterUtils";
import { StatusContextMenu } from "../../common/StatusContextMenu";
import ReservationInformation from "../common/ReservationInformation";
import StorageSpaceInformation from "../common/StorageSpaceInformation";
import WarehouseInformation from "../common/WarehouseInformation";

interface DefaultBatchRowProps {
  activeTab: WarehouseListingTabNames;
  batch: Batch;
  expandInitially?: boolean;
  compact?: boolean;
}

const DefaultBatchRow: React.FC<DefaultBatchRowProps> = ({ activeTab, batch, expandInitially, compact }) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const { configuration, selectedEntries, selectedLocation } = warehouseContext;
  const [showDetails, setShowDetails] = useState(!!expandInitially);

  // toggle or hide all details
  useEffect(() => {
    setShowDetails(!!expandInitially);
  }, [expandInitially]);

  const uniqueWarehouses = useMemo(
    () => Array.from(new Set(batch.locations.map(l => l.location.warehouseSnapshot.shortName))),
    [batch]
  );

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedBatchEntryType.BATCH && entry.parentId === batch._id.toString() && !entry.childId
      ),
    [batch, selectedEntries]
  );

  const headerDefinition = useMemo(
    () =>
      compact
        ? [
            { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
            { title: "#", size: 1 },
            { title: i18n.t("warehouse:amount"), size: 15 },
            { title: i18n.t("warehouse:warehouseShort"), size: 25 },
            { title: i18n.t("warehouse:storageSpaceShort"), size: 25 },
            { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 30 }
          ]
        : [
            { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
            { title: "#", size: 1 },
            { title: i18n.t("warehouse:amount"), size: 15 },
            { title: i18n.t("warehouse:warehouseShort"), size: 15 },
            { title: i18n.t("warehouse:storageSpace"), size: 15 },
            { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 20 },
            { title: i18n.t("warehouse:reservation"), size: 15 },
            { title: i18n.t("warehouse:status"), size: 13 }
          ],
    [compact]
  );

  // Note reservation is currently not relevant here
  const filteredLocations = useMemo(
    () => batch.locations.filter(l => filterLocations(batch._id.toString(), l, activeTab, [], warehouseContext)),
    [activeTab, batch, configuration, selectedLocation]
  );

  const handleToggleDetails = useCallback(() => {
    setShowDetails(prevState => !prevState);
    dispatch({ type: WarehouseActionType.SELECT_BATCH, payload: { batch } });
  }, [batch]);

  const handleCheckbox = useCallback(
    () =>
      dispatch({
        type: WarehouseActionType.SELECT_BATCH_ENTRY,
        payload: {
          type: SelectedBatchEntryType.BATCH,
          parentId: batch._id.toString(),
          numberOfChildren: filteredLocations.length,
          currentChildren: filteredLocations.map(fL => fL._id.toString())
        }
      }),
    [batch, filteredLocations]
  );

  return (
    <>
      <tr className={"kt-datatable__row d-table-row nopadding table-hover"} onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <input
                type="checkbox"
                className="ml-0 kt-checkbox--solid"
                checked={checked}
                onClick={e => e.stopPropagation()}
                onChange={handleCheckbox}
              />
            </div>
          </div>
        </td>
        {!compact && (
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
                  <span className="d-inline-block text-ellipsis align-bottom" style={{ maxWidth: "200px" }}>
                    {resolveTranslation(batch.content.details.title)}
                  </span>
                  <small
                    className={"kt-user-card-v2__email ml-2 text-ellipsis align-bottom"}
                    style={{ maxWidth: "200px" }}
                  >
                    {resolveTranslation(batch.content.details.subtitle)}
                  </small>
                </span>
              </div>
            </div>
          </td>
        )}
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email mt-0 text-black"}>{batch.content.details.articleNo || "-"}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email mt-0 text-black text-ellipsis"} style={{ maxWidth: "120px" }}>
            {batch.sender.name}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email mt-0 text-black"}>{batch.lot}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email " + getBBDColor(batch.expiry)}>
            {baseUtils.formatDate(batch.expiry)}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black kt-font-bold">{formatNumValue(batch.totalAmount)}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell text-right">
          <Tooltip
            tooltipText={
              uniqueWarehouses.length === 1
                ? resolveTranslation(batch.locations[0].location.warehouseSnapshot.warehouseName)
                : i18n.t("warehouse:variousWarehouses")
            }
          >
            <div className={"label label-light label-inline kt-font-bold text-black my-auto"} style={{ width: 60 }}>
              {uniqueWarehouses.length === 1 ? uniqueWarehouses[0] : "VAR"}
            </div>
          </Tooltip>
        </td>
      </tr>
      {showDetails && (
        <tr className={"kt-datatable__row d-table-row"}>
          <td colSpan={10} className="px-0">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
              <table className="kt-datatable__table d-table p-5 bg-light">
                <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    {headerDefinition.map((def, idx) => (
                      <th key={idx} className="kt-datatable__cell d-table-cell" style={{ width: `${def.size}%` }}>
                        <span>{def.title}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {filteredLocations.map(l => (
                    <DefaultBatchRowDetailRow
                      key={l._id.toString()}
                      batch={batch}
                      location={l}
                      numberOfSiblingLocations={filteredLocations.length}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultBatchRowDetailRowProps {
  batch: Batch;
  location: BatchLocation;
  numberOfSiblingLocations: number;
  compact?: boolean;
}

const DefaultBatchRowDetailRow: React.FC<DefaultBatchRowDetailRowProps> = ({
  batch,
  location,
  numberOfSiblingLocations,
  compact
}) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { reservation } = dataContext;
  const { selectedEntries } = warehouseContext;

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedBatchEntryType.LOCATION &&
          entry.parentId === batch._id.toString() &&
          entry.childId === location._id.toString()
      ),
    [batch, location, selectedEntries]
  );

  const [status, color] = useMemo(
    () => getBatchLocationStatusIndicationText(batch._id.toString(), location, reservation),
    [batch, location, reservation]
  );

  const handleCheckbox = useCallback(
    () =>
      dispatch({
        type: WarehouseActionType.SELECT_BATCH_ENTRY,
        payload: {
          type: SelectedBatchEntryType.LOCATION,
          parentId: batch._id.toString(),
          numberOfChildren: numberOfSiblingLocations,
          childId: location._id.toString()
        }
      }),
    [batch, location]
  );

  return (
    <tr className={"kt-datatable__row d-table-row nopadding"}>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md " + color} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input type="checkbox" className="ml-0 kt-checkbox--solid" checked={checked} onChange={handleCheckbox} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  ">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              <span>{formatNumValue(location.amountAtLocation)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <WarehouseInformation batch={batch} location={location} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <StorageSpaceInformation batch={batch} location={location} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span>{getPackagingUnitAmount(location.packagingUnits)}</span>
            </span>
          </div>
        </div>
      </td>
      {!compact && (
        <>
          <td className="kt-datatable__cell d-table-cell">
            <ReservationInformation batch={batch} location={location} />
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <StatusContextMenu status={status} batchId={batch._id.toString()} locationId={location._id.toString()} />
          </td>
        </>
      )}
    </tr>
  );
};

export default DefaultBatchRow;

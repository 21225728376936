import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import dbService, { ORDERS } from "../../../services/dbService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import toastUtils from "../../../utils/toastUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import userService from "../../../services/userService";
import { T_TARGETDATEUPDATED } from "../../../utils/timelineUtils";
import { CalendarWeekBadge } from "../../listings/common/BaseListingComponents";
import dateUtils from "../../../utils/dateUtils";
import baseUtils from "../../../utils/baseUtils";
import DelayReasons from "../../common/DelayReasons";
import slackService from "../../../services/slackService";
import { ManufacturersDocument } from "../../../model/manufacturers.types";

interface EditTargetDateModalProps {
  order: CustomOrder | OrdersDocument;
  targetWeekBadge?: boolean;
}

interface EditTargetDateModalState {
  targetDate: Date | null;
  show: boolean;
  showHistory: boolean;
  note: string;
  reason: { value: string; label: string } | "";
}

class EditTargetDateModal extends PureComponent<EditTargetDateModalProps, EditTargetDateModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: EditTargetDateModalProps) {
    super(props);
    this.state = { show: false, targetDate: props.order.targetDate, showHistory: false, note: "", reason: "" };
  }

  componentDidUpdate(
    prevProps: Readonly<EditTargetDateModalProps>,
    prevState: Readonly<EditTargetDateModalState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.order, this.props.order)) {
      this.setState({ targetDate: this.props.order.targetDate });
    }
  }

  handleShow = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this.setState({ show: true });
  };
  handleClose = () =>
    this.setState({ show: false, targetDate: this.props.order.targetDate, note: "", showHistory: false, reason: "" });
  handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });
  handleReason = (reason: { value: string; label: string } | "") => this.setState({ reason });

  handleBlurTargetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { order } = this.props;
    const min = userService.isAdmin() ? new Date(0) : orderUtils.calculateEarliestDeliveryDate(order);
    const date = e.target.valueAsDate;
    this.setState({ targetDate: date && date >= min ? date : min });
  };

  handleSetTargetDate = async () => {
    const { order } = this.props;
    const { targetDate, note, reason } = this.state;
    const { companies, updateDocumentInContext } = this.context;
    const customer =
      "_id" in order.createdFor
        ? order.createdFor
        : (baseUtils.getDocFromCollection(companies, order.createdFor as BSON.ObjectId) as CompaniesDocument);
    if (!targetDate) toast.error("No target date set");
    try {
      const timelineEntry: any = {
        id: new BSON.ObjectId(),
        type: T_TARGETDATEUPDATED,
        date: new Date(),
        person: userService.getUserId(),
        oldDate: order.targetDate,
        newDate: targetDate,
        note: note.trim()
      };
      if (reason) timelineEntry.notification = reason.label;
      const result = await dbOrderService.updateTargetDate(order._id, targetDate!, timelineEntry);
      await toastUtils.databaseOperationToast(
        !!result && result.modifiedCount > 0,
        "Target date successfully set",
        "Target date could not be set",
        async () => {
          updateDocumentInContext(ORDERS, order._id);
          const user = userService.getUserData();
          const isOrder = orderUtils.isOrderState(order.state) && order.state !== DECLINED;
          const messageHead = `Target date of ${isOrder ? "order" : "offer"} `;
          const destination =
            process.env.NODE_ENV === "production"
              ? "https://www.admincentral.private-label-factory.com"
              : "http://localhost:3000";
          const slackOrderLink = `<${destination}/order/${order._id.toString()}|${isOrder ? "AT" : "AN"}-${
            order.identifier
          }> `;
          const mailOrderLink = `<a href="${destination}/order/${order._id.toString()}">${isOrder ? "AT" : "AN"}-${
            order.identifier
          }</a> `;
          let message = `for ${reason ? "_" : ""}${customer ? customer.name : "Unknown Customer"}${
            reason ? "_" : ""
          } was updated. Before: ${
            timelineEntry.oldDate
              ? `${baseUtils.formatDate(timelineEntry.oldDate)} (CW ${dateUtils.getCW(timelineEntry.oldDate)})`
              : "-"
          } Now: ${baseUtils.formatDate(timelineEntry.newDate)} (CW ${dateUtils.getCW(
            timelineEntry.newDate
          )}). Changed By: ${user.prename} ${user.surname}.`;
          if (reason) {
            message += ` Reason: ${reason.label}`;
            const slackMessage = messageHead + slackOrderLink + message;
            const target = "_id" in order.createdFrom ? order.createdFrom._id : order.createdFrom;
            // dev case handled by backend-function
            slackService.sendMessage(target, slackMessage);
          }
          // Check if an email notification should be sent to a specific address
          const manufacturer: ManufacturersDocument =
            "name" in order.settings.manufacturer
              ? order.settings.manufacturer
              : baseUtils.getDocFromCollection(this.context.manufacturers, order.settings.manufacturer);
          if (manufacturer.notificationSettings?.targetWeekChanges) {
            const { mailAddress, isEnabled } = manufacturer.notificationSettings.targetWeekChanges;
            if (isEnabled) {
              const mailSubject = "Change CW: " + (isOrder ? "AT-" : "AN-") + order.identifier;
              const mailText = messageHead + mailOrderLink + message;
              // dev case is handled by backend function
              dbService.callFunction("sendEmail", [mailSubject, mailText, "", mailAddress]);
            }
          }
        }
      );
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    }
    this.handleClose();
  };

  render() {
    const { order, targetWeekBadge } = this.props;
    const { show, targetDate, showHistory, note, reason } = this.state;
    const history = order.timeline.filter(t => t.type === T_TARGETDATEUPDATED).reverse();
    const isEqual = _.isEqual(targetDate, order.targetDate);
    return (
      <>
        {targetWeekBadge ? (
          <CalendarWeekBadge order={order} onClick={this.handleShow} />
        ) : (
          <i className="fa fa-cog pointer ml-2" onClick={this.handleShow} />
        )}
        {show && (
          <Modal show={show} onHide={this.handleClose} centered name={"editTargetDateModal"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="kt-font-brand fa fa-calendar mr-2" />
                Edit Target Date
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="kt-portlet__body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="kt-section kt-section--first mb-3">
                      <div className="kt-section__body">
                        <br />
                        <div className="input-group mx-auto" style={{ maxWidth: "300px" }}>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              {targetDate ? "CW " + dateUtils.getCW(targetDate) : "-"}
                            </span>
                          </div>
                          <input
                            type="date"
                            className="form-control "
                            min={
                              userService.isAdmin()
                                ? undefined
                                : orderUtils.calculateEarliestDeliveryDate(order).toISOString().split("T")[0]
                            }
                            onChange={e => this.setState({ targetDate: e.target.valueAsDate })}
                            onBlur={this.handleBlurTargetDate}
                            value={targetDate ? targetDate.toISOString().split("T")[0] : ""}
                          />
                        </div>
                        <br />
                        <textarea
                          className="form-control mx-auto "
                          name="note"
                          rows={2}
                          value={note}
                          onChange={this.handleNote}
                          placeholder={"Note, e.g. a reason"}
                          style={{ maxWidth: "300px" }}
                        />
                        <br />
                        <div className="mx-auto" style={{ maxWidth: "300px" }}>
                          <DelayReasons reason={reason} onChange={this.handleReason} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {history.length > 0 && (
                  <>
                    <span
                      className="pointer kt-font-hover-dark"
                      onClick={() => this.setState({ showHistory: !showHistory })}
                    >
                      {showHistory ? "Hide history" : "Show history"}
                    </span>
                    {showHistory && (
                      <div
                        className="kt-notes kt-scroll kt-scroll--pull ps ps--active-y pt-2 overflow-auto"
                        style={{ maxHeight: "400px" }}
                      >
                        <div className="kt-notes__items">
                          {history.map(timelineEntry => {
                            const user = baseUtils.getDocFromCollection(this.context.userdata, timelineEntry.person);
                            const userName = user ? user.prename + " " + user.surname : "-";
                            return (
                              <div className="kt-notes__item pb-2" key={timelineEntry.id.toString()}>
                                <div className="kt-notes__media">
                                  <img
                                    src={
                                      user && user.img_url
                                        ? user.img_url
                                        : "https://private-label-factory.de/img/mail/placeholder_female.jpg"
                                    }
                                    alt="image"
                                  />
                                </div>
                                <div className="kt-notes__content px-2 pb-0">
                                  <div className="kt-notes__section">
                                    <div className="kt-notes__info">
                                      <span className="kt-notes__title">{userName}</span>
                                      <span className="kt-notes__desc">{baseUtils.formatDate(timelineEntry.date)}</span>
                                    </div>
                                  </div>
                                  <span className="kt-notes__body p-0">
                                    <div className="kt-widget4">
                                      <div className="kt-widget4__item">
                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                          <i
                                            className="flaticon2-calendar-2"
                                            style={{ fontSize: "24px", color: "#bfc5d4" }}
                                          />
                                        </div>
                                        <div className="kt-widget4__info">
                                          <span className="kt-widget4__username">
                                            {`${
                                              timelineEntry.oldDate
                                                ? `${baseUtils.formatDate(timelineEntry.oldDate)} (CW ${dateUtils.getCW(
                                                    timelineEntry.oldDate
                                                  )})`
                                                : "Not set"
                                            }`}{" "}
                                            <i className="la la-arrow-right" />{" "}
                                            {`${baseUtils.formatDate(timelineEntry.newDate)} (CW ${dateUtils.getCW(
                                              timelineEntry.newDate
                                            )})`}
                                            {timelineEntry.note && <p>Note: {timelineEntry.note}</p>}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={this.handleClose}>
                Close
              </button>
              <button
                className={"btn btn-success " + (!targetDate && isEqual ? "btn-disabled" : "")}
                disabled={!targetDate || isEqual}
                onClick={this.handleSetTargetDate}
              >
                <b>Set Target Date</b>
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default EditTargetDateModal;

import * as Realm from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import jwt_decode from "jwt-decode";

const application = process.env.REACT_APP_NAME;
const app = new Realm.App({ id: application ?? "" });

/**
 * Login the user and redirect him to the last location he visited (or "/" if there was none).
 * @param email: Email of the user
 * @param password: Password of the user
 * @param history: History object that is manipulated to redirect
 * @param lastLocation: Location the user should be redirected to
 * @returns { Realm.User | boolean } The user if the login was successful, else false
 */
async function login(email: string, password: string, history: RouteComponentProps["history"], lastLocation: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  try {
    const user = await app.logIn(credentials);
    window.location.href = lastLocation;
    return user;
  } catch (err) {
    console.error("Failed to log in", err);
    return false;
  }
}

/**
 * Log out the current user.
 */
async function logout() {
  const user = getUser();
  if (user) {
    try {
      await user.logOut();
      // Clear realm app related entries, only keep local-userpass entry, otherwise next login won't succeed for some reason
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("realm-web:app") && localStorage.getItem(key)! !== "local-userpass")
          keysToRemove.push(key);
      }
      keysToRemove.forEach(key => localStorage.removeItem(key));
    } catch (e) {
      console.error("cannot log out", e);
    }
  }
}

/**
 * Retrieve the current user.
 * @returns { Realm.User } Current user
 */
function getUser() {
  return app.currentUser;
}

/**
 * Retrieve the ID of the user data document of the current user. If the app does not include this information we are
 * looking for the access token inside the local storage.
 * @returns { BSON.ObjectId } ID of the user data document
 */
function getUserDataID() {
  try {
    if (app.currentUser && app.currentUser.customData && app.currentUser.customData._id) {
      return app.currentUser.customData._id;
    } else {
      console.warn("User not found, trying to get access token");
      const prefix = `realm-web:app(${application ?? ""})`;
      const userId = JSON.parse(localStorage.getItem(`${prefix}:userIds`)!)[0];
      const token = localStorage.getItem(`${prefix}:user(${userId}):accessToken`);
      // @ts-ignore
      return jwt_decode(token)["user_data"]._id;
    }
  } catch (e) {
    console.error("cannot get user information", e);
    return null;
  }
}

/**
 * Check if credentials are valid
 * @param email email of the user to check
 * @param password password of the user
 * @returns {Promise<AuthResponse>} result of authentication
 */
async function checkCredentials(email: string, password: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  return app.authenticator.authenticate(credentials);
}

/**
 * Change password
 * @param email email of the user
 * @param password the new password
 * @returns {Promise<void>} result of the reset password function
 */
async function changePassword(email: string, password: string) {
  return app.emailPasswordAuth.callResetPasswordFunction(email, password, [false, process.env.NODE_ENV]);
}

/**
 * Send password reset mail by calling a password reset function
 * @param email  User email that is linked to the account
 */
async function sendResetPasswordMail(email: string) {
  await app.emailPasswordAuth.callResetPasswordFunction(email, "12345678", [true, process.env.NODE_ENV]);
}

/**
 * Change password according to user input
 * @param token mongo identifier for user
 * @param tokenId mongo identifier for user
 * @param newPassword new Password set by user.
 */
async function resetPassword(token: string, tokenId: string, newPassword: string) {
  await app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
}
/**
 * Check if user is logged in
 * @returns {boolean} true if user is logged in, else False
 */
function isLoggedIn() {
  return app.currentUser?.isLoggedIn;
}

/**
 * Check if a user exists and is confirmed
 * @param email: Mail of the user
 * @returns true if user already confirmed, false if user does not exist or isn't confirmed
 */
async function userExistsAndConfirmed(email: string) {
  try {
    await app.emailPasswordAuth.resendConfirmationEmail(email);
    return false;
  } catch (e) {
    return e.errorCode === "UserAlreadyConfirmed";
  }
}

export default {
  getUser,
  getUserDataID,
  userExistsAndConfirmed,
  sendResetPasswordMail,
  login,
  logout,
  checkCredentials,
  changePassword,
  isLoggedIn,
  resetPassword
};

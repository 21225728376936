import { BSON } from "realm-web";
import { Content, ExtendedOrderSnapshot, PersonSnapshot } from "./common.types";
import { BatchLocationInformation } from "./batch.types";
import { NumValue } from "../common.types";

export enum ReservationState {
  OPEN = "open",
  DONE = "done",
  CANCELED = "canceled"
}

export enum ReservationTimelineType {
  RESERVATIONCREATED = "reservationCreated",
  BATCHESUPDATED = "batchesUpdated",
  BATCHBOOKEDOUT = "batchBookedOut",
  BATCHMOVEDTONEWLOCATION = "batchMovedToNewLocation",
  BATCHRESERVATIONCANCELLED = "batchReservationCancelled"
}

export interface Reservation {
  _id: BSON.ObjectId; // unique id
  identifier: string;
  state: ReservationState;
  created: Date;
  person: PersonSnapshot;
  batches: Array<ReservedBatch>;
  order: ExtendedOrderSnapshot;
  timeline: Array<ReservationTimelineEntry>;
}

export interface ReservedBatch {
  _id: BSON.ObjectId; // unique id
  batch: ReservedBatchSnapshot;
  location: BatchLocationInformation;
  amountAtLocation: NumValue; // reserved amount
}

export interface ReservedBatchSnapshot {
  _id: BSON.ObjectId; // batch id, reference
  lot: string;
  content: Content;
}

export interface ReservationTimelineEntry {
  _id: BSON.ObjectId;
  type: ReservationTimelineType;
  date: Date;
  person: PersonSnapshot;
  payload: object;
  diff: { pre: object; post: object };
}

import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getLastLocation } from "../../app/router/RouterHelpers";
import authenticationService from "../../services/authenticationService";

interface LoginProps extends RouteComponentProps<{}, {}, {}> {}

interface LoginState {
  email: string;
  password: string;
  loginError: string;
  loggingIn: boolean;
  fromLogout: boolean;
}

class Login extends PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    const fromLogout = new URLSearchParams(props.location.search).get("logout");
    this.state = { email: "", password: "", loginError: "", loggingIn: false, fromLogout: !!fromLogout };
  }

  /**
   * Handle the login of the user.
   * @param e: Event that triggered the login
   */
  handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const { email, password } = this.state;
    const { history } = this.props;
    this.setState({ loggingIn: true });
    e.preventDefault();
    const res = await authenticationService.login(email, password, history, getLastLocation());
    if (!res) {
      this.setState({
        loggingIn: false,
        loginError: "Login failed! Please check your data and try again.",
        email: "",
        password: ""
      });
    }
  };

  componentDidMount() {
    const { history } = this.props;
    const { fromLogout } = this.state;
    const user = authenticationService.getUser();
    if (!fromLogout && user && user.isLoggedIn) {
      history.replace("/");
    }
  }

  render() {
    const { email, password, loginError, loggingIn, fromLogout } = this.state;
    return (
      <div className="root" style={{ height: "100%" }}>
        <div className="kt-grid kt-grid--ver kt-grid--root" style={{ height: "100%" }}>
          <div className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin" id="kt_login">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
              style={{
                height: "100%!important",
                backgroundImage: "url(/media/bg/bg-3.jpg)"
              }}
            >
              <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                <div className="kt-login__container">
                  <div className="kt-login__logo">
                    <a href="#">
                      <img src={process.env.PUBLIC_URL + "/media/logos/logo-5.png"} alt="plf" />
                    </a>
                  </div>
                  <div className="kt-login__signin">
                    <div className="kt-login__head">
                      <h3 className="kt-login__title">Please Sign In</h3>
                      {fromLogout && <p className="kt-login text-center">Logged out successfully.</p>}
                    </div>
                    <form className="kt-form" noValidate>
                      <div className="input-group">
                        <input
                          autoFocus
                          className="form-control is-invalid"
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={e => this.setState({ email: e.target.value, loginError: "" })}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <input
                          className="form-control is-invalid"
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={e => this.setState({ password: e.target.value, loginError: "" })}
                        />
                        <div
                          id="password-error"
                          className={"error invalid-feedback text-center" + (!loginError ? " d-none" : "")}
                        >
                          {loginError}
                        </div>
                      </div>
                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <Link to="/passwordResetRequest" id="kt_login_forgot" className="text-muted text-hover-primary">
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="kt-login__actions">
                        <button
                          id="login-submit"
                          className={"btn btn-brand btn-elevate kt-login__btn-primary" + (loggingIn ? " disabled" : "")}
                          disabled={loggingIn}
                          onClick={this.handleLogin}
                        >
                          {loggingIn ? "Please wait..." : "Sign In"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
